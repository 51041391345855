import { spawn } from 'redux-saga/effects';

import { watchUpdatePassword } from './updatePassword';
import { watchUpdatePreferences } from './updatePreferences';
import { watchGetUserPreferences } from './getUserPreferences';
import { watchUploadUserHeadshot } from './uploadUserHeadshot';
import { watchUploadUserBrokerLogo } from './uploadUserBrokerLogo';

export default function* saga() {
  yield spawn(watchUpdatePassword);
  yield spawn(watchUpdatePreferences);
  yield spawn(watchGetUserPreferences);
  yield spawn(watchUploadUserHeadshot);
  yield spawn(watchUploadUserBrokerLogo);
}
