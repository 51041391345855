import { CustomFlowbiteTheme, Modal } from 'flowbite-react';

import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import GuidePreviewFirstTime from 'modules/v2/pages/GuideCatalog/components/GuidePreviewFirstTime';

const customTheme: CustomFlowbiteTheme['modal'] = {
  root: {
    show: {
      on: 'flex bg-neutral-900 bg-opacity-80',
      off: 'hidden',
    },
  },
  sizes: {
    'full': 'max-w-full',
  },
  content: {
    base: 'relative h-full max-sm:w-full md:h-auto font-[figtree]',
    'inner': 'relative flex flex-col rounded-lg bg-transparent shadow dark:bg-gray-700'
  },
  'positions': {
    'center-left': 'items-center justify-start',
  },
  body: {
    base: 'px-6',
  },
  footer: {
    base:
      'flex space-x-2 rounded-b border-neutral-200 min-h-[91px] px-6 pb-[13px] pt-[34px] border-neutral-600',
  },
};

const GuideTour = ({ showModal, setShowModal, guideDetails, frontCoverResponse, personalInformation, inProgressOrders, isOnlyGuideCustomer }) => {

  return (
    <Modal
      theme={customTheme}
      show={showModal}
      onClose={() => setShowModal(false)}
      size="full"
      popup
      position="center-left"
      dismissible
    >
      <div className='flex flex-wrap gap-3 ml-[290px] mt-[-300px] max-lg:ml-0'>
        <Box className='p-2 w-[240px]' full={false}>
          <GuidePreviewFirstTime guideDetails={guideDetails} inProgressOrders={inProgressOrders} isOnlyGuideCustomer={isOnlyGuideCustomer} frontCoverResponse={frontCoverResponse} personalInformation={personalInformation} />
        </Box>
        <Box className='p-5 w-[286px]' full={false}>
          <p className='font-semibold leading-5'>This is what you bought. Click to start editing.</p>
          <Button onClick={() => setShowModal(false)} size='sm' className='w-[57px] mt-3.5'>
            Okay
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export default GuideTour;
