import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash-es';

import { getGuideContent } from 'modules/api/strapi';
import { withGuideProfile } from 'modules/dashboard/containers';
import { withProfile } from 'modules/v2/containers';
import { normalizeUrl } from 'modules/v2/utils';
import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import { fetchAllOrders, fetchGuidesListByEmail } from 'modules/api/authorify/guides';
import { showColorInGuides } from 'modules/v2/utils/guideUtils';
import {
  formatOrderedData,
  formatProductCatalog,
  getDefaultImage,
  getTitleName,
} from 'modules/v2/pages/GuideCatalog/utils';
import {
  BookMinimalistic,
  DownloadIcon,
  LinkMinimalistc2,
  QuestionCircleIcon,
} from 'modules/v2/common/components/SvgIcon';
import { Link } from 'modules/v2/draft/components';
import { Tooltip } from 'modules/v2/common/AtomicDesign/molecules';

const GuidesOrder = ({
  profile,
}) => {
  const [orderedList, setOrderedList] = useState([]);
  const [productCatalogData, setProductCatalogData] = useState([]);
  const [productCatalog, setProductCatalog] = useState([]);
  const [showOrderedGuides, setShowOrderedGuides] = useState(<></>);
  const { data: profileData } = profile;
  const { email } = profileData;

  const { data: guideResponse, isLoading: isGuideLoading } = useQuery(
    'getGuideContent',
    getGuideContent,
  );

  const { data: orderList, isLoading: isOrderLoading } = useQuery('getOrders', () =>
    fetchAllOrders({ customerEmail: email }),
  );

  const { data: guideList, isLoading: isGuideListLoading } = useQuery(
    ['getGuidesList', email],
    () => fetchGuidesListByEmail(email),
  );

  useEffect(() => {
    if (!isGuideLoading) {
      const payloadData = guideResponse.data;
      const newPayloadData = payloadData.map(item => {
        const showColor = showColorInGuides.includes(item?.id);
        const showInfographics = !isEmpty(item?.attributes?.infographics);
        return {
          id: item?.id,
          name: item?.attributes?.Name,
          title: item?.attributes?.frontCover?.title,
          showColor,
          showInfographics,
        };
      });
      setProductCatalog(newPayloadData);
    }
  }, [guideResponse, isGuideLoading]);

  useEffect(() => {
    if (!isGuideListLoading && !isEmpty(guideList?.data)) {
      const payloadData = guideList?.data?.filter(Boolean)[0];
      const formattedCatalog = formatProductCatalog(payloadData, productCatalog);
      setProductCatalogData(formattedCatalog);
    }
  }, [guideList, isGuideListLoading, productCatalog]);

  useEffect(() => {
    if (!isOrderLoading && !isEmpty(orderList?.data?.payload)) {
      const payloadOrderData = orderList.data.payload;
      if (payloadOrderData.length) {
        const orderedHistory = productCatalog.map((item) => {
          const dataLoad = formatOrderedData(item, payloadOrderData);
          if (dataLoad) {
            return {
              ...item,
              guideName: item.name,
              ...dataLoad,
              orderId: dataLoad?._id,
            };
          }
          return null;
        });
        const filteredOrderedHistory = orderedHistory.filter(Boolean);
        setOrderedList(filteredOrderedHistory);
      }
    }
  }, [isOrderLoading, orderList, productCatalog]);

  const renderYourGuides = () => {
    const guides = productCatalogData?.map((item) => {
      const { name, guideStatus } = item;
      const orderData = orderedList.find((order) => order.guideName === name);
      const isPublishedGuide = !isEmpty(orderData)
        ? orderData.status === 'DONE'
        : guideStatus === 'published';

      const finalData = {
        thumbnail: '',
        guideName: '',
        titleName: '',
        lcsLink: '',
        digitalLink: '',
        infographicsLink: '',
        isDigitalGuide: false,
      };
      if (!isEmpty(orderData)) {
        const getData = guideList?.data
          ?.filter(Boolean)[0]
          .find((guide) => guide._id === orderData.guide);
        const guideTitle = Object.keys(getDefaultImage).find((key) => name.includes(key));
        finalData.thumbnail = orderData.thumbnail || getDefaultImage[guideTitle];
        finalData.guideName = orderData.name;
        finalData.titleName = getTitleName[orderData.guideName];
        finalData.lcsLink = getData?.output?.landingCaptureSiteUrl;
        finalData.digitalLink = getData?.output?.pdf?.spreadLayout;
        finalData.infographicsLink = orderData.infographic;
        finalData.isDigitalGuide = false;
      } else if (guideStatus === 'published') {
        finalData.thumbnail = item.output.pdf.thumbnail.large;
        finalData.guideName = item.name;
        finalData.titleName = getTitleName[item.name];
        finalData.lcsLink = item.output.landingCaptureSiteUrl;
        finalData.digitalLink = item.output.pdf.spreadLayout;
        finalData.infographicsLink = item.output.pdf.infographic;
        finalData.isDigitalGuide = true;
      } else {
        return null;
      }

      return (
        <div className="border-t border-neutral-200">
          <div className="p-6 flex gap-[18px] font-semibold">
            <img
              src={finalData.thumbnail}
              alt={finalData.guideName}
              className="w-[196px] h-[250px]"
            />
            <div>
              <div className="flex flex-wrap gap-2">
                <div className="text-base">{finalData.titleName}</div>
                <div className="flex items-center gap-2 py-1 px-2 rounded-md border border-neutral-200 text-xs h-fit">
                  {finalData.isDigitalGuide ? (
                    <>
                      <LinkMinimalistc2 />
                      <span>Digital</span>
                    </>
                  ) : (
                    <>
                      <BookMinimalistic />
                      <span>Print & Digital</span>
                    </>
                  )}
                </div>
              </div>

              {isPublishedGuide && (
                <div className="flex gap-6">
                  <div className="w-1/2 my-6">
                    <div className="w-[450px] max-sm:w-full">
                      <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                        Lead Capture Site
                        <Tooltip
                          content="Share your guide digitally to showcase expertise and build trust with remote clients. "
                          trigger="hover"
                          style="dark"
                          arrow
                          placement="top"
                          theme={{
                            base:
                              'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                          }}
                        >
                          <QuestionCircleIcon />
                        </Tooltip>
                      </h5>
                      <Link
                        key="landingPage"
                        id="landingPage"
                        showPreview={false}
                        href={normalizeUrl(finalData.lcsLink)}
                        title={null}
                      />
                    </div>
                  </div>

                  <div className="w-1/2 my-6">
                    <div className="w-[450px] max-sm:w-full">
                      <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                        Digital Flip Book
                        <Tooltip
                          content="Share your guide digitally to showcase expertise and build trust with remote clients. "
                          trigger="hover"
                          style="dark"
                          arrow
                          placement="top"
                          theme={{
                            base:
                              'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                          }}
                        >
                          <QuestionCircleIcon />
                        </Tooltip>
                      </h5>
                      <Link
                        key="landingPage"
                        id="landingPage"
                        showPreview={false}
                        href={normalizeUrl(finalData.lcsLink)}
                        title={null}
                      />
                    </div>
                  </div>
                </div>
              )}

              {isPublishedGuide && (
                <div className="w-[470px] max-sm:w-full">
                  <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                    Infographic
                    <Tooltip
                      content="Download a printable version of your infographic"
                      trigger="hover"
                      style="dark"
                      arrow
                      placement="top"
                      theme={{
                        base:
                          'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                      }}
                    >
                      <QuestionCircleIcon />
                    </Tooltip>
                  </h5>
                  <Button
                    type="muted"
                    onClick={() => window.open(finalData.infographicsLink, '_blank')}
                  >
                    <div className="flex items-center gap-2">
                      <DownloadIcon />
                      <span>Download</span>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
    return guides;
  };

  useEffect(() => {
    const component = <div>
    {orderedList?.length > 0 &&
      <Box className="my-6">
        <div className="py-[22px] px-6 font-semibold text-base">
          <p>Your Guides</p>
        </div>
        {renderYourGuides()}
      </Box>
    }
  </div>;
    setShowOrderedGuides(component)

  }, [orderedList, guideList]);

  return showOrderedGuides;
};

export default compose(
  withProfile,
  withGuideProfile,
)(GuidesOrder);
