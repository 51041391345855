import classnames from 'classnames';

const LabelInput = ({ children, required, className='', ...props }) => {
  return (
    <label className={classnames('font-medium text-neutral-800 mb-1', className)} {...props}>
      {children}{required ? <span className="text-error-500">*</span> : null}
    </label>
  );
};

export default LabelInput;
