/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useState, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { LabelInput } from 'modules/v2/common/AtomicDesign/atoms';
import { SearchAddressPinIcon } from 'modules/v2/common/components/SvgIcon';
import { extractAddressComponents } from 'modules/v2/pages/WelcomeJourney/utils/MapHandler';
import { isEmpty } from 'lodash';
import { cn } from 'modules/v2/common/utils/cn';
import { notification } from 'modules/v2/common/utils';

export type AutocompleteMode = { id: string; label: string };

export default function AddressGoogleLookup({
  setSelectedPlace,
  setGeocoderLocation,
  setFieldValue,
  setIsManualEntry,
  isAddLead = false,
  required = true,
  initialInputValue = '',
}) {
  const refInput = useRef(null);
  const map = useMap();
  const [
    autocompleteService,
    setAutocompleteService,
  ] = useState<google.maps.places.AutocompleteService | null>(null);
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const places = useMapsLibrary('places');
  const geocodingLib = useMapsLibrary('geocoding');
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([]);
  const [inputValue, setInputValue] = useState<string>(initialInputValue);
  const [focused, setFocused] = useState(false);
  const [hasPrediction, setHasPrediction] = useState(false);
  const [searchLocation, setSearchLocation] = useState(false);
  const [hideInput, setHideInput] = useState(false);

  useEffect(() => {
    if (places) {
      setAutocompleteService(new places.AutocompleteService());
      setPlacesService(new places.PlacesService(map));
    }
  }, [places, map]);

  useEffect(() => {
    if (initialInputValue) {
      setInputValue(initialInputValue);
      setFieldValue('addressLine1', initialInputValue);
    }
  }, [initialInputValue]);

  const handleGeocode = () => {
    if (!geocodingLib) return;

    const geocoder = new geocodingLib.Geocoder();
    geocoder.geocode({ address: inputValue }, (results, status) => {
      setGeocoderLocation({ status, results });
      const addressComponents = extractAddressComponents(results);
      if (addressComponents) {
        const fieldAddress = ['addressLine1', 'city', 'country', 'pincode', 'state'];

        fieldAddress.forEach((field) => {
          setFieldValue(field, addressComponents[field]);
          if (field === 'addressLine1') {
            setInputValue(addressComponents[field]);
          }
        });
      }
    });
  };

  useEffect(() => {
    if (hasPrediction) {
      handleGeocode();
    }
  }, [geocodingLib, setGeocoderLocation, hasPrediction]);

  const onFocus = () => {
    setFocused(true);
    setHideInput(false);

    if (inputValue && refInput.current) {
      refInput.current.select();
    }
  };

  const onBlur = () => {
    /** Why do we need to do this?
     *  when the handleSuggestionClick is beeing triggered while the field is hidden
     *  it's not available on DOOM, so React can't process that click,
     *  with this delay, we can process the address suggestion click, and hide it right after
     */
    setTimeout(() => {
      setFocused(false);
      setHideInput(true);
    }, 250);
  };

  const setManualAddress = () => {
    setIsManualEntry(true);
    setHasPrediction(false);
    setPredictionResults([]);
    setHideInput(true);
    setSearchLocation(true);
  };

  useEffect(() => {
    const fetchPredictions = async (targetValue) => {
      if (!autocompleteService || !targetValue) {
        setPredictionResults([]);
        return;
      }

      try {
        const request = {
          input: targetValue,
          sessionToken,
          componentRestrictions: { country: ['us', 'ca'] },
        };

        const response = await autocompleteService.getPlacePredictions(request);

        setPredictionResults(response?.predictions ?? []);
      } catch (error) {
        notification.error({ description: 'Error fetching predictions' });
        setPredictionResults([]);
        setManualAddress();
      }
    };
    const delayDebounceFn = setTimeout(() => {
      if(inputValue.length > 2) {
        fetchPredictions(inputValue);
      }
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, autocompleteService, sessionToken]);

  const onInputChange = (event) => {
    setInputValue(event.target.value);
    setHasPrediction(false);
    setFieldValue('addressLine1', event.target.value);
  };

  const onInputChangeUpdated = (event) => {
    setInputValue(event.target.value);
    setHasPrediction(false);
    setFieldValue('addressLine1', event.target.value);
  };

  const handleSuggestionClick = (placeId) => {
    if (!places || !map || isEmpty(placeId)) return;

    const detailRequestOptions = {
      placeId,
      fields: ['geometry', 'name', 'formatted_address'],
      sessionToken,
    };

    const detailsRequestCallback = (placeDetails: google.maps.places.PlaceResult | null) => {
      setInputValue(placeDetails.formatted_address ?? '');
      setHasPrediction(true);
      setSelectedPlace(placeDetails);
      setPredictionResults([]);
      setSessionToken(new places.AutocompleteSessionToken());
      setSearchLocation(true);
    };

    placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
  };

  const mustHideLocationsList = () => Boolean(
    hideInput ||
      (!inputValue && predictionResults.length === 0) ||
      inputValue === ' ' ||
      hasPrediction ||
      focused === false ||
      (initialInputValue === inputValue && predictionResults.length === 0)
  );

  return (
    <div>
      <LabelInput required={required}>Street Address</LabelInput>
      <div
        className={cn(
          'flex gap-2 bg-white border border-neutral-200 rounded-md px-[14px] py-[9px] transition duration-2 h-[38px]',
          {
            'border-primary-500 border-2': focused,
          },
        )}
      >
        <SearchAddressPinIcon />
        <input
          value={inputValue}
          ref={refInput}
          id="location-input"
          name="addressline1"
          onFocus={onFocus}
          onBlur={onBlur}
          onInput={searchLocation && !isAddLead ? onInputChangeUpdated : onInputChange}
          onChange={searchLocation && !isAddLead ? onInputChangeUpdated : onInputChange}
          placeholder="Enter your address"
          autoComplete="off"
          className="border-none border-transparent focus:border-transparent focus:ring-0 focus:outline-none w-full bg-transparent placeholder-neutral-300 text-sm font-normal text-neutral-800"
        />
      </div>

      <ul
        className={cn(
          'rounded-lg shadow-box border border-neutral-200 overflow-hidden mt-2 py-2 bg-white',
          { 'hidden ': mustHideLocationsList() },
        )}
      >
        {predictionResults?.length > 0 &&
          !isEmpty(inputValue) &&
          predictionResults.map(({ place_id, description }) => {
            const regexSearch = new RegExp(`(${inputValue})`, 'gi');
            const newText = description.replace(regexSearch, '<strong>$1</strong>');
            return (
              <li
                key={place_id}
                className="hover:bg-neutral-200 p-3 border-t border-neutral-200 cursor-pointer transition duration-2 bg-neutral-50"
                onClick={() => handleSuggestionClick(place_id)}
              >
                <div
                  className="text-neutral-800 text-sm font-normal"
                  dangerouslySetInnerHTML={{ __html: newText }}
                />
              </li>
            );
          })}
        {!isAddLead && (
          <li
            className="hover:bg-neutral-200 p-3 border-t border-neutral-200 cursor-pointer transition duration-2 bg-neutral-50 font-bold underline"
            onClick={setManualAddress}
            onKeyDown={null}
          >
            Enter address manually
          </li>
        )}
      </ul>
    </div>
  );
}
