import getInstance from 'modules/api/instanceBBA';

const instance = getInstance();

export const getBookVersions = (bookId) => {
  return instance
    .get(`/draft/previous-version/${bookId}`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

