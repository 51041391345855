import { stringify } from 'qs';

const optmizedImageFields = { fields: ['name', 'formats', 'url'] };
const imageFields = { fields: ['name', 'url'] };

export class CmsPopulateBuilder {
  static build(queryObject: object): string {
    return stringify(queryObject, {
      encodeValuesOnly: true,
    });
  }
}

export const queryBuilderInfographics = () => {
  const queryParts = [];
  const populateFields = {
    populate: {
      displayBgImage: optmizedImageFields,
      phoneIcon: imageFields,
      emailIcon: imageFields,
    },
    fields: ['title', 'html', 'color'],
  };

  queryParts.push(CmsPopulateBuilder.build({ populate: { cover: populateFields } }));

  return `?${queryParts.join('&')}`;
};

export const queryBuilder = () => {
  const queryParts = [];
  const populateFields = {
    populate: {
      displayBgImage: optmizedImageFields,
      phoneIcon: imageFields,
      emailIcon: imageFields,
      locationIcon: imageFields,
      webIcon: imageFields,
    },
    fields: ['title', 'name', 'htmlContent', 'htmlFooter'],
  };
  queryParts.push(CmsPopulateBuilder.build({ populate: { frontCover: populateFields } }));

  const populateFrontInside = {
    populate: {
      displayBgImage: optmizedImageFields,
      contentImages: imageFields,
    },
    fields: ['title', 'html', 'innerFooter', 'order'],
  };

  queryParts.push(CmsPopulateBuilder.build({ populate: { content: populateFrontInside } }));

  const populateBackCover = {
    populate: {
      displayBgImage: optmizedImageFields,
      emailIcon: imageFields,
      locationIcon: imageFields,
      phoneIcon: imageFields,
      webIcon: imageFields,
    },
    fields: ['title', 'html', 'titleFooter', 'htmlFooter'],
  };

  queryParts.push(CmsPopulateBuilder.build({ populate: { backCover: populateBackCover } }));


  const populateInfographicsCover = {
    populate: {
      displayBgImage: optmizedImageFields,
      phoneIcon: imageFields,
      emailIcon: imageFields,
    },
    fields: ['title', 'html', 'color'],
  };
  queryParts.push(CmsPopulateBuilder.build({ populate: { infographics: populateInfographicsCover } }));

  return `?${queryParts.join('&')}`;
};