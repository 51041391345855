import { 
  UPDATE_PASSWORD, 
  UPDATE_PREFERENCES, 
  GET_USER_PREFERENCES,
  UPLOAD_USER_HEADSHOT,
  UPLOAD_USER_BROKERLOGO,
} from 'modules/dashboard/store/constants';

export const updatePreferences = ({ 
  name, 
  email, 
  phone, 
  brokerInfo, 
  headshotId,
  firstName,
  lastName,
  publicAddress,
  brokerLogo,
  companyName,
  guides,
}) => ({
  type: UPDATE_PREFERENCES,
  payload: {
    name,
    email,
    phone,
    brokerInfo,
    headshotId,
    firstName,
    lastName,
    publicAddress,
    brokerLogo,
    companyName,
    guides,
  },
});

export const updateGuidePreferences = ({ 
  name, 
  email, 
  phone, 
  brokerInfo, 
  headshotId,
  firstName,
  lastName,
  publicAddress,
  brokerLogo,
  companyName,
}) => ({
  type: UPDATE_PREFERENCES,
  payload:{
    guide: {
    name,
    email,
    phone,
    brokerInfo,
    headshotId,
    firstName,
    lastName,
    publicAddress,
    brokerLogo,
    companyName,
  }},
});

export const updatePassword = (oldPassword, newPassword) => ({
  type: UPDATE_PASSWORD,
  payload: {
    oldPassword,
    newPassword,
  },
});

export const getUserPreferences = () => ({
  type: GET_USER_PREFERENCES,
});

export const uploadUserHeadshot = (headshot) => ({
  type: UPLOAD_USER_HEADSHOT,
  payload: {
    headshot,
  },
});

export const uploadUserBrokerLogo = (userBrokerLogo) => ({
  type: UPLOAD_USER_BROKERLOGO,
  payload: {
    userBrokerLogo,
  },
});

