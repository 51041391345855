import { createSelector } from 'reselect';
import { isCanadaCountry } from 'modules/v2/utils';
import { dashboardSelector } from 'modules/dashboard/store/selectors/dashboard';

const byDefaultAddress = (addressObject) => Boolean(addressObject?.isDefault);

const isCanadadianAddress = (addresses) => {
  const defaultAddress = addresses?.data?.find(byDefaultAddress);

  if (typeof defaultAddress?.country === 'string')
    return isCanadaCountry(defaultAddress?.country);

  return false;
}

export const addressesSelector = createSelector(dashboardSelector, (state) => state.addresses);

export const isCanadianAddressSelector = createSelector(dashboardSelector, (state) => isCanadadianAddress(state.addresses));
