import classNames from 'classnames';
import { SubMenuProps } from './types';

const OrderHistorySubMenu = ({ items, changeActiveItem }: SubMenuProps) => {
  const handleItemClick = (id: string) => {
    changeActiveItem(id);
  };

  return (
    <div className="w-full overflow-x-auto flex border-b-[1px] border-neutral-200 gap-8 mb-[22px]">
      {items.map((item, index) => (
        <div
          key={item.id}
          className={classNames(
            item.active ? 'border-primary-500 border-b-2 text-neutral-800' : 'border-0',
            'pb-4 text-sm whitespace-nowrap cursor-pointer text-neutral-600 flex gap-2',
            'font-semibold',
          )}
          onClick={() => handleItemClick(item.id)}
        >
          <div className='mt-1'>{item.title} </div>
          <div className='px-2 py-1 bg-neutral-200 rounded-md'>{item.count}</div>
        </div>
      ))}
    </div>
  );
};

export default OrderHistorySubMenu;
