import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { isEmpty } from 'lodash-es';

import { Box } from 'modules/v2/common/AtomicDesign/atoms';
import { getMyCheckout } from 'modules/v2/routes/navigation';
import { GuideCheckoutStatus, showColorInGuides } from 'modules/v2/utils/guideUtils';
import { getGuideContent } from 'modules/api/strapi';
import { fetchAllOrders, fetchGuidesListByEmail } from 'modules/api/authorify/guides';
import { withGuideProfile } from 'modules/dashboard/containers';
import { formatProductCatalog } from 'modules/v2/pages/GuideCatalog/utils';
import { getItem } from 'modules/v2/utils/legacy';
import OrderedBookSkeleton from 'modules/v2/pages/OrderedBooksListingPage/loading/OrderedBookSkeleton';

import OrderedCard from '../OrderedCard';

const OrderedGuides = ({ updateGuideProfile, guideProfile }) => {
  const history = useHistory();
  const [orderedList, setOrderedList] = useState([]);
  const [productCatalog, setProductCatalog] = useState([]);
  const userData = JSON.parse(getItem('userData'));
  const { email } = userData;
  const { guidePreferences } = guideProfile;

  const renderLoadingSkeleton = () => {
    const keys = ['dummyKey1', 'dummyKey2'];
    return Array(2)
      .fill(0)
      .map((_, index) => <OrderedBookSkeleton key={keys[index]} />);
  };

  const { isLoading: isOrderLoading } = useQuery(
    'getOrders',
    () => fetchAllOrders({ customerEmail: email }),
    {
      onSuccess(data){
        const payloadData = data.data.payload;
        if(payloadData.length){
          setOrderedList(payloadData);
        }
      }
    }
  );

  const { data: guideResponse, isLoading: isGuideLoading } = useQuery(
    'getGuideContent',
    getGuideContent,
  );

  const { data: guideList, isLoading: isGuideListLoading } = useQuery(
    ['getGuidesList', email],
    () => fetchGuidesListByEmail(email),
  );

  useEffect(() => {
    if (!isGuideLoading) {
      const payloadData = guideResponse.data;
      const newPayloadData = payloadData.map(item => {
        const showColor = showColorInGuides.includes(item?.id);
        const showInfographics = !isEmpty(item?.attributes?.infographics);
        return {
          id: item?.id,
          name: item?.attributes?.Name,
          title: item?.attributes?.frontCover?.title,
          showColor,
          showInfographics,
        };
      });
      setProductCatalog(newPayloadData);
    }
  }, [guideResponse, isGuideLoading]);

  const navigateToCheckout = (orderData, guideName) => {
    if (guidePreferences.length === 0) {
      const payloadData = guideList?.data?.filter(Boolean)[0];
      const result = formatProductCatalog(payloadData, productCatalog);
      const productCatalogDataResponse = [...result];
      const newGuidePreferences = productCatalogDataResponse.map((item) => ({
        ...item,
        selectedColor: orderData?.color,
        selectedStatus: item.name === guideName,
      }));
      updateGuideProfile({ guidePreferences: newGuidePreferences });
    } else {
      const newGuidePreferences = guidePreferences.map((item) => ({
        ...item,
        selectedColor: orderData?.color,
        selectedStatus: item.name === guideName,
      }));
      updateGuideProfile({ guidePreferences: newGuidePreferences });
    }
    history.push(getMyCheckout(GuideCheckoutStatus.DONE, orderData._id));
  };

  if (isOrderLoading) {
    return renderLoadingSkeleton();
  }

  if (orderedList?.length > 0 && !isGuideListLoading) {
    return orderedList.map((item) => (
      <OrderedCard
        key={item._id}
        orderData={item}
        onBuyAgain={navigateToCheckout}
        guides={guideList?.data?.filter(Boolean)[0]}
      />
    ));
  }

  return (
    <Box className="mb-6 p-7 justify-center items-center font-figtree relative text-neutral-600 text-center">
      No orders available
    </Box>
  );
};

export default compose(withGuideProfile)(OrderedGuides);