import { useState } from 'react';
import { compose } from 'redux';

import { isReady } from 'store/status';
import { Loader, UserAvatar } from 'modules/common/components';

import { ProfileAvatar } from 'modules/common/components/SvgIcon';
import { EditProfileForm, FileUploadModal } from 'modules/dashboard/components';
import { ProfileTemplate } from 'modules/dashboard/templates';
import { withProfile } from 'modules/dashboard/containers';
import { withAuth } from 'modules/auth/containers';

import S from './styles';

const Profile = ({ avatar, uploadAvatar, profile, updateProfile, signOut }) => {
  const [loadingText, setLoadingText] = useState('Loading Profile');
  const [isOpen, setIsOpen] = useState(false);

  const {
    data: { email, firstName, lastName, phone },
  } = profile;

  const fields = {
    email,
    firstName,
    lastName,
    phone,
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleConfirm = (newFile) => {
    setIsOpen(false);

    uploadAvatar(newFile);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleProfileUpdate = (formFields) => {
    setLoadingText('Saving profile');

    updateProfile(formFields);
  };

  let content = <Loader title={loadingText} height="100%" />;

  if (isReady(profile.status)) {
    content = (
      <>
        <S.AvatarWrapper>
          <UserAvatar
            avatar={avatar}
            bordered
            defaultAvatar={<ProfileAvatar />}
            size="100px"
            onClick={handleOpen}
          />
        </S.AvatarWrapper>
        <EditProfileForm fields={fields} onSave={handleProfileUpdate} />
        {isOpen && (
          <FileUploadModal
            isOpen={isOpen}
            title="Upload Avatar"
            type="profile"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}
      </>
    );
  }

  return (
    <ProfileTemplate
      avatar={avatar}
      email={fields.email}
      title="Account Settings"
      username={fields.firstName}
      onLogout={signOut}
      sidebarWidth={0}
      noPaddingLeft
      noPaddingRight
    >
      {content}
    </ProfileTemplate>
  );
};

export default compose(withProfile, withAuth)(Profile);
