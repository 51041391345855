//

import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from 'modules/v2/common/components/SvgIcon/CopyIcon';
import { Tooltip } from 'flowbite-react';
import { debounce } from 'lodash-es';
import { notification } from 'v2/common/utils';
import S from './styles';

const Link = ({ id, title, href, showPreview = true, showText = false }) => {
  const showNotification =
    debounce(() => notification.success({ description: 'Copied to clipboard!' }), 500, { leading: true });

  const handleCopy = () => {
    if (!href) return false;

    showNotification();

    return true;
  };

  return (
    <S.InputWithCopy>
      <S.InputWithCopy.Label htmlFor={id}>{title}</S.InputWithCopy.Label>

      <S.InputWithCopy.InputWrapper>
        <S.InputWithCopy.Input id={id} type="text" value={href} readOnly />

        <Tooltip
          content="Copy Link"
          className="w-[90px] text-center ml-tooltip"
          trigger="hover"
          style="dark"
          arrow
          placement="top"
          theme={{
            style: {
              dark: 'bg-neutral-800 text-white',
            },
          }}
        >
          <CopyToClipboard text={href} onCopy={handleCopy}>
            <S.InputWithCopy.Button>
              <div className='flex items-center gap-1 text-neutral-800'>
                <CopyIcon />
                {showText && <span>Copy</span>}
              </div>
            </S.InputWithCopy.Button>
          </CopyToClipboard>
        </Tooltip>
      </S.InputWithCopy.InputWrapper>

      {href && showPreview && (
        <S.InputWithCopy.Link className="mt-1  hover:text-primary-600" href={href} target="_blank">
          <span className="border-neutral-300 border-b border-solid">Preview</span>
        </S.InputWithCopy.Link>
      )}
    </S.InputWithCopy>
  );
};
export default Link;
