import { TickMark } from 'modules/v2/common/components/SvgIcon';
import { Box } from 'modules/v2/common/AtomicDesign/atoms';
import { cardImageUrl } from 'modules/v2/utils/guideUtils';

function SubscribeGuide() {
  return (
    <Box className='p-6'>
      <div className='flex flex-wrap lg:flex-nowrap gap-5 w-full'>
        <img
          className="object-cover w-[382px] max-h-[416px] rounded-lg mx-auto lg:mx-0"
          src={cardImageUrl}
          alt="guide-asset"
        />
        <div className='w-full lgg:w-auto flex-1 text-base'>
          <div className='text-lg font-bold text-center lg:text-left'>
            Why Use Our Guides?
          </div>
  
          <div className='flex items-start gap-2 self-stretch my-2'>
            <div>
              <TickMark />
            </div>
            <div>
              <span className='font-semibold mr-1'>Easily customize the guides.</span>
              <span className='font-medium text-neutral-600'>Make each guide your own by simply attaching your business card to the cover, giving it a professional, personalized touch.</span>
            </div>
          </div>
  
          <div className='flex items-start gap-2 self-stretch my-2'>
            <div>
              <TickMark />
            </div>
            <div>
              <span className='font-semibold mr-1'>Show sellers you’re a competent professional.</span>
              <span className='font-medium text-neutral-600'>Use these guides to show sellers you’re a knowledgeable and capable professional they can trust.</span>
            </div>
          </div>
  
          <div className='flex items-start gap-2 self-stretch my-2'>
            <div>
              <TickMark />
            </div>
            <div>
              <span className='font-semibold mr-1'>Demonstrate that you care about marketing with these guides.</span>
              <span className='font-medium text-neutral-600'>Use these materials to show your expertise and dedication to effective marketing strategies.</span>
            </div>
          </div>
  
        </div>
      </div>
    </Box>
  );
  
}

export default SubscribeGuide;