import styled from 'styled-components';
import * as color from 'modules/v2/common/theme/color';
import { device } from 'modules/v2/common/theme/mediaqueries';

const Title = styled.span`
  display: inline-block;
  font-size: 1em;
  line-height: 1.5em;
  color: ${color.Black};
  margin-bottom: 4px;

  @media ${device.tabletS} {
    font-size: 0.75em;
  }
`;

const RequiredSymbol = styled.span`
  color: ${color.ErrorWarning};
`;

const Error = styled.span`
  color: ${color.ErrorWarning};
`;

const Space = styled.div`
  height: 28px;
  width: 100%;
`;

const Label = styled.label`
  width: 100%;
  font-size: 14px;
  font-weight: 600;

  ${(props) =>
    props.$hasError
      ? `
    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${color.ErrorWarning};
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    border-color: ${color.ErrorWarning};
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: ${color.ErrorWarning} !important;
    box-shadow: none;
  } `
      : `
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${color.GreyFormBorder};
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
    border-color: ${color.GreyFormBorder};
  }

  .ant-input:focus,
  .ant-input-focused {
    border-color: ${color.GreyFormBorder};
    box-shadow: none;
  }
  `}
`;

export default {
  RequiredSymbol,
  Title,
  Error,
  Space,
  Label,
};
