import { call, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'modules/common/utils';

import { uploadHeadshotImage, resource, getError } from 'modules/api';
import { UPLOAD_USER_HEADSHOT } from 'modules/dashboard/store/constants';
import { formatImageUrl } from 'modules/dashboard/utils';

function* onUploadUserHeadshot({ payload }) {
  try {
    const request = resource(UPLOAD_USER_HEADSHOT, uploadHeadshotImage, formatImageUrl);
    const response = yield call(request, payload);

    notification.success({ description: 'Headshot was uploaded successfully' });
    yield put({ type: `${UPLOAD_USER_HEADSHOT}_SUCCEEDED`, payload: { 
      headshot: response,
    } });

    return response;
  } catch (error) {
    const response = getError(error);
    console.error(response);

    notification.error({ description: 'Headshot could not be uploaded' });

    return response;
  }
}

export function* watchUploadUserHeadshot() {
  yield takeLatest(UPLOAD_USER_HEADSHOT, onUploadUserHeadshot);
}
