import * as Yup from 'yup';

const phoneNumber = Yup.string()
  .transform((_value, originalValue) => originalValue?.replace(/-/g, '') ?? originalValue)
  .trim()
  .required('Phone number is required')
  .matches(/^(?!.*_).+$/, 'Phone number must be valid')
  .max(10, 'Please enter the valid phone number')
  .min(10, ' ');
  /** This is empty because we don't want to display errors while user is typing
   ** And it can't be blank (like just ''), because it count as a null message error to JavaScript,
   ** so there's no validation for this case
   */

export default phoneNumber;
