
import { ChevronLeft, ChevronRight } from 'modules/v2/common/components/SvgIcon';
import { cn } from 'modules/v2/common/utils';

interface INavigationProps {
  direction: 'left' | 'right';
  disabled: boolean;
  onClick: () => void;
};

const NavigationArrow = ({ direction, onClick, disabled }: INavigationProps) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={cn(
      'bg-neutral-100 transition-colors flex w-[38px] h-[38px] items-center justify-center p-2.5 rounded-md overflow-hidden shadow-xl',
      {
        'hover:bg-neutral-200': !disabled,
        'opacity-50 cursor-not-allowed': disabled
      }
    )}
  >
    {direction === 'left' ? (
      <ChevronLeft />
    ) : (
      <ChevronRight />
    )}
  </button>
);

export default NavigationArrow;