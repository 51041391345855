/* eslint-disable no-nested-ternary */
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { capitalize } from 'lodash-es';
import { getRouteGuidePreview } from 'modules/v2/routes/navigation';
import {
  GuideStatus,
} from 'modules/v2/utils';
import { htmlCreation } from 'modules/v2/pages/GuideCatalog/utils/htmlCreation';
import { withGuideProfile } from 'modules/dashboard/containers';
import { BookLabel } from 'modules/v2/pages/BookCatalog/components';

const GuidePreviewFirstTime = ({
  guideDetails,
  frontCoverResponse: guideFrontResponse,
  personalInformation,
  guideProfile,
  updateGuideProfile,
}) => {

  const guideFrontResponseData = guideFrontResponse.find(
    (item) => item.attributes.Name === guideDetails.link,
  );
  const { guidePreferences } = guideProfile;
  const { guideName, link, guideStatus } = guideDetails;
  const name = guideName || guideDetails.name;

  const [selectedColor, setSelectedColor] = useState('blue');
  const history = useHistory();

  const handlePreviewClick = (gName: string) => {
    const newGuidePreferences = guidePreferences.map((item) => {
      if (item.link === link) {
        return { ...item, selectedStatus: true };
      }
      return { ...item, selectedStatus: false };
    });
    updateGuideProfile({ guidePreferences: newGuidePreferences });
    history.push(getRouteGuidePreview(gName));
  };

  useEffect(() => {
    if (guidePreferences.length === 0) {
      return;
    }
    const selected = guidePreferences.find((item) => item.link === link);
    setSelectedColor(selected.selectedColor);
  }, [guidePreferences, link]);

  const finalHTML = htmlCreation(guideFrontResponseData, personalInformation, selectedColor);

  const isGuidePublished = guideStatus === GuideStatus.PUBLISHED;

  return (
    <div className="p-6">
      <div className="flex w-full justify-between">
        <div className='flex gap-5'>
          <div className="bg-grey-100 relative w-[194px] h-[250px] rounded-lg border neutral-300 object-cover group-hover:transform group-hover:scale-110 transition-transform duration-300 ease-in-out">
            {isGuidePublished && (
              <BookLabel
                className="z-50 text-success-500"
                label={capitalize(guideStatus)}
              />
            )}
            <div
              className="absolute top-0 left-0 max-w-full max-h-full origin-top-left scale-23 cursor-pointer"
              onClick={() => handlePreviewClick(name)}
              dangerouslySetInnerHTML={{ __html: finalHTML }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(withGuideProfile)(GuidePreviewFirstTime);
