import { call, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'modules/common/utils';

import { uploadBrokerLogo, resource, getError } from 'modules/api';
import { UPLOAD_USER_BROKERLOGO } from 'modules/dashboard/store/constants';
import { formatImageUrl } from 'modules/dashboard/utils';

function* onUploadUserBrokerLogo({ payload }) {
  try {
    const request = resource(UPLOAD_USER_BROKERLOGO, uploadBrokerLogo, formatImageUrl);
    const response = yield call(request, payload);

    notification.success({ description: 'Broker logo was uploaded successfully' });
    yield put({
      type: `${UPLOAD_USER_BROKERLOGO}_SUCCEEDED`,
      payload: { userBrokerLogo: response.url }
    });

    return response;
  } catch (error) {
    const response = getError(error);
    console.error(response);

    notification.error({ description: 'Broker logo could not be uploaded' });

    return response;
  }
}

export function* watchUploadUserBrokerLogo() {
  yield takeLatest(UPLOAD_USER_BROKERLOGO, onUploadUserBrokerLogo);
}
