import { BoltIcon, TickMark } from 'modules/v2/common/components/SvgIcon';
import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import { cardImageUrl } from 'modules/v2/utils/hideBookAccess';



function SubscribeBook({ setOpenModal }) {
  const handleClick = () => {
    setOpenModal(true);
  };

  return (
    <Box className='p-6'>
      <div className='flex flex-wrap lg:flex-nowrap gap-5 w-full'>
        <img
          className="object-cover w-[382px] max-h-[416px] rounded-lg mx-auto lg:mx-0"
          src={cardImageUrl}
          alt="book-asset"
        />
        <div className='w-full lgg:w-auto flex-1 text-base'>
          <div className='text-lg font-bold text-center lg:text-left'>
            Why Have Your Own Book?
          </div>

          <div className='flex items-start gap-2 self-stretch my-2'>
            <div>
              <TickMark />
            </div>
            <div>
              <span className='font-semibold mr-1'>Your Book Instantly Transforms You Into A Trusted Authority.</span>
              <span className='font-medium text-neutral-600'>Prospective clients will be impressed by the professional design, helpful content, and the fact that you’re a published author</span>
            </div>
          </div>
          <div className='flex items-start gap-2 self-stretch my-2'>
            <div>
              <TickMark />
            </div>
            <div>
              <span className='font-semibold mr-1'>Use Your Book To Generate Leads.</span>
              <span className='font-medium text-neutral-600'>Instead of offering the same thing as every other agent, you can offer something that makes you stand out.</span>
            </div>
          </div>
          <div className='flex items-start gap-2 self-stretch my-2'>
            <div>
              <TickMark />
            </div>
            <div>
              <span className='font-semibold mr-1'>You Close More Appointments When You Have A Book.</span>
              <span className='font-medium text-neutral-600'>When you give away your book at the beginning of a listing presentation. Sellers will be impressed, and every presentation will start off on the right foot.</span>
            </div>
          </div>
          <div className='flex items-start gap-2 self-stretch my-2'>
            <div>
              <TickMark />
            </div>
            <div>
              <span className='font-semibold mr-1'>Show People You Can Help Them By Actually Helping Them.</span>
              <span className='font-medium text-neutral-600'>When you give prospective clients a book, you’re educating them on how everything works. This creates goodwill and subtly demonstrates your expertise.</span>
            </div>
          </div>
          <div className='flex justify-center lg:justify-start gap-2 mt-4 mb-2'>
            <Button type='primary' className="py-3 lgg:w-[426px]" onClick={handleClick}>
              Need Books? <br /> Please call us at 904-695-9933
            </Button>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default SubscribeBook;