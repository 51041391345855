export const ALL = 'all';
export const APPROVED = 'approved';
export const FOR_REVIEW = 'for-review';
export const SUBMITTED = 'submitted';
export const WITH_LEADS = 'With Leads';
export const WITH_OUT_LEADS = 'Without Leads';
export const SENT_BOTH_MEMBERS_LEADS = 'Sent To Both Members and Leads';
export const MINIMUM_CHECKOUT_BOOK_QUANTITY = 8;
export const MINIMUM_CHECKOUT_BOOK_QUANTITY_FOR_DIGITAL_SUB = 8;
export const GUIDE_JOURNEY = 'guideJourney';
export const GUIDE_SETUP_PII_STEP = 'setupPII';
export const GUIDE_SETUP_SHIPPING_ADDRESS = 'setupShippingAddress';
export const GUIDE_CATALOG_STEP = 'accessGuideCatalog';

