import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Form, Formik } from 'formik';

import { isReady } from 'store/status';
import { withUserPreferences, } from 'modules/dashboard/containers';
import { withProfile } from 'modules/v2/containers';
import { withAuth } from 'modules/auth/containers';
import { Loader, UserAvatar, FormLabel } from 'modules/v2/common/components';

import { ProfileAvatar, DangerTriangleIcon } from 'modules/v2/common/components/SvgIcon';
import { Button, TextInput } from 'modules/v2/common/AtomicDesign/atoms';
import { LogoUploader } from 'modules/v2/common/AtomicDesign/molecules';
import { AddressForm } from 'modules/v2/common/AtomicDesign/organisms';
import { FileUploadModal } from 'modules/v2/components';
import validationSchema from './validationSchema';
import { isAtGuidesPage } from 'modules/v2/utils';

import { brokerageInfo } from 'modules/v2/common/validationSchema/brokerageInfo';

type PersonalInformationFormProps = {
  updateGuide?: boolean;
  avatar: string;
  uploadAvatar: () => void;
  profile: any;
  updateProfile: () => void;
  getUserPreferences: () => void;
  userPreferences: any;
  logo: string;
  uploadLogo: () => void;
  hideAddress?: boolean;
  isProfile?: boolean;
  brokerLogo: {
    status: boolean;
    value: string;
  },
};

const emptyAddress = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  country: '',
  pincode: '',
};

const emptyBrokerLogo = {
  status: false,
  value: '',
};

const emptyGuideFields = {
  email: '',
  firstName: '',
  lastName: '',
  name: '',
  phone: '',
  brokerLogo: { ...emptyBrokerLogo },
  companyName: '',
  publicAddress: { ...emptyAddress },
  avatar: '',
};

const PersonalInformationForm: React.FC<PersonalInformationFormProps> = ({
  updateGuide = false,

  avatar,
  uploadAvatar,
  profile,
  updateProfile,
  getUserPreferences,
  getProfile,
  updateUserPreferences,
  userPreferences,
  logo,
  uploadLogo,
  headshot,
  userBrokerLogo,
  uploadUserHeadshot,
  uploadUserBrokerLogo,
  hideAddress = true,
  isProfile = false,
}) => {
  const [loadingText, setLoadingText] = useState('Loading user preferences');
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [addBrokerageLogo, setAddBrokerageLogo] = useState(false);
  const [hasPreferences, setHasPreferences] = useState(true);
  const [hasBrokerageError, setHasBrokerageError] = useState(false);
  const [addressValues, setAddressValues] = useState({
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
  });

  let formFields = {
    ...emptyGuideFields,
    guides: { ...emptyGuideFields },
  };

  let hasGuideData = false;

  useEffect(() => {
    if (isProfile) {
      uploadLogo(imageUrl);
    }
  }, [imageUrl]);

  useEffect(() => {
    if (userBrokerLogo) {
      setImageUrl(userBrokerLogo?.data);
    }
  }, [userBrokerLogo]);

  const [userPreference, setUserPreference] = useState({
    preferenceBrokerLogo: {
      status: false,
      value: '',
    },
    preferenceComapnyName: '',
    preferenceEmail: '',
    preferenceFirstName: '',
    preferenceLastName: '',
    preferenceName: '',
    preferenceHeadshotImage: '',
    preferencePhone: '',
    preferencePublicAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
    },
  });

  const [fields, setFields] = useState({
    email: '',
    firstName: '',
    lastName: '',
    name: '',
    phone: '',
    publicAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
    },
    avatar: '',
  });

  const {
    data: {
      email: profileEmail,
      firstName: profileFirstName,
      lastName: profileLastName,
      phone: profilePhone,
    },
  } = profile;

  useEffect(() => {
    getProfile();
    getUserPreferences();
  }, []);

  useEffect(() => {
    if (userBrokerLogo) {
      setImageUrl(userBrokerLogo?.data);
    }
  }, [userBrokerLogo]);

  useEffect(() => {
    setIsLoading(true);
    if (profile?.status === 'STATUS_SUCCESS' && userPreferences?.status === 'STATUS_SUCCESS') {
      const profileData = profile?.data;
      const perferencesData = userPreferences?.data?.data?.data;

      hasGuideData = perferencesData?.guides?.length > 0;

      let name: string;
      let email: string;
      let phone: string;
      let brokerLogo: {
        status: boolean;
        value: string;
      };
      let publicAddress: {
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        country: string;
        pincode: string;
      };
      let firstName: string;
      let lastName: string;

      const avatar = perferencesData?.headshotImage || '';

      if (isProfile) {
        email = profileData?.email;
        phone = profileData?.phone;
        firstName = profileData?.firstName;
        lastName = profileData?.lastName;
        name = `${profileData?.firstName} ${profileData?.lastName}` || '';
        brokerLogo = perferencesData?.brokerLogo || { status: false, value: '' };
        publicAddress = perferencesData?.publicAddress;
      } else {
        email = perferencesData?.email || profileData?.email;
        phone = perferencesData?.phone || profileData?.phone;
        firstName = perferencesData?.firstName || profileData?.firstName;
        lastName = perferencesData?.lastName || profileData?.lastName;
        name = perferencesData?.name || `${perferencesData?.firstName} ${perferencesData?.lastName}` || `${profileData?.firstName} ${profileData?.lastName}`;
        brokerLogo = perferencesData?.brokerLogo || { status: false, value: '' };
        publicAddress = perferencesData?.publicAddress;
      }

      const updateProfile = {
        email,
        firstName,
        lastName,
        name,
        phone,
        brokerLogo,
        publicAddress,
        avatar,
      };
      setCompanyName(perferencesData?.companyName || '');
      setImageUrl(perferencesData?.brokerLogo?.value);
      setFields(updateProfile)
      setIsLoading(false)
      setAddBrokerageLogo(perferencesData?.brokerLogo?.status ?? false);

    }
  }, [profile, userPreferences, isProfile]);

  useEffect(() => {
    if (userPreferences?.status === 'STATUS_LOADING') {
      setIsLoading(true);
    }
    if (userPreferences?.status === 'STATUS_SUCCESS' && userPreferences?.data?.data) {
      setIsLoading(false);
      const { data } = userPreferences.data.data;
      const updateUserPreference = {
        preferenceBrokerLogo: data?.brokerLogo,
        preferenceComapnyName: data?.companyName,
        preferenceEmail: data?.email,
        preferenceFirstName: data?.firstName,
        preferenceLastName: data?.lastName,
        preferenceName: data?.name,
        preferenceHeadshotImage: data?.headshotImage,
        preferencePhone: data?.phone,
        preferencePublicAddress: data?.publicAddress,
      };
      setUserPreference(updateUserPreference);
    }
  }, [userPreferences]);

  const {
    preferenceBrokerLogo,
    preferenceComapnyName,
    preferenceEmail,
    preferenceFirstName,
    preferenceLastName,
    preferenceName,
    preferenceHeadshotImage,
    preferencePhone,
    preferencePublicAddress,
  } = userPreference;

  useEffect(() => {
    if (profile.status === 'STATUS_SUCCESS' && userPreferences.status === 'STATUS_SUCCESS') {
      let userData;
      if (isProfile) {
        setAvatarUrl(avatar?.data?.url || preferenceHeadshotImage);
        userData = {
          email: profileEmail || '',
          firstName: profileFirstName || '',
          lastName: profileLastName || '',
          name: `${profileFirstName} ${profileLastName}` || '',
          phone: profilePhone || preferencePhone || '',
          brokerLogo: {
            value: preferenceBrokerLogo?.value || logo || '',
            status: preferenceBrokerLogo?.status || false,
          },
          avatar: avatar || preferenceHeadshotImage || '',
          companyName: preferenceComapnyName || '',
        };
      } else {
        const name = preferenceName ? preferenceName?.split(' ') : '';
        setAvatarUrl(preferenceHeadshotImage || avatar?.data?.url || null);

        userData = {
          email: preferenceEmail || profileEmail || '',
          firstName: preferenceFirstName || name[0] || profileFirstName || '',
          lastName: preferenceLastName || name[1] || profileLastName,
          name: `${preferenceFirstName || name[0] || profileFirstName} ${preferenceLastName || name[1] || profileLastName}` || '',
          phone: preferencePhone || profilePhone || '',
          brokerLogo: {
            value: preferenceBrokerLogo?.value || logo || '',
            status: preferenceBrokerLogo?.status || false,
          },
          avatar: preferenceHeadshotImage || avatar,
          companyName: preferenceComapnyName || '',
        };
      }

      userData.publicAddress = {
        addressLine1: preferencePublicAddress?.addressLine1 || '',
        addressLine2: preferencePublicAddress?.addressLine2 || '',
        city: preferencePublicAddress?.city || '',
        state: preferencePublicAddress?.state || '',
        country: preferencePublicAddress?.country || '',
        pincode: preferencePublicAddress?.pincode || '',
      }

      setFields(userData);
    }

  }, [profile, userPreference]);

  const hasAvatar = !!avatar?.data?.url || !!preferenceHeadshotImage;

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleConfirm = (newFile) => {
    setIsOpen(false);

    if (!avatar.data.url) {
      uploadAvatar(newFile);
      uploadUserHeadshot(newFile);
    }

    if (isProfile) {
      return uploadAvatar(newFile);
    }

    return uploadUserHeadshot(newFile);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleProfileUpdate = () => {
    setLoadingText('Saving profile');

    const { guides, ...cleanFormFields } = formFields;

    updateProfile(cleanFormFields);
  };

  let content = <Loader title={loadingText} height="100%" />;

  const handlePreferencesUpdate = async () => {
    setLoadingText('Saving preferences');
    setIsLoading(true);
    const { guides, ...preferences } = formFields;

    if (updateGuide) {
      const updatedPreferences = { ...userPreferences };
      Object.entries(preferences).forEach(([key, value]) => {
        if (value && userPreferences[key] === undefined) {
          updatedPreferences[key] = value;
        }
      });

      updatedPreferences.guides = guides;
      return updateUserPreferences(updatedPreferences);
    }

    const { guides: _, ...updatedFormFields } = formFields;

    updateUserPreferences(updatedFormFields);
  };

  if (isLoading) {
    content = <Loader title={loadingText} height="100%" />;
  }

  useEffect(() => {
    if (imageUrl && imageUrl?.startsWith?.('data:')) {
      uploadUserBrokerLogo(imageUrl)
    }
  }, [imageUrl]);

  const onSave = (values) => {
    const brokerageFields = {
      companyName,
      brokerLogo: {
        status: addBrokerageLogo,
        value: userBrokerLogo?.data
      },
    }

    const saveCallback = () => {
      formFields = {
        ...values,
        headShotImage: headshot?.data?.url,
        headShotId: headshot?.data?._id,
        publicAddress: addressValues,
        ...brokerageFields,
      };

      formFields.guides = { ...formFields };
      formFields.guides.brokerLogo = { ...formFields.brokerLogo };
      formFields.guides.publicAddress = { ...formFields.publicAddress };

      const isMissingPhoneData = profile?.data?.phone === '';

      if (isMissingPhoneData) {
        handleProfileUpdate();
      }

      handlePreferencesUpdate();
    }

    if (addBrokerageLogo) {
      brokerageInfo.validate(brokerageFields)
        .then((_result: any) => {
          setHasBrokerageError(false);

          saveCallback();
        })
        .catch((_err: any) => setHasBrokerageError(true));
    } else {
      setHasBrokerageError(false);

      saveCallback();
    };
  };

  useEffect(() => {
    if (!isProfile && headshot?.data) {
      setAvatarUrl(headshot?.data?.url);
    }
  }, [headshot]);

  useEffect(() => {
    if (
      (userPreferences.status === 'STATUS_SUCCESS') &&
      fields.firstName
    ) {
      setIsLoading(true);
      setTimeout(() => {
        getUserPreferences();
      }, 2000);

      if (headshot?.data?.url) {
        setAvatarUrl(headshot.data?.url);
      }
      setIsLoading(false);
      setLoadingText('Loading Preferences');
    }
  }, [userPreferences.status]);

  const avatarImage = (hasAvatar, image) => {
    if (hasAvatar) {
      return (
        <div className="mb-6">
          <div className="flex flex-col items-start mb-4">
            <FormLabel className="text-neutral-800 text-sm font-semibold" title="Your Headshot Photo" required />
            <div className="text-neutral-500 text-sm font-medium">Recommended size: 520 x 520</div>
          </div>
          <div className="mb-6 flex items-center gap-6">
            <div className="max-h-[120px] max-w-[120px]">
              <UserAvatar
                avatar={image}
                bordered
                defaultAvatar={<ProfileAvatar />}
                size="120px"
              />
            </div>
            <Button type="outlined" onClick={handleOpen}>
              Change
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Button type="outlined" onClick={handleOpen} className="max-w-[492px] mt-3.5 mb-6" full>
        Upload Photo
      </Button>
    )
  };

  if (isReady(profile.status)) {
    content = (
      <Formik
        initialValues={fields}
        validationSchema={validationSchema}
        onSubmit={onSave}
        enableReinitialize
      >
        {({
          dirty,
          values,
          setFieldValue,
          errors,
          setFieldError,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          isValid,
        }) => {
          const formatPhoneNumber = (phoneNumber) => {
            const cleaned = phoneNumber.replace(/\D/g, '');
            const formatted = cleaned.replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');

            return formatted;
          };

          const handleChangeNumber = (event) => {
            const { name, value } = event.target;
            const formattedValue = formatPhoneNumber(value);
            setFieldValue(name, formattedValue);
          };

          const isDisabled = !isValid || !dirty;

          return (
            <Form
              className={isAtGuidesPage() ? "h-full flex flex-col justify-between" : ""}
              onSubmit={handleSubmit}
            >
              <div>
                {!hasPreferences && (
                  <div className='px-3 py-2 flex gap-3 bg-danger-50 rounded-lg'>
                    <div className='w-5 h-5'>
                      <DangerTriangleIcon fill='#E4483D' /></div>
                    <p className='w-fit text-danger-500 font-medium'>Please complete the missing information below to finalize your guide.</p>
                  </div>
                )}
                {avatarImage(hasAvatar, (headshot?.data?.url || avatarUrl))}
                <div className="flex gap-3.5 mb-3.5">
                  <div className="flex-1 flex flex-col mb-3.5 min-w-[130px]">
                    <FormLabel title="First Name" htmlFor="firstName" required>
                      <TextInput
                        id="firstName"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                      />
                      {errors.firstName ? (
                        <span className="text-error-500 mt-2">{errors.firstName}</span>
                      ) : null}
                    </FormLabel>
                  </div>
                  <div className="flex-1 flex flex-col mb-3.5 min-w-[130px]">
                    <FormLabel title="Last Name" htmlFor="lastName" required>
                      <TextInput
                        id="lastName"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                      />
                      {errors.lastName ? (
                        <span className="text-error-500 mt-2">{errors.lastName}</span>
                      ) : null}
                    </FormLabel>
                  </div>
                </div>

                <div className="flex gap-3.5 mb-3.5 flex-wrap max-lg:flex-col max-sm:flex-col">
                  <div className="flex-1 flex flex-col mb-3.5 min-w-[250px]">
                    <FormLabel title="Email" htmlFor="email" required>
                      <TextInput
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email ? (
                        <span className="text-error-500 mt-2">{errors.email}</span>
                      ) : null}
                    </FormLabel>
                  </div>
                  <div className="flex-1 flex flex-col mb-3.5 min-w-[250px]">
                    <FormLabel title="Phone Number" htmlFor="phone" required>
                      <TextInput
                        id="phone"
                        name="phone"
                        type="tel"
                        value={formatPhoneNumber(values.phone)}
                        onChange={handleChangeNumber}
                        mask="999-999-9999"
                      />
                      {errors.phone ? (
                        <span className="text-error-500 mt-2">{errors.phone}</span>
                      ) : null}
                    </FormLabel>
                  </div>
                </div>
                {isOpen && (
                  <FileUploadModal
                    isOpen={isOpen}
                    title="Upload Avatar"
                    type="profile"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                  />
                )}
                {!hideAddress && (
                  <div className="border-t border-neutral-200 py-6 mb-6">
                    <div className="mb-6">
                      <p className="text-base font-semibold">Your Public Address</p>
                      <p className="text-sm font-medium text-neutral-500">
                        The address you enter here will appear in your printed materials. Please note,
                        this is not your shipping address.
                      </p>
                    </div>
                    <AddressForm setAddressValues={setAddressValues} initialValues={fields?.publicAddress} />
                  </div>
                )}
                <div className="border-t border-neutral-200 pt-6">
                  {hasBrokerageError && (
                    <div className="mb-4">
                      <span className="text-error-500">
                        Brokerage info must be filled when it's enabled.
                      </span>
                    </div>
                  )}

                  <div className='p-3.5 mb-6 bg-neutral-50 border border-neutral-200 rounded-md'>
                    <LogoUploader
                      isActive={addBrokerageLogo}
                      setIsActive={(value) => {
                        if (!value) setHasBrokerageError(false);

                        setAddBrokerageLogo(value);
                      }}
                      imageUrl={imageUrl}
                      setImageUrl={setImageUrl}
                      companyName={companyName}
                      setCompanyName={setCompanyName}
                    />
                  </div>
                </div>
              </div>
              {!isAtGuidesPage() ? (
                <Button
                  buttonType="submit"
                  className='mt-6'
                  onClick={handleSubmit}
                >
                  Save Changes
                </Button>
              ) : (
                <div className="relative sticky bottom-0 bg-white border-t border-neutral-200 mt-6 p-6 w-full">
                  <Button
                    buttonType="submit"
                    className='min-w-full'
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </Button>
                </div>
              )}
            </Form>
          );
        }}

      </Formik>
    );
  }

  return (
    <div className="h-full w-full">
      {content}
    </div>
  );
};

export default compose(
  withProfile,
  withUserPreferences,
  withAuth,
)(PersonalInformationForm);
