import { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { useQuery } from 'react-query';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';

import { withAddress, withGuideProfile, withUserPreferences } from 'modules/dashboard/containers';
import { withProfile } from 'modules/v2/containers';
import {
  normalizeUrl,
  GuideCheckoutStatus,
  isDigitalPlan,
  isGuideOnlyCustomer,
} from 'modules/v2/utils';
import { Link } from 'modules/v2/draft/components';
import { fetchJourneyTodos, postJourneyTodos } from 'modules/api/common';
import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import { Tooltip } from 'modules/v2/common/AtomicDesign/molecules';
import { PersonalInformationForm, AddressForm } from 'modules/v2/common/AtomicDesign/organisms';
import {
  BookMinimalistic,
  ChecklistIcon,
  CheckIconGuides,
  DangerTriangleIcon,
  LinkMinimalistc2,
  DownloadIcon,
  QuestionCircleIcon,
} from 'modules/v2/common/components/SvgIcon';
import { fetchAllOrders, fetchGuidesListByEmail } from 'modules/api/authorify/guides';
import { showColorInGuides } from 'modules/v2/utils/guideUtils';
import {
  formatOrderedData,
  formatProductCatalog,
  getDefaultImage,
  getTitleName,
} from 'modules/v2/pages/GuideCatalog/utils';
import GuidePreview from 'modules/v2/pages/GuideCatalog/components/GuidePreview';
import {
  profilePlaceholder,
  formatPhoneNumber,
  getName,
} from 'modules/v2/utils/userPreferencesUtils';
import { getItem } from 'modules/dashboard/utils/legacy';
import FirstTimeAccess from './components/FirstTimeAccess';
import {
  GUIDE_JOURNEY,
  GUIDE_SETUP_PII_STEP,
  GUIDE_SETUP_SHIPPING_ADDRESS,
} from '../../../constants';

const GuidesContainer = ({
  profile,
  avatar,
  guideProfile,
  updateGuideProfile,
  addresses,
  getUserAddresses,
  getUserPreferences,
  userPreferences,
  guideResponse,
  isGuideLoading,
  hasBookAccess,
}) => {
  const [setupForm, setSetupForm] = useState('personal-information');
  const [firstTimeHomePage, setFirstTimeHomePage] = useState(false);
  const [isFormCompleted, setIsFormCompleted] = useState(true);
  const [address, setAddress] = useState(null);
  const [preferencesCompleted, setPreferencesCompleted] = useState(false);
  const [shippingAddressCompleted, setShippingAddressCompleted] = useState(false);
  const [productCatalogData, setProductCatalogData] = useState([]);
  const [productCatalog, setProductCatalog] = useState([]);
  const [orderedList, setOrderedList] = useState([]);
  const [orderedListIp, setOrderedListIp] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [guideTask, setGuideTask] = useState({
    guideTaskId: '',
    guidePIIStep: null,
    guideAddressStep: null,
  });
  const [userPreference, setUserPreference] = useState({
    companyLogo: '',
    companyName: '',
    email: '',
    name: '',
    profileImage: '',
    phoneNumber: '',
    address: '',
  });

  const { data: profileData } = profile;
  const { email, firstName, lastName, phone } = profileData;
  const profileImageUrl = avatar?.data || '';
  const { guidePreferences } = guideProfile;

  const { data: orderList, isLoading: isOrderLoading } = useQuery('getOrders', () =>
    fetchAllOrders({ customerEmail: email }),
  );

  const { data: guideList, isLoading: isGuideListLoading } = useQuery(
    ['getGuidesList', email],
    () => fetchGuidesListByEmail(email),
  );

  const isDigitalUser = isDigitalPlan();

  useEffect(() => {
    if (!isGuideLoading) {
      const payloadData = guideResponse.data;
      const newPayloadData = payloadData.map(item => {
        const showColor = showColorInGuides.includes(item?.id);
        const showInfographics = !isEmpty(item?.attributes?.infographics);
        return {
          id: item?.id,
          name: item?.attributes?.Name,
          title: item?.attributes?.frontCover?.title,
          showColor,
          showInfographics,
        };
      });
      setProductCatalog(newPayloadData);
    }
  }, [guideResponse, isGuideLoading]);

  useEffect(() => {
    if (!isOrderLoading && !isGuideListLoading) {
      const payloadData = orderList.data.payload;
      const orderedHistory = productCatalog.map((item) => {
        const dataLoad = formatOrderedData(item, payloadData);
        if (dataLoad) {
          return {
            ...item,
            guideName: item.name,
            ...dataLoad,
            orderId: dataLoad?._id,
          };
        }
        return item;
      });

      setOrderedList(orderedHistory);
      const getIpOrders = payloadData.filter(
        (item) => item.status === GuideCheckoutStatus.IN_PROGRESS,
      );
      if (getIpOrders.length) {
        setInProgressOrders(getIpOrders);
      }
      // Integrating Guides list
      const payloadGuideData = guideList?.data?.filter(Boolean)[0];
      const formattedCatalog = formatProductCatalog(payloadGuideData, orderedHistory);
      setProductCatalogData(formattedCatalog);
    }
  }, [guideList, isGuideListLoading, isOrderLoading, orderList, productCatalog]);

  useEffect(() => {
    if (!isOrderLoading && !isEmpty(orderList?.data?.payload)) {
      const payloadOrderData = orderList.data.payload;
      if (payloadOrderData.length) {
        const orderedHistory = productCatalog.map((item) => {
          const dataLoad = formatOrderedData(item, payloadOrderData);
          if (dataLoad) {
            return {
              ...item,
              guideName: item.name,
              ...dataLoad,
              orderId: dataLoad?._id,
            };
          }
          return null;
        });
        const filteredOrderedHistory = orderedHistory.filter(Boolean);
        setOrderedListIp(filteredOrderedHistory);
      }
    }
  }, [isOrderLoading, orderList, productCatalog]);

  // TODO: Fix that when we have the guide plan, update the function to check if the user has the guide plan
  const isOnlyGuideCustomer = isGuideOnlyCustomer();

  useEffect(() => {
    getUserPreferences();
    getUserAddresses();
  }, [getUserAddresses, getUserPreferences]);

  // const processJourneyTodos = (data) => {
  //   const guideJourneyTask = data?.find((task) => task.name === GUIDE_JOURNEY);
  //   const guideAddressTask = data?.find((step) => step.name === GUIDE_SETUP_SHIPPING_ADDRESS);
  //   if (!guideJourneyTask) {
  //     return;
  //   }

  //   const guidePIIStep = guideJourneyTask.steps?.find((step) => step.name === GUIDE_SETUP_PII_STEP);

  //   setGuideTask({
  //     guideTaskId: guideJourneyTask?.id,
  //     guidePIIStep,
  //     guideAddressStep: guideAddressTask,
  //   });

  //   if (guidePIIStep.isCompleted) {
  //     setFirstTimeHomePage(false);
  //   } else {
  //     setFirstTimeHomePage(true);
  //   }
  // };

  // useQuery('fetchJourneyTodos', () => fetchJourneyTodos(), {
  //   onSuccess: ({ data }) => processJourneyTodos(data),
  // });

  useEffect(() => {
    if (userPreferences?.status === 'STATUS_SUCCESS') {
      const preferencesData = userPreferences?.data?.data?.data;
      const publicAddress = preferencesData?.publicAddress;
      if (publicAddress) {
        setAddress(publicAddress);
      }
      if (preferencesData) {
        function validateFields(data) {
          const fields = ['firstName', 'lastName', 'phone', 'headshotImage', 'email'];

          const invalidFields = fields.filter((field) => {
            return !data[field] || data[field].trim() === '';
          });

          if (invalidFields.length > 0) {
            return false;
          }
          return true;
        }

        setPreferencesCompleted(validateFields(preferencesData));
        const phoneDetails = preferencesData?.phone ? formatPhoneNumber(preferencesData.phone) : '';
        const updatePreferences = {
          companyLogo: preferencesData?.brokerLogo?.value || '',
          companyName: preferencesData?.companyName || '',
          email: preferencesData?.email || email,
          name: getName(preferencesData, firstName, lastName),
          profileImage: preferencesData?.headshotImage || profileImageUrl.url || profilePlaceholder,
          phoneNumber: phoneDetails || (phone && formatPhoneNumber(phone)) || '',
          showBrokerInfo: preferencesData?.brokerLogo?.status || false,
        };
        setUserPreference(updatePreferences);
      }
    }
  }, [email, firstName, lastName, phone, userPreferences, profileImageUrl]);

  useEffect(() => {
    if (addresses?.status === 'STATUS_SUCCESS') {
      const defaultAddress = addresses.data.find((address) => address.isDefault === true);
      setAddress(defaultAddress);
      if (defaultAddress) {
        setShippingAddressCompleted(true);
      }
    }
  }, [addresses]);

  useEffect(() => {
    if (preferencesCompleted && shippingAddressCompleted) {
      setIsFormCompleted(true);

      if (guidePreferences.length === 0) {
        let productCatalogDataResponse;
        if (!isGuideListLoading && !isEmpty(guideList?.data)) {
          const payloadData = guideList?.data?.filter(Boolean)[0];
          const result = formatProductCatalog(payloadData, productCatalog);
          productCatalogDataResponse = [...result];
          const newGuidePreferences = productCatalogDataResponse.map((item) => ({
            ...item,
            selectedColor: 'blue',
            selectedStatus: item.name === 'Seller',
          }));
          updateGuideProfile({ guidePreferences: newGuidePreferences });
        }
      }
    } else {
      setIsFormCompleted(false);
    }
  }, [guideList, guidePreferences, isGuideListLoading, preferencesCompleted, productCatalog, shippingAddressCompleted, updateGuideProfile]);

  let content = null;

  if (setupForm === 'personal-information') {
    content = (
      <div>
        <p className="mb-6 text-base font-semibold">Your Personal Information</p>
        <PersonalInformationForm updateGuide />
      </div>
    );
  }

  if (setupForm === 'address') {
    content = (
      <div className="flex flex-col gap-6">
        <p className="text-base font-semibold">{`Your ${!isDigitalUser ? 'Shipping' : ''
        } Address`}</p>

        <AddressForm initialValues={address} showButton hideCancelButton />
      </div>
    );
  }

  const targetRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const isFirstTime = getItem('firstTimeGuidePII');
    if (!isFirstTime) {
      setFirstTimeHomePage(true);
    }
    if (firstTimeHomePage && !isFormCompleted && isOnlyGuideCustomer) {
      targetRef.current.focus();
      targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      localStorage.removeItem('isWJGuidesCompleted');
    }
    if (isFormCompleted) {
      localStorage.setItem('isWJGuidesCompleted', 'true');
    } else {
      localStorage.setItem('isWJGuidesCompleted', 'false');
    }
  }, [isFormCompleted]);

  const handleClickFirstTime = async () => {
    setFirstTimeHomePage(false);
  };

  // const handleCompleteAddressStep = async () => {
  //   await postJourneyTodos({
  //     taskId: guideTask?.guideAddressStep?.id,
  //     stepId: guideTask?.guideAddressStep?.steps[0]?.id,
  //   });
  // };

  // const handleCompletePIIStep = async () => {
  //   await postJourneyTodos({
  //     taskId: guideTask?.guideTaskId,
  //     stepId: guideTask?.guidePIIStep?.id,
  //   });
  // };

  // useEffect(() => {
  //   const wjTaskCompleted =
  //     guideTask?.guidePIIStep?.isCompleted && guideTask?.guideAddressStep?.status === 'completed';
  //   if (preferencesCompleted && shippingAddressCompleted && wjTaskCompleted) {
  //     setIsFormCompleted(true);
  //     return;
  //   }

  //   if (preferencesCompleted && !guideTask?.guidePIIStep?.isCompleted) {
  //     handleCompletePIIStep();
  //   }

  //   if (shippingAddressCompleted && !(guideTask?.guideAddressStep?.status === 'completed')) {
  //     handleCompleteAddressStep();
  //   }
  // }, [
  //   guideTask,
  //   handleCompleteAddressStep,
  //   handleCompletePIIStep,
  //   preferencesCompleted,
  //   shippingAddressCompleted,
  // ]);

  let frontCoverResponse;
  if (!isEmpty(guideResponse)) {
    frontCoverResponse = guideResponse?.data;
  }

  const showYourGuides = () => {
    const guides = orderedListIp?.map((order) => {
      const { status, name, guide } = order;
      const isPublished = status === 'DONE';
      const guideTitle = Object.keys(getDefaultImage).find((item) => name.includes(item));
      const thumbnail = order.thumbnail || getDefaultImage[guideTitle];
      const payloadData = guideList?.data?.filter(Boolean)[0].find((item) => item._id === guide);
      let lcsLink;
      let digitalLink;
      if (!isEmpty(payloadData)) {
        lcsLink = payloadData?.output?.landingCaptureSiteUrl;
        digitalLink = payloadData?.output?.pdf?.spreadLayout;
      }
      return (
        <div className="border-t border-neutral-200">
          <div className="p-6 flex max-md:flex-wrap gap-[18px] font-semibold">
            <img
              src={thumbnail}
              alt={order.guideName || guideTitle}
              className="w-[196px] h-[250px]"
            />
            <div>
              <div className="flex flex-wrap gap-2">
                <div className="text-base">{getTitleName[order.guideName]}</div>
                <div className="flex items-center gap-2 py-1 px-2 rounded-md border border-neutral-200 text-xs h-fit">
                  <>
                    <BookMinimalistic />
                    <span>Print & Digital</span>
                  </>
                </div>
              </div>

              {isPublished && (
                <div className="flex flex-wrap gap-5 my-6">
                  <div>
                    <div className="w-[450px] max-sm:w-full">
                      <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                        Lead Capture Site
                        <Tooltip
                          content="Share your guide digitally to showcase expertise and build trust with remote clients. "
                          trigger="hover"
                          style="dark"
                          arrow
                          placement="top"
                          theme={{
                            base:
                              'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                          }}
                        >
                          <QuestionCircleIcon />
                        </Tooltip>
                      </h5>
                      <Link
                        key="landingPage"
                        id="landingPage"
                        showPreview={false}
                        href={normalizeUrl(lcsLink)}
                        title={null}
                        showText
                      />
                    </div>
                  </div>

                  <div>
                    <div className="w-[450px] max-sm:w-full">
                      <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                        Digital Flip Book
                        <Tooltip
                          content="Share your guide digitally to showcase expertise and build trust with remote clients. "
                          trigger="hover"
                          style="dark"
                          arrow
                          placement="top"
                          theme={{
                            base:
                              'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                          }}
                        >
                          <QuestionCircleIcon />
                        </Tooltip>
                      </h5>
                      <Link
                        key="landingPage"
                        id="landingPage"
                        showPreview={false}
                        href={normalizeUrl(digitalLink)}
                        title={null}
                        showText
                      />
                    </div>
                  </div>
                </div>
              )}

              {isPublished && (
                <div className="w-[470px] max-sm:w-full">
                  <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                    Infographic
                    <Tooltip
                      content="Download a printable version of your infographic"
                      trigger="hover"
                      style="dark"
                      arrow
                      placement="top"
                      theme={{
                        base:
                          'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                      }}
                    >
                      <QuestionCircleIcon />
                    </Tooltip>
                  </h5>
                  <Button type="muted" onClick={() => window.open(order.infographic, '_blank')}>
                    <div className="flex items-center gap-2">
                      <DownloadIcon />
                      <span>Download</span>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
    return guides;
  };

  return (
    <div>
      {!isFormCompleted && isOnlyGuideCustomer && (
        <div className="flex items-center gap-3 py-2.5 px-3 bg-secondary-orange-50 rounded-md">
          <DangerTriangleIcon />
          <p className="text-base text-secondary-orange-600 font-semibold">
            Please complete the setup checklist below to customize your guides.
          </p>
        </div>
      )}

      {orderedList?.length > 0 && isOnlyGuideCustomer && (
        <Box className="my-6">
          <div className="py-[22px] px-6 font-semibold text-base">
            <p>Your Guides</p>
          </div>

          {showYourGuides()}
        </Box>
      )}

      {!isFormCompleted && isOnlyGuideCustomer && (
        <>
          <Box className='mt-6'>
            <div className="flex w-full max-lg:flex-col">
              <div
                className="p-6 w-full max-w-[300px] border-r border-neutral-200 max-lg:border-none"
                ref={targetRef}
                tabIndex={-1}
              >
                <div className="text-lg font-semibold flex items-center gap-2.5 mb-3.5">
                  <ChecklistIcon />
                  <span>Setup Checklist</span>
                </div>

                <button
                  onClick={() => setSetupForm('personal-information')}
                  className={classNames(
                    'w-full',
                    setupForm === 'personal-information' ? 'cursor-auto' : 'cursor-pointer',
                  )}
                >
                  <div
                    className={classNames(
                      'text-sm font-semibold flex items-center gap-3 py-2.5 px-3 rounded-md hover:bg-neutral-100',
                      setupForm === 'personal-information' ? 'bg-neutral-100' : 'bg-white',
                    )}
                  >
                    <div
                      className={classNames(
                        'w-4 h-4 rounded-full flex items-center justify-center border border-neutral-200',
                        preferencesCompleted ? 'bg-success-500' : 'bg-white',
                      )}
                    >
                      <CheckIconGuides width="10" height="10" />
                    </div>
                    <span>Your Personal Information</span>
                  </div>
                </button>

                <button
                  onClick={() => setSetupForm('address')}
                  className={classNames(
                    'w-full',
                    setupForm === 'address' ? 'cursor-auto' : 'cursor-pointer',
                  )}
                >
                  <div
                    className={classNames(
                      'text-sm font-semibold flex items-center gap-3 py-2.5 px-3 rounded-md mt-2 hover:bg-neutral-100',
                      setupForm === 'address' ? 'bg-neutral-100' : 'bg-white',
                    )}
                  >
                    <div
                      className={classNames(
                        'w-4 h-4 rounded-full flex items-center justify-center border border-neutral-200',
                        shippingAddressCompleted ? 'bg-success-500' : 'bg-white',
                      )}
                    >
                      <CheckIconGuides width="10" height="10" />
                    </div>
                    <span>{isDigitalUser ? 'Your Address' : 'Your Shipping Address'}</span>
                  </div>
                </button>
              </div>
              <div className="p-6 w-full max-w-screen-lg">{content}</div>
            </div>
          </Box>
          <FirstTimeAccess
            showModal={firstTimeHomePage}
            setShowModal={handleClickFirstTime}
            isDigitalOnly={isDigitalUser}
          />
        </>
      )}

      {(isFormCompleted || !isOnlyGuideCustomer) && (
        <Box className="p-6 mt-6">
          <p className="text-lg font-semibold mb-1">Explore More of Our Guides</p>
          <p className="text-sm font-medium text-neutral-600 mb-3.5">
            Our Guides cover a range of topics designed to support your growth and success.
          </p>
          <div className="w-full rounded-b-lg">
            {productCatalogData.map((guide) => (
              <GuidePreview
                key={guide.id}
                guideDetails={guide}
                frontCoverResponse={frontCoverResponse}
                personalInformation={userPreference}
                inProgressOrders={inProgressOrders}
                isOnlyGuideCustomer={isOnlyGuideCustomer}
              />
            ))}
          </div>
        </Box>
      )}
    </div>
  );
};

export default compose(
  withAddress,
  withProfile,
  withGuideProfile,
  withUserPreferences,
)(GuidesContainer);
