import {
  isGuideOnlyCustomer,
  objectHasValidEntries,
} from 'modules/v2/utils';

export const GuideCheckoutStatus = {
  DONE: 'DONE',
  IN_PROGRESS: 'IN_PROGRESS'
};

export const GuidePreviewBtn = {
  PREVIEW: 'Preview',
  VIEW: 'View',
};

export const GuideStatus = {
  PUBLISHED: 'published',
};

export const GuideCheckoutPlaceOrderButton = {
  SHIPNOW: 'Ship Now',
  PLACEORDER: 'Place Order',
};

export const GuideStatusButton = {
  FINALIZE: 'Customize',
  BUY_AGAIN: 'Reorder Print Copies',
  BUY_PRINT: 'Order Print Copies'
};

export const DigitalOption = {
  name: 'Digital Only',
};

export const isGuideInProgress = (isOrderAvailable: boolean, orderStatus: string) =>
  Boolean(isOrderAvailable && orderStatus === 'IN_PROGRESS');

export const getButtonLabel = (isOrderAvailable: boolean, orderStatus: string) => {
  if(isOrderAvailable && orderStatus === 'DONE') return 'Reorder Print Copies';
  return 'Order Print Copies';
};

export const getEditButtonLabel = (hasOrder: boolean, isFinalized: boolean, guideStatus: string) => {
  if (isGuideOnlyCustomer() && hasOrder) return 'Publish';
  if (!isGuideOnlyCustomer() && isFinalized && !(guideStatus === 'published')) return 'Publish Digital';
  return 'Buy Print';
};

export const colorPick = {
  blue: '#4073aa',
  red: '#970E1E',
  purple: '#6f617c',
  white: '#898782',
  gold: '#c1a46c'
};

export const guideColor = ['blue', 'gold', 'white', 'purple', 'red'];
export const showColorInGuides = [1,2,4,5];
export const pageOneHandleBar = [[3, 6]];

export const getFooterTOC = () => {
  return `© ${new Date().getFullYear()} Authorify`;
};

export const cardImageUrl = 'https://afy-ui.s3.us-east-1.amazonaws.com/b241465b66e2f159d6aee2547260b0a5.png';

export const formatGuideNameWithPlural = (guideName) => {
  return guideName?.replace('(', '')?.replace(')', '').replace(' &', '');
};

export const checkOrderedGuideName = (orderGuideName: string, guideName: string) => {
  return orderGuideName.includes(guideName);
};

/**
 * PII means: Personal Information
 */
export const hasRequiredPII = (pii: any) => {
  const fieldsToValidate = ['email', 'name', 'phoneNumber', 'profileImage'] as (keyof typeof pii)[];


  return objectHasValidEntries(pii, fieldsToValidate);
};
