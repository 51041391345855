import { useMutation } from 'react-query';
import { checkCustomerPaymentProfile, getPaymentProfilesByEmail } from 'modules/api';

interface IOptions {
  onSuccess?: (data: any) => void;
  onError?: (error: string, actions: any) => void;
}

interface IProfile {
  email: string;
  firstName: string;
  lastName: string;
}

interface IProfileCreationPayload {
  email: string;
  first_name: string;
  last_name: string;
}

const mountProfilePayload = (profile: IProfile): IProfileCreationPayload => ({
  email: profile.email,
  first_name: profile.firstName,
  last_name: profile.lastName,
});

export const useGetPaymentProfiles = (options: IOptions) =>
  useMutation('getPaygmentsProfileByEmail', getPaymentProfilesByEmail, options);

export const useCheckForCustomerPaymentProfile = (profile: IProfile, options: IOptions) =>
  useMutation('checkForCustomerPaymentProfile', () => checkCustomerPaymentProfile(mountProfilePayload(profile)), options);
