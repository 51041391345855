import { get } from 'lodash-es';

import defaultAvatar from 'assets/images/avatar.jpg';

import { isLoading } from 'store/status';
import ImagePlaceholder from '../ImagePlaceholder';

const UserAvatar = ({ avatar, size, scale }) => {
  let avatarImage = <img src={defaultAvatar} alt="avatar" className="rounded-full" />;

  let avatarUrl;

  if (typeof avatar === 'string') {
    avatarUrl = avatar;
  } else if (avatar !== null) {
    avatarUrl = get(avatar.data, 'url');
  }

  if (avatarUrl) {
    avatarImage = (
      <img
        className="rounded-full w"
        data-testid="loaded-image"
        src={avatarUrl}
        alt="avatar"
        size={size}
      />
    );
  }

  if (isLoading(avatar?.status)) {
    avatarImage = <ImagePlaceholder width={size} height={size} scale={scale} />;
  }

  return <div className="border border-neutral-300 rounded-full">{avatarImage}</div>;
};

export default UserAvatar;
