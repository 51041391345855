/* eslint-disable no-nested-ternary */
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { capitalize } from 'lodash-es';
import {
  BookMinimalistic,
  BuyAgainIcon,
  CheckIconGuides,
  DownloadIcon,
  EyeIcon,
  LinkMinimalistc2,
  PencilIcon,
  QuestionCircleIcon,
  TickMark,
} from 'modules/v2/common/components/SvgIcon';
import { Button, ThumbnailPreview } from 'modules/v2/common/AtomicDesign/atoms';
import { getMyCheckout, getRouteGuidePreview } from 'modules/v2/routes/navigation';
import {
  checkOrderedGuideName,
  getButtonLabel,
  guideColor,
  GuideStatus,
  GuideStatusButton,
  isGuideInProgress,
  normalizeUrl,
  hasRequiredPII,
} from 'modules/v2/utils';
import { cn } from 'modules/v2/common/utils';
import { BookLabel } from 'modules/v2/pages/BookCatalog/components';
import { htmlCreation } from 'modules/v2/pages/GuideCatalog/utils/htmlCreation';
import { Link } from 'modules/v2/draft/components';
import { Tooltip } from 'modules/v2/common/AtomicDesign/molecules';
import { withGuideProfile, withProfile, withAddress } from 'modules/dashboard/containers';

const GuidePreview = ({
  addresses,
  getUserAddresses,
  isCanadianAddress,
  profile,
  guideDetails,
  frontCoverResponse: guideFrontResponse,
  personalInformation,
  guideProfile,
  updateGuideProfile,
  inProgressOrders,
  isOnlyGuideCustomer,
}) => {
  const guideFrontResponseData = guideFrontResponse.find(
    (item) => item.id === guideDetails.id,
  );

  const isOrderInProgress = () => Boolean(
    inProgressOrders?.length &&
    inProgressOrders?.filter((item) => checkOrderedGuideName(item.name, guideDetails.name))?.length > 0
  );

  const { guidePreferences } = guideProfile;
  const {
    guideName,
    title,
    guideStatus,
    orderStatus,
    showColor = true,
    id: guideId,
    output = {},
    status = 'NEW',
  } = guideDetails;
  const name = guideName || guideDetails.name;

  const [selectedColor, setSelectedColor] = useState('blue');
  const [isOrderAvailable, setIsOrderAvailable] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState(null);
  const [hideShippingOptions, setHideShippingOptions] = useState(false);

  const history = useHistory();

  const handleCheckout = (urlLinkParam: string) => {
    const newGuidePreferences = guidePreferences.map((item) => {
      if (item.id === guideId) {
        return { ...item, selectedStatus: true };
      }
      return { ...item, selectedStatus: false };
    });

    updateGuideProfile({ guidePreferences: newGuidePreferences });

    if (personalInformation?.isFinalized || hasRequiredPII(personalInformation)) {
      if (isOrderInProgress()) {
        history.push(getMyCheckout('finalized'));
      } else {
        history.push(getMyCheckout('new'));
      }
    } else {
      history.push(getRouteGuidePreview(urlLinkParam));
    }
  };

  const handlePreviewClick = (urlLinkParam) => {
    const newGuidePreferences = guidePreferences.map((item) => {
      if (item.id === guideId) {
        return { ...item, selectedStatus: true };
      }
      return { ...item, selectedStatus: false };
    });
    updateGuideProfile({ guidePreferences: newGuidePreferences });
    history.push(getRouteGuidePreview(urlLinkParam));
  };

  useEffect(() => {
    if (guidePreferences.length === 0) {
      return;
    }
    const selected = guidePreferences.find((item) => item.id === guideId);
    setSelectedPreference(selected);
    setSelectedColor(selected.selectedColor);
  }, [guideId, guidePreferences]);

  useEffect(() => {
    if (guideDetails?.orderId) {
      setIsOrderAvailable(true);
    }

  }, [guideDetails]);

  const handleColorChange = (colorItem) => {
    setSelectedColor(colorItem);

    if (selectedPreference) {
      setSelectedPreference({ ...selectedPreference, selectedColor: colorItem });
      const newGuidePreferences = guidePreferences.map((item) => {
        if (item.id === guideId) {
          return { ...item, selectedColor: colorItem };
        }
        return item;
      });
      updateGuideProfile({ guidePreferences: newGuidePreferences });
    } else {
      setSelectedPreference({ name, title, color: colorItem });
      const newGuidePreferences = [
        ...guidePreferences,
        { name, title, color: colorItem, guideStatus, orderStatus },
      ];
      updateGuideProfile({ guidePreferences: newGuidePreferences });
    }
  };

  const finalHTML = htmlCreation(guideFrontResponseData, personalInformation, selectedColor);

  const isGuidePublished = guideStatus === GuideStatus.PUBLISHED;

  const BookTitleBadge = () => {
    const mustRenderBadge = Boolean(isOrderAvailable || isGuidePublished);

    if (!mustRenderBadge) return <></>;

    return (
      <div className="flex items-center gap-2 py-1 px-2 rounded-md border border-neutral-200 text-xs h-fit">
        {isOrderAvailable && (
          <>
            <BookMinimalistic />
            <span>Print & Digital</span>
          </>
        )}
        {!isOrderAvailable && isGuidePublished && (
          <>
            <LinkMinimalistc2 />
            <span>Digital</span>
          </>
        )}
      </div>
    );
  }

  const guideButtonLabel = getButtonLabel(isOrderAvailable, status);

  const guideButtonIcon = () => {
    if (guideButtonLabel === GuideStatusButton.BUY_AGAIN)
      return <BuyAgainIcon />;

    return <BookMinimalistic fill="#252525" />;
  };

  const mustDisableGuideButton = () => isCanadianAddress && !isGuideInProgress;

  useEffect(() => {
    setHideShippingOptions(isCanadianAddress);
  }, [addresses]);

  useEffect(() => {
    getUserAddresses();
  }, []);

  const fallbackThumbnail = output?.pdf?.thumbnail?.large;

  const lcsThumbnail = output?.pdf?.firstPage;
  const flipBookThumbnail = output?.pdf?.firstPage;

  const infographicThumbnail = output?.pdf?.infographic?.includes('.pdf')
    ? fallbackThumbnail
    : output?.pdf?.infographic;

  return (
    <div className="border-b p-6">
      <div className="flex gap-6 flex-wrap w-full">
        <div className="flex gap-5 w-[840px] max-lg:w-full max-lg:flex-wrap">
          <div className="relative group">
            <div className="bg-grey-100 relative min-w-[194px] min-h-[250px] neutral-300 object-cover overflow-hidden rounded-lg border">
              {isGuidePublished && (
                <BookLabel
                  className="z-50 text-success-500"
                  label={capitalize(guideStatus)}
                />
              )}

              <div
                className="absolute top-0 left-0 max-w-full max-h-full origin-top-left scale-23 cursor-pointer group-hover:scale-25 group-hover:transform group-hover:ease-in-out transition-transform duration-300"
                onClick={() => handlePreviewClick(guideId)}
                dangerouslySetInnerHTML={{ __html: finalHTML }}
              />

              <div className="absolute min-h-full px-6 inset-0 bg-black bg-opacity-40 rounded-lg flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <Button className="min-w-full" type="outlined" onClick={() => handlePreviewClick(guideId)}>
                  <EyeIcon />
                  <span className="ml-2">Preview</span>
                </Button>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="ext-base font-semibold">
              <div className="flex flex-row gap-2">
                <p className="lg:truncate">{title}</p>
                <BookTitleBadge />
              </div>
              <span className="text-neutral-500">{name}</span>
            </div>
            {showColor &&
              <div className="py-6 flex gap-2 flex-row">
                {guideColor.map((item) => (
                  <div
                    key={item}
                    className={cn(
                      `h-[26px] w-[26px] flex items-center justify-center rounded-full hover:cursor-pointer hover:outline-primary-400 hover:outline hover:outline-offset-1 hover:outline-2 bg-guides-${item}`,
                    )}
                    onClick={() => handleColorChange(item)}
                  >
                    {selectedColor === item && <CheckIconGuides />}
                  </div>
                ))}
              </div>
            }

            <div className="flex flex-row items-end mt-auto gap-3">
              <Button type="primary" onClick={() => handleCheckout(guideId)}>
                <div className="flex justify-center items-center gap-2 text-sm w-[171px] font-bold">
                  <span>{guideButtonIcon()}</span>
                  <span>{guideButtonLabel}</span>
                </div>
              </Button>

              {!isOnlyGuideCustomer && guideStatus !== GuideStatus.PUBLISHED && (
                <div>
                  <Button type="outlined" onClick={() => handlePreviewClick(guideId)}>
                    <div className="flex justify-center items-center gap-2 text-sm w-[171px] font-bold">
                      <LinkMinimalistc2 fill="#252525" />
                      <span>Generate Digital</span>
                    </div>
                  </Button>
                </div>
              )}

              <div>
                <Button type="outlined" onClick={() => handlePreviewClick(guideId)}>
                  <div className="flex justify-center items-center gap-2 text-sm w-[171px] font-bold">
                    <PencilIcon fill="#252525" />
                    <span>Customize</span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>

        {isGuidePublished && (
          <div className="flex flex-col justify-end gap-6 w-[450px] max-lg:w-full">
            {output?.landingCaptureSiteUrl && (
              <div className="flex gap-3.5">
                <div className='min-w-[75px]'>
                  <ThumbnailPreview
                    imageAlt='Lead Capture Site'
                    imageSrc={lcsThumbnail ?? fallbackThumbnail}
                    linkToRedirect={output?.landingCaptureSiteUrl}
                  />
                </div>

                <div className="w-[450px] max-sm:w-full">
                  <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                    Lead Capture Site
                    <Tooltip
                      content="Generate more leads and grow your business. Receive instant notifications for every new lead."
                      trigger="hover"
                      style="dark"
                      arrow
                      placement="top"
                      theme={{
                        base:
                          'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                      }}
                    >
                      <QuestionCircleIcon />
                    </Tooltip>
                  </h5>

                  <Link
                    key="landingPage"
                    id="landingPage"
                    showPreview={false}
                    href={normalizeUrl(output?.landingCaptureSiteUrl)}
                    title={null}
                    showText
                  />
                </div>
              </div>
            )}

            {output?.flippingBookUrl && (
              <div className="flex gap-3.5">
                <div className='min-w-[75px]'>
                  <ThumbnailPreview
                    imageAlt='Digital Flip Book'
                    imageSrc={flipBookThumbnail ?? fallbackThumbnail}
                    linkToRedirect={output?.flippingBookUrl}
                  />
                </div>
                <div className="w-[450px] max-sm:w-full">
                  <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                    Digital Flip Book
                    <Tooltip
                      content="Share your guide digitally to showcase expertise and build trust with remote clients."
                      trigger="hover"
                      style="dark"
                      arrow
                      placement="top"
                      theme={{
                        base:
                          'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                      }}
                    >
                      <QuestionCircleIcon />
                    </Tooltip>
                  </h5>
                  <Link
                    key="landingPage"
                    id="landingPage"
                    showPreview={false}
                    href={normalizeUrl(output?.flippingBookUrl)}
                    title={null}
                    showText
                  />
                </div>
              </div>
            )}

            {output?.pdf?.infographic && (
              <div className="flex gap-3.5">
                <div className='min-w-[75px]'>
                  <ThumbnailPreview
                    imageAlt='Infographic'
                    imageSrc={infographicThumbnail ?? fallbackThumbnail}
                    linkToRedirect={output?.pdf?.infographic}
                  /></div>
                <div>
                  <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                    Infographic
                    <Tooltip
                      content="Download a printable version of your infographic"
                      trigger="hover"
                      style="dark"
                      arrow
                      placement="top"
                      theme={{
                        base:
                          'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                      }}
                    >
                      <QuestionCircleIcon />
                    </Tooltip>
                  </h5>
                  <Button
                    type="muted"
                    onClick={() => window.open(output?.pdf?.infographic, '_blank')}
                  >
                    <div className="flex items-center gap-2">
                      <DownloadIcon />
                      <span>Download</span>
                    </div>
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default compose(withGuideProfile, withProfile, withAddress)(GuidePreview);
