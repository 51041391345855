import { useContext } from 'react';
import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import {
  BookMinimalistic,
  DownloadIcon,
  PinMap,
  QuestionCircleIcon,
  BuyAgainIcon,
} from 'modules/v2/common/components/SvgIcon';
import { getDefaultImage, getTitleName } from 'modules/v2/pages/GuideCatalog/utils';

import OrderedDateTime from '../../../common/AtomicDesign/atoms/OrderedDateTime';
import { GuidesContext } from '../context';
import { Tooltip } from '../../../common/AtomicDesign/molecules';
import { normalizeUrl } from '../../../utils/stringManipulation';
import { Link } from '../../../draft/components';

const OrderedCard = ({ orderData, onBuyAgain, guides }) => {
  const { address: shippingAddress, thumbnail, infographic, guide, name } = orderData;
  const guideTitle = Object.keys(getTitleName).find((item) => name.includes(item));
  const guideName = getTitleName[guideTitle];
  let guideDetail;
  let lcsLink;
  let digitalLink;
  if (guide) {
    guideDetail = guides.find((item) => item._id === guide);
    lcsLink = guideDetail?.output?.landingCaptureSiteUrl;
    digitalLink = guideDetail?.output?.pdf?.spreadLayout;
  }

  const ADDRESS_MAX_LENGTH = 70;

  const { setGuideQuantity, setSelectedOrderContext } = useContext(GuidesContext);

  const handleNavigateToCheckout = () => {
    setSelectedOrderContext(orderData);
    setGuideQuantity(orderData.quantity);
    onBuyAgain(orderData, guideTitle);
  };

  const GuideThumbnail = ({ src, alt, className }) => (
    <div
      className={`h-42 md:h-64 overflow-hidden rounded-lg relative w-auto transition-transform duration-200 ${className}`}
    >
      <img
        src={src}
        alt={alt}
        className="h-full max-w-none object-cover rounded-lg transition-transform duration-200"
      />
    </div>
  );

  const QuantityTag = ({ children }) => (
    <div className="flex font-semibold items-center gap-2 rounded-md px-2 py-1 text-neutral-900 text-sm bg-neutral-100 w-fit">
      {children}
    </div>
  );

  function shippingAddressText() {
    const addressParts = [
      shippingAddress?.addressLine1,
      shippingAddress?.addressLine2,
      shippingAddress?.city,
      shippingAddress?.state,
      shippingAddress?.country,
      shippingAddress?.pincode,
    ];
    const address = addressParts.filter(Boolean).join(', ');
    return address.length > ADDRESS_MAX_LENGTH ? `${address.slice(0, ADDRESS_MAX_LENGTH)}...` : address;
  }

  return (
    <>
      <Box className="mb-6 relative">
        <div className="text-base gap-2 flex-row items-center px-6 py-[22px] text-boxTitle">
          <OrderedDateTime value={orderData?.createdAt} />
          {guide && (
            <span className="md:ml-0 sm:ml-0 flex-row text-base">
              <PinMap width={24} height={24} />
              <span className="font-semibold mr-1">Shipped to</span>
              <span>{shippingAddressText()}</span>
            </span>
          )}
        </div>

        <div className="grid lg:grid-cols-2 grid-cols-1 border-t-[1px] border-t-neutral-200 overflow-visible">
          <div className="p-6 flex flex-wrap gap-4 pr-0">
            <div className="w-fit">
              <GuideThumbnail
                src={thumbnail || getDefaultImage[guideTitle]}
                alt="data"
                className="group-hover:scale-105 max-h-[255px] max-w-[200px] border border-neutral-300"
              />
            </div>

            <div className="flex flex-col justify-between">
              <div className="flex flex-col mb-2 gap-2">
                <div className="flex flex-row items-center">
                  <span className="mr-2 font-semibold">{guideName}</span>
                  <div className="flex items-center gap-2 py-1 px-2 rounded-md border border-neutral-200 text-xs h-fit">
                    <BookMinimalistic />
                    <span>Print & Digital</span>
                  </div>
                </div>
                {guideTitle}
                <QuantityTag>Qty: {orderData?.quantity}</QuantityTag>
              </div>

              { guide && <Button
                type="primary"
                onClick={() => handleNavigateToCheckout()}
              >
                <BuyAgainIcon />
                <span className="ml-2">Buy again</span>
              </Button>
              }
            </div>
          </div>
          {guide && (
            <div className="flex flex-col p-6">
              <div className="w-full mb-6">
                <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                  Lead Capture Site
                  <Tooltip
                    content="Share your guide digitally to showcase expertise and build trust with remote clients."
                    trigger="hover"
                    style="dark"
                    arrow
                    placement="top"
                    theme={{
                      base:
                        'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                    }}
                  >
                    <QuestionCircleIcon />
                  </Tooltip>
                </h5>
                <Link
                  key="digitalGuide"
                  id="digitalGuide"
                  showPreview={false}
                  href={normalizeUrl(lcsLink)}
                />
              </div>
              <div className="w-full mb-6">
                <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                  Digital Flip Books
                  <Tooltip
                    content="Share your guide digitally to showcase expertise and build trust with remote clients."
                    trigger="hover"
                    style="dark"
                    arrow
                    placement="top"
                    theme={{
                      base:
                        'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                    }}
                  >
                    <QuestionCircleIcon />
                  </Tooltip>
                </h5>
                <Link
                  key="digitalGuide"
                  id="digitalGuide"
                  showPreview={false}
                  href={normalizeUrl(digitalLink)}
                />
              </div>
              <div className="w-[470px] max-sm:w-full">
                <h5 className="text-neutral-800 text-sm font-semibold flex gap-1 items-center">
                  Infographic
                  <Tooltip
                    content="Download a printable version of your infographic"
                    trigger="hover"
                    style="dark"
                    arrow
                    placement="top"
                    theme={{
                      base:
                        'text-neutral-50 w-64 text-sm p-2 px-3 rounded-lg font-normal font-figtree',
                    }}
                  >
                    <QuestionCircleIcon />
                  </Tooltip>
                </h5>
                <Button type="muted" onClick={() => window.open(infographic, '_blank')}>
                  <div className="flex items-center gap-2">
                    <DownloadIcon />
                    <span>Download</span>
                  </div>
                </Button>
              </div>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

export default OrderedCard;
