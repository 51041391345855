import { isEmpty } from 'lodash';

export const profilePlaceholder = 'https://afy-ui.s3.us-east-1.amazonaws.com/avatar.jpeg';

export const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3');
};

export const getName = (data: { firstName: string; lastName: string; name: string; }, firstName: string, lastName: string) => {
  if(data?.firstName && data?.lastName){
    return `${data?.firstName} ${data?.lastName}`;
  }
  if(data?.name){
    return `${data?.name}`;
  }
  return `${firstName} ${lastName}`;
};

export const getAddress = (data: { publicAddress: { addressLine1: string; addressLine2: string; city: string; country: string; pincode: string; state: string; }; }) => {
  if(isEmpty(data?.publicAddress)){
    return '';
  }
  const { addressLine1, addressLine2, city, country, pincode, state } = data.publicAddress;
  return `${addressLine1 ? `${addressLine1  } ` : ''}${addressLine2 ? `, ${addressLine2  } ` : ''}${city ? `, ${city  } ` : ''}${state ? `, ${state  } ` : ''}${pincode ? `- ${pincode  } , ` : ''}${country || ''}`.trim();

};
export const getFinalizedCheck = (
  data: {
    email: string, 
    phone: string, 
    firstName: string, 
    lastName: string, 
    headshotImage: string
  }, email: string, phone: string, profileImageUrl: { url: string }) => {
  if (
    (data?.email || email) && 
    (data?.phone || phone) && 
    data?.firstName && 
    data?.lastName && 
    (data?.headshotImage || profileImageUrl.url)
  ) {
    return true;
  }
  return false;
};