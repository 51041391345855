import React, { useLayoutEffect, useRef } from 'react';

import { debounce, includes, noop } from 'lodash-es';

import { CoverBackground, CoverElement } from './components';
import { compileTemplate } from './utils';
import S from './styles';

const onResize = (editor, onScaleChange) => {
  if (!editor) {
    return;
  }

  requestAnimationFrame(() => {
    const viewport = editor.parentElement;

    if (viewport && editor) {
      const scale = Math.min(
        viewport.clientWidth / editor.offsetWidth,
        viewport.clientHeight / editor.offsetHeight,
      );

      // eslint-disable-next-line no-param-reassign
      editor.style.transform = `scale(${scale})`;

      const { width, height } = editor.getBoundingClientRect();
      // eslint-disable-next-line no-param-reassign
      editor.style.top = `${(viewport.clientHeight - height) / 2 + 0}px`;
      // eslint-disable-next-line no-param-reassign
      editor.style.left = `${(viewport.clientWidth - width) / 2 + 0}px`;

      onScaleChange(scale);
    }
  });
};

const DEBOUNCE_RESIZE_EVENT = 300;

const CoverEditor = ({
  template,
  elements,
  scale,
  showGuides,
  loadingElements,
  onClick = noop,
  onScaleChange = noop
}) => {
  const ref = useRef();

  useLayoutEffect(() => {
    // Necessary because of invalidation of props
    const debouncedResize = debounce(
      () => onResize(ref.current, onScaleChange),
      DEBOUNCE_RESIZE_EVENT,
    );

    window.addEventListener('resize', debouncedResize);
    onResize(ref.current, onScaleChange);

    return () => {
      window.removeEventListener('resize', debouncedResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const compiled = compileTemplate(template, elements);

  return (
    <S.Wrapper>
      <S.Container ref={ref}>
        <CoverBackground template={compiled} />
        {elements &&
          elements.reduce((acc, element) => {
            if (!element) {
              return acc;
            }
            const isLoading = includes(loadingElements, element.id);

            acc.push(
              <CoverElement
                key={element.id}
                element={element}
                scale={scale}
                showGuides={showGuides}
                onClick={onClick}
                isLoading={isLoading}
              />,
            );
            return acc;
          }, [])}
      </S.Container>
    </S.Wrapper>
  );
};

export default React.memo(CoverEditor);
