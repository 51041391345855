import { isEmpty } from 'lodash';

export const profilePlaceholder = 'https://afy-ui.s3.us-east-1.amazonaws.com/avatar.jpeg';

export const formatPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1.$2.$3');
};

export const getTitleName = {
  Seller: 'A Guide to Selling Your Home + Infographics',
  Buyer: 'Finding Your Dream Home + Infographics',
};

export const getDefaultImage = {
  Seller: 'https://afy-ui.s3.us-east-1.amazonaws.com/seller+guide+blue+cover.jpg',
  Buyer: 'https://afy-ui.s3.us-east-1.amazonaws.com/buyer+guide+blue+cover.jpg',
  Moving: 'https://afy-ui.s3.us-east-1.amazonaws.com/RM+Automation+-+Moving+Guide+-+Single+Page.jpg',
  Pricing: 'https://afy-ui.s3.us-east-1.amazonaws.com/RM+Automation+-+Pricing+Guide+-+Single+Page.jpg'
};

export const formatProductCatalog = (guides, productCatalog) => {
  const result = productCatalog.map((item) => {
    const { id }  = item;
    const guideData = guides?.find(guide => {
      const isPublished = guide.contentId && guide.contentId === id.toString() && guide.status === 'PUBLISHED';
      if(isPublished){
        return guide;
      }
      return false;
    });
    if(isEmpty(guideData)){
      return { ...item, guideStatus: 'not published', orderStatus: 'new' };
    }
    return { ...item, guideStatus: 'published', orderStatus: 'new' , output: guideData?.output };
  });
  return result;
};

export const formatOrderedData = (guides, orderedData) => {
  const { name: guideName } = guides;
  const result = orderedData.find((item) => {
    if(item.name.includes(guideName)){
      const response = {
        ...item,
        orderedStatus: item.status,
      };
      return response;
    }
    return null;
  });
  return result;
};

export const formatInfographicsName = (guideName) => {
  return `${guideName} Infographics`;
};

export const formatGuideName = (guideName) => {
  return `${guideName} Guide`;
};