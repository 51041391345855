import { useHistory } from 'react-router-dom';
import { Modal, CustomFlowbiteTheme } from 'flowbite-react';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { CheckIconNew } from 'modules/v2/common/components/SvgIcon';
import { getRoutePlansAndBillings } from 'modules/v2/routes/navigation';
import { ConfirmationCheckIcon } from '../../../../../common/components';

interface ConfirmationModalProps {
  title: string;
  description?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  onConfirmation: () => void;
  confirmationText: string;
  buttonTheme?: 'light' | 'dark';
  children?: any,
  filledIcon?: boolean;
}

const defaultProps: Partial<ConfirmationModalProps> = {
  description: '',
};

const customTheme: CustomFlowbiteTheme['modal'] = {
  root: {
    show: {
      on: 'flex bg-gray-700 bg-opacity-50',
      off: 'hidden',
    },
  },
  content: {
    base: 'relative h-full w-fit w-[440px] md:h-auto max-[440px]:w-full',
  },
  footer: {
    base:
      'flex space-x-2 rounded-b border-neutral-200 min-h-[91px] px-6 py-[13px] dark:border-neutral-600',
  },
};

const ConfirmationModal = ({
  title,
  description,
  showModal,
  setShowModal,
  onConfirmation,
  confirmationText = 'Got it',
  children = null,
  buttonTheme = 'light',
  filledIcon = false,
}: ConfirmationModalProps) => {
  const history = useHistory();

  return (
    <Modal
      className="font-figtree"
      theme={customTheme}
      show={showModal}
      onClose={() => setShowModal(false)}
    >
      <Modal.Body>
        <div className="mx-auto w-[52px] h-[52px] flex items-center justify-center">
          <ConfirmationCheckIcon isFilled={filledIcon} />
        </div>
        <div className="text-center w-[513px] max-[440px]:w-full sm:w-full mt-6">
          <p className="text-base mb-3.5">{title}</p>
          {description && <p className="text-ms text-neutral-500">{description}</p>}
        </div>

        {Boolean(children) && children}

        <div className="flex justify-center mt-6">
          <Button onClick={onConfirmation} type={buttonTheme} className="w-[116px]">
            {confirmationText}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ConfirmationModal.defaultProps = defaultProps;

export default ConfirmationModal;
