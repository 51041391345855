const CrossIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Iconer">
        <path id="Vector" d="M15 5.00004L5 15M4.99996 5L14.9999 15" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
      </g>
    </svg>
  );
};

export default CrossIcon;
