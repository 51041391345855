import axios from 'axios';
import env from 'environments';
import jwt from 'jwt-decode';
import { get } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const LoggerInstance = axios.create({
  baseURL: env.LOGGER_URL,
});

const getUser = () => {
  const jwtToken = window.localStorage.getItem('token');
  const decoded = jwt(jwtToken) ?? null;

  if (!decoded) {
    return {
      name: 'Not informed',
      email: 'anon@authorify.com',
    };
  }

  return {
    id: <number>get(decoded, ['data', 'userId'], undefined),
    name: <string>get(decoded, ['data', 'firstname'], 'Not informed'),
    email: <string>get(decoded, ['data', 'email'], 'anon@authorify.com'),
    hasAccessToDMPOneDotFive: <boolean>get(decoded, ['data', 'hasAccessToDMPOneDotFive'], false),
    isAdmin: <boolean>get(decoded, ['data', 'isAdmin'], true),
  };
};

const log = async (eventName: string, eventNamespace: string, tags = []) => {
  try {
    await LoggerInstance.post('/loggers', {
      customer: getUser(),
      source: 'afy-ui',
      event: {
        name: eventName,
        namespace: eventNamespace,
      },
      trace: uuidv4(),
      tags: [`day:${weekday[new Date().getDay()]}`, ...tags],
    });
  } catch (err) {
    // do-nothing
  }
}


const logError = async (eventName: string, eventNamespace: string, email = '') => {
  try {
    await LoggerInstance.post('/loggers', {
      customer: {
        email,
        name: 'Not authenticated',
      },
      source: 'afy-ui',
      event: {
        name: eventName,
        namespace: eventNamespace,
      },
      trace: uuidv4(),
      tags: [`day:${weekday[new Date().getDay()]}`],
    });

  } catch (err) {
    // do-nothing
  }
}

const logDD = async (eventName: string, eventNamespace: string, tags?: string[]) => {
  try {
    await LoggerInstance.post('/loggers', {
      customer: getUser(),
      source: 'afy-ui',
      event: {
        name: eventName,
        namespace: eventNamespace,
      },
      trace: uuidv4(),
      tags: tags ?? [],
    });
  } catch (err) {
    // do-nothing
  }
};

export { log, logError, getUser, logDD };
