import env from 'environments';
import { getValueFromToken } from './getValueFromToken';

const guideFeatureIsEnabled = env.VITE_GUIDES_AUTOMATION;
const features = () => getValueFromToken('features');

export const hasGuideAccess = () => Array.isArray(features()) &&  features()?.includes('GUIDES:*') || guideFeatureIsEnabled;

export const isGuideOnlyCustomer = () => {
  return hasGuideAccess() && features().length === 1;
};
