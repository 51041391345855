import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { ArrowLeft } from 'modules/v2/common/components/SvgIcon/ArrowLeft/index';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { getOrderedHistoryRoute, getRouteGuideCatalog, getRouteHomePage } from 'modules/v2/routes/navigation';
import { compose } from 'redux';
import { Box, Button, Radio } from 'modules/v2/common/AtomicDesign/atoms';
import { Dropdown, Tooltip } from 'modules/v2/common/AtomicDesign/molecules';
import { CreditCardIcon } from 'modules/common/components/SvgIcon';
import ShippingIcon from 'modules/common/components/SvgIcon/ShippingIcon';
import { AddCircleIcon, DangerTriangleIcon, QuestionCircleIcon } from 'modules/v2/common/components/SvgIcon';
import { guideOneTimePayment } from 'modules/api/digitalServices/guides';
import { getOneTimePaymentOnly } from 'modules/api/digitalServices/product-catalog';
import { notification } from 'modules/common/utils';
import {
  ProcessingModal,
  AddCardModal,
  ChangeDefaultPaymentModal,
} from 'modules/v2/common/AtomicDesign/organisms';
import ConfirmationModal from 'modules/v2/common/AtomicDesign/organisms/ConfirmationModal';
import { AddressFormView } from 'modules/v2/common/views/AddressView/views';
import ShippingAddressSelectNew from 'modules/v2/draft/pages/Checkout/ShippingAddressSelectNew';
import ShippingNewAddress from 'modules/v2/draft/pages/Checkout/ShippingNewAddress';
import { withAddress, withProfile } from 'modules/v2/containers';
import {
  createGuide,
  fetchAllOrders,
  fetchUserAddresses,
  getDefaultPaymentProfile,
  getPaymentProfilesList,
} from 'modules/api';
import ObjectSet from 'modules/v2/common/utils/set';
import { STATUS_SUCCESS } from 'store/status';
import { CreditCardImages , isCanadaCountry } from 'modules/v2/utils';
import { withPublish } from 'modules/v2/draft/containers';
import { checkOrderedGuideName, formatGuideNameWithPlural, GuideCheckoutPlaceOrderButton, GuideCheckoutStatus } from 'modules/v2/utils/guideUtils';
import PaymentCardsSkeleton from 'modules/v2/common/AtomicDesign/atoms/PaymentCardsSkeleton';
import { withGuideProfile, withUserPreferences } from 'modules/dashboard/containers';
import { getGuideContent } from 'modules/api/strapi';
import { formatGuideName } from 'modules/v2/pages/GuideCatalog/utils';
import { htmlCreation, payloadCreation } from 'modules/v2/pages/GuideCatalog/utils/htmlCreation';
import {
  profilePlaceholder,
  formatPhoneNumber,
  getName,
} from 'modules/v2/utils/userPreferencesUtils';
import { Link } from 'modules/v2/draft/components';
import {
  useCheckForCustomerPaymentProfile,
  useGetPaymentProfiles,
} from 'modules/landingPages/hooks';

import { customLoadingModalTheme } from './theme';

const GuideCheckout = ({
  countries,
  states,
  addUserAddresses,
  order,
  clearPublish,
  checkoutStatus,
  orderId = '',
  guideProfile,
  getUserPreferences,
  userPreferences,
  profile,
  avatar,
}) => {
  const queryClient = useQueryClient();

  const hasCheckoutStatusFinalized = checkoutStatus === 'finalized';
  const { guidePreferences } = guideProfile;

  const { data: profileData } = profile;
  const { email, firstName, lastName, phone } = profileData;
  const profileImageUrl = avatar?.data || '';

  const [showShippingModal, setShowShippingModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [guideQuantity, setGuideQuantity] = useState(8);
  const [guideOrderId, setGuideOrderId] = useState('');
  const [strapiContentId, setStrapiContentId] = useState('');
  const [selectedQtyOption, setSelectedQtyOption] = useState({});
  const [productsOptions, setProductsOptions] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [availableShippingAddresses, setAvailableShippingAddresses] = useState([]);
  const history = useHistory();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [title, setTitle] = useState('');
  const [color, setColor] = useState('');
  const [guidename, setGuidename] = useState('');
  const [isReadyToCheckOrder, setIsReadyToCheckOrder] = useState(false);
  const [availablePaymentOptions, setAvailablePaymentOptions] = useState(new ObjectSet());
  const [defaultPaymentOption, setDefaultPaymentOption] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [defaultCardModal, setDefaultCardModal] = useState(false);
  const [userPreference, setUserPreference] = useState({
    companyLogo: '',
    companyName: '',
    email: '',
    name: '',
    profileImage: '',
    phoneNumber: '',
  });

  const [digitalGuideUrl, setDigitalGuideUrl] = useState('');

  let selectedGuideData;

  const { data: guideResponse, isLoading: isGuideLoading } = useQuery(
    'getGuideContent',
    getGuideContent,
  );

  const { data: getOneTimeProduct, isLoading: isPcLoading } = useQuery(
    ['getOneTimePaymentOnly', strapiContentId],
    () => getOneTimePaymentOnly(strapiContentId),
    {
      enabled: !!strapiContentId,
    }
  );

  const { data: orderList, isLoading: isOrderLoading } = useQuery('getOrders', () => fetchAllOrders({ customerEmail: email }));

  const {
    isLoading: loadingGetPaymentProfilesList,
    isRefetching: refetchingGetPaymentProfilesList,
  } = useQuery('getPaymentProfilesList', getPaymentProfilesList, {
    onSuccess: (data) => {
      const set = new ObjectSet();
      data.forEach((item) => set.add(item));
      setAvailablePaymentOptions(set);
    },
  });

  const { isLoading: defaultProfileLoading, isRefetching: defaultProfileRefetching } = useQuery(
    'getDefaultPaymentProfile',
    getDefaultPaymentProfile,
    {
      onSuccess: (data) => {
        setDefaultPaymentOption(data);
      },
    },
  );

  const mutation = useMutation({
    mutationFn: createGuide,
    onSuccess: (response) => {
      console.log('TODO: need to adjust this if with the right logic');
      if (true) {
        const {
          payload: {
            output: { pdf },
          },
        } = response;
        setDigitalGuideUrl(pdf?.fullContent);
      }

      queryClient.invalidateQueries({ queryKey: ['posts'] });
      const path = getRouteGuideCatalog();
      history.push(`${path}?orderPlaced`);
    },
    onError: (error) => {
      setShowLoadingModal(false);
      notification.error({ description: 'Something went wrong while processing guide' });
    },
  });

  const { mutate: createPaymentProfileMutation } = useCheckForCustomerPaymentProfile(profile?.data, {
    onError: () => {
      notification.error({
        title: 'Error',
        description: 'We could not create your payment profile.',
      });
    },
  });

  if (!isEmpty(guideResponse)) {
    const newGuidePreferences = guidePreferences?.find((item) => item.selectedStatus === true);
    const selectedGuide = guideResponse?.data?.find((item) => {
      return item?.attributes?.Name === newGuidePreferences?.name;
    });

    selectedGuideData = selectedGuide || guideResponse?.data?.[0];
  }

  useEffect(() => {
    if (!isOrderLoading && orderList?.data?.payload.length) {
      const payloadData = orderList.data.payload;
      const getIpOrders = payloadData.filter(item => item.status === GuideCheckoutStatus.IN_PROGRESS && checkOrderedGuideName(item.name, guidename));
      if(getIpOrders.length){
        setGuideOrderId(getIpOrders[0]?.productId);
        setGuideQuantity(getIpOrders[0]?.quantity);
      }
    }
  }, [isOrderLoading, orderList, guidename]);

  const mutationOneTimePayment = useMutation({
    mutationFn: guideOneTimePayment,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['guideOneTimePayment'] });
      const payload = payloadCreation(
        selectedGuideData,
        userPreference,
        color,
        email,
        selectedAddress,
        { paidBefore: false, productId: selectedQtyOption.id }
      );
      mutation.mutate(payload);
    },
    onError: (error) => {
      setShowLoadingModal(false);
      notification.error({ description: 'Something went wrong while performing payment' });
    },
  });

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    if (!isPcLoading && getOneTimeProduct?.data) {
      const productResponse = getOneTimeProduct?.data.map((el) => {
        return {
          id: el._id,
          label: el.title,
          qty: el.title.split(' ')[0],
          price: el.price,
        };
      });
      setProductsOptions(productResponse);
      setSelectedQtyOption(productResponse[0]);
    }
  }, [getOneTimeProduct, isPcLoading]);

  useEffect(() => {
    if (guidePreferences.length) {
      const newGuidePreferences = guidePreferences.find((item) => item.selectedStatus === true);
      setTitle(newGuidePreferences.title);
      setColor(newGuidePreferences.selectedColor);
      setGuidename(newGuidePreferences.name);
      setStrapiContentId(newGuidePreferences.id);
    }
  }, [guidePreferences]);

  const addressFormRef = useRef();

  const {
    refetch: fetchUserAddressesRefetch,
    isRefetching: isRefetchingUserAddress,
    isLoading: isLoadingUserAddress,
  } = useQuery('fetchUserAddresses', fetchUserAddresses, {
    onSuccess: (res) => {
      const {
        data: { data },
      } = res;

      const { shippingAddress } = data;
      setAvailableShippingAddresses(shippingAddress);

      const defaultSelectedAddress = shippingAddress.find((address) => address.isDefault === true);
      if (defaultSelectedAddress) {
        setSelectedAddress(defaultSelectedAddress);
      } else if (shippingAddress.length === 1) {
        setSelectedAddress(shippingAddress[0]);
      } else {
        setSelectedAddress(null);
      }
    },
    onError: (error) => {
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Network Error',
        autoClose: false,
      });
    },
  });

  useEffect(() => {
    createPaymentProfileMutation();
    getUserPreferences();
  }, []);

  useEffect(() => {
    if (userPreferences?.status === 'STATUS_SUCCESS' && userPreferences?.data?.data) {
      const { data: dataDefault } = userPreferences?.data?.data;
      const data = dataDefault?.data?.guides || dataDefault;

      const phoneDetails = data?.phone ? formatPhoneNumber(data.phone) : '';
      const updatePreferences = {
        companyLogo: data?.brokerLogo?.value || '',
        companyName: data?.companyName || '',
        email: data?.email || email,
        name: getName(data, firstName, lastName),
        profileImage: data?.headshotImage || profileImageUrl.url || profilePlaceholder,
        phoneNumber: phoneDetails || (phone && formatPhoneNumber(phone)) || '',
        showBrokerInfo: data?.brokerLogo?.status || false,
      };
      setUserPreference(updatePreferences);
    }
  }, [email, firstName, lastName, phone, userPreferences, profileImageUrl.url]);

  const handleAddressFormAndSubmit = async () => {
    setShowLoadingModal(true);
    if (hasCheckoutStatusFinalized) {
      const payloadGuide = payloadCreation(
        selectedGuideData,
        userPreference,
        color,
        email,
        selectedAddress,
        { paidBefore: true, productId: guideOrderId }
      );
      mutation.mutate(payloadGuide);
    } else {
      const payload = {
        customerEmail: email,
        paymentProfileId: selectedPayment.id,
        productId: selectedQtyOption.id,
        productName: selectedGuideData.attributes.Name,
      };
      mutationOneTimePayment.mutate(payload);
    }
  };

  const handleAddShippingAddress = async (fields) => {
    await addUserAddresses({ ...fields, isDefault: true });
    await fetchUserAddressesRefetch();
    setIsReadyToCheckOrder(true);
  };

  function styleMask(item) {
    return (
      item?.masked_card_number.slice(0, -4).replace(/./g, '*') + item?.masked_card_number.slice(-4)
    );
  }

  const isDefaultPayment = (itemId) => {
    return itemId === defaultPaymentOption?.id;
  };

  const handleShowShippingModal = () => {
    setShowShippingModal(true);
  };

  const handleCheckoutSubmit = async () => {
    try {
      await handleAddressFormAndSubmit();
    } catch (error) {
      notification.error({
        description: error?.response?.data?.errors?.[0] || 'Network Error',
        autoClose: false,
      });
    }
  };

  const handleConfirmation = () => {
    history.push(`${getOrderedHistoryRoute()}?orderid=00001111`);
  };

  const handleAddNewPaymentMethod = () => {
    setShowAddCardModal(true);
  };

  const finalHTML = htmlCreation(selectedGuideData, userPreference, color);

  const renderInLineAddShippingAddress = () => {
    if (isLoadingUserAddress || isRefetchingUserAddress) {
      return <PaymentCardsSkeleton />;
    }
    return availableShippingAddresses.length === 0 ? (
      <AddressFormView
        countries={countries.data}
        states={states.data}
        onSubmit={handleAddShippingAddress}
        hasLeadingMessage={false}
        hasSubmitButton={false}
        hasSelectedToggle={false}
        addressFormRef={addressFormRef}
      />
    ) : (
      <Button onClick={handleShowShippingModal} type="bare">
        <AddCircleIcon className="mr-2" /> Add new address
      </Button>
    );
  };

  const isDigitalOnly = () => true;

  const ConfirmationModalContent = () =>
    isDigitalOnly() ? (
      <div className="my-6">
        <Link
          id="landingPage"
          showPreview={false}
          href={digitalGuideUrl || 'The generated guide url'}
          title={null}
        />
      </div>
    ) : null;

  useEffect(() => {
    if (isReadyToCheckOrder) {
      setIsReadyToCheckOrder(false);
    }
  }, [isReadyToCheckOrder]);

  useEffect(() => {
    const currentUrl = window.location.href;

    if (order.status === STATUS_SUCCESS && currentUrl.includes('checkout')) {
      setShowConfirmationModal(true);
      setShowLoadingModal(false);
      clearPublish();
    }
  }, [clearPublish, order]);

  useEffect(() => {
    if (!isEmpty(defaultPaymentOption)) {
      const currentSet = availablePaymentOptions;
      currentSet.add(defaultPaymentOption);
      setAvailablePaymentOptions(currentSet);
      setSelectedPayment(defaultPaymentOption);
    } else{
      const currentArray = Array.from(availablePaymentOptions.toArray()).filter((_, index) => index === 0);
      setSelectedPayment(currentArray[0]);
    }
  }, [availablePaymentOptions, defaultPaymentOption]);

  const hasSelectedPaymentMethod = () => selectedPayment && !selectedPayment?.disabled;
  const hasMissingPaymentMethod = () => Boolean(!hasSelectedPaymentMethod() && !hasCheckoutStatusFinalized);

  const mustDisableButton = isCanadaCountry(selectedAddress?.country) || hasMissingPaymentMethod();

  const mustDisplayError = () => {
    if (isEmpty(selectedPayment)) return false;

    return mustDisableButton;
  }

  const alertText = () => {
    if (hasMissingPaymentMethod()) return 'You need to select a payment method to place the order.';

    return 'Sorry, at this point we do not ship printed guides to Canada.';
  };

  return (
    <>
      <div className="w-1/12 flex gap-1 items-center cursor-pointer" onClick={goBack}>
        <ArrowLeft stroke="#3C3C3C" />
        <span className="font-medium text-sm">Back</span>
      </div>

      <Box className="mt-6">
        <div className="flex justify-between items-center border-b-[1px] p-7">
          <span className="text-lg font-semibold">Review</span>
        </div>

        <div className="p-7 flex max-[1365px]:flex-col gap-[24px]">
          <div className="w-1/2 max-[1365px]:w-full">
            {!hasCheckoutStatusFinalized && (
              <div className="justify-start max-[1250px]:flex-col">
                <div className="w-[90%] min-w-80">
                  <div className="flex items-center gap-2 mb-4 text-neutral-900">
                    <CreditCardIcon /> Choose payment method
                  </div>

                  <div className="max-xl:overflow-x-auto relative">
                    <div className="mb-6">
                      {loadingGetPaymentProfilesList ||
                      defaultProfileLoading ||
                      defaultProfileRefetching ||
                      refetchingGetPaymentProfilesList ? (
                          <PaymentCardsSkeleton />
                        ) : (
                          Array.from(availablePaymentOptions.toArray()).map((item, index) => {
                            const selected =
                            'border-primary-500 bg-addressSelect border-0.5 rounded-lg flex gap-4 items-center p-4 mb-2 hover:bg-primary-50 transition duration-300 ease-in-out cursor-pointer';
                            const defaultStyle =
                            'border-neutral-200 gap-4 items-center flex border-[2px] rounded-lg p-4 mb-2 hover:bg-neutral-100 transition duration-300 ease-in-out cursor-pointer';
                            return (
                              <label
                                key={item.id}
                                className={selectedPayment?.id === item.id ? selected : defaultStyle}
                                onClick={() => setSelectedPayment(item)}
                                htmlFor={item.id}
                              >
                                <Radio
                                  id={item.id}
                                  name="cards"
                                  value={item.id}
                                  onChange={() => setSelectedPayment(item)}
                                  className={classNames(
                                    selectedPayment?.id === item.id && 'border-4 border-primary-500',
                                  )}
                                />
                                <img
                                  src={CreditCardImages[item?.card_type]}
                                  alt={item?.card_type}
                                  className="w-[46px] h-[32px] rounded-sm"
                                />
                                <span className="text-sm font-semibold">
                                Card {styleMask(item)} {isDefaultPayment(item.id) && '(Default)'}
                                </span>
                              </label>
                            );
                          })
                        )}

                      <Button
                        wrapperClassName="flex items-center"
                        onClick={handleAddNewPaymentMethod}
                        type="bare"
                      >
                        <AddCircleIcon className="mr-2" /> Add new payment method
                      </Button>
                    </div>
                  </div>
                  <ChangeDefaultPaymentModal
                    showModal={defaultCardModal}
                    setShowModal={setDefaultCardModal}
                    isDefault
                  />
                </div>
              </div>
            )}

            <div id="shipping-address-" className="justify-items-start w-full">
              <div className="flex gap-2 items-start mb-4">
                <ShippingIcon /> <span className="text-sm font-semibold">Ship to</span>
              </div>
              <ShippingAddressSelectNew
                selectedAddress={selectedAddress}
                setSelectedAddress={setSelectedAddress}
                shippingAddressList={availableShippingAddresses}
                isRefeching={isRefetchingUserAddress}
                additionalShippingLoading={isRefetchingUserAddress || isLoadingUserAddress}
              />
              <span className="flex items-center">{renderInLineAddShippingAddress()}</span>
            </div>
          </div>
          <div className="w-1/2 max-[1365px]:w-full">
            <div id="summary" className="justify-items-start bg-neutral-50 rounded-lg p-6">
              <span className="text-2xl font-bold">Summary</span>

              <div className="mt-7 gap-[14px] flex max-[510px]:flex-col max-[510px]:items-center">
                <div className="flex gap-2 items-center mb-2">
                  <div className="bg-grey-100 relative w-[88px] h-[113px] rounded-lg border neutral-300 overflow-hidden cursor-pointer transition-transform duration-300 ease-in-out">
                    <div
                      className="absolute top-0 left-0 w-full h-full origin-top-left scale-11"
                      dangerouslySetInnerHTML={{ __html: finalHTML }}
                    />
                  </div>
                </div>

                <div className="relative">
                  <div className="flex gap-2 items-center mb-2">
                    <span className="text-base font-semibold">{title}</span>
                  </div>
                  {hasCheckoutStatusFinalized ? (
                    <div className="text-neutral-400 text-sm font-medium">
                      {guideQuantity} Printed Copies
                    </div>
                  ) : (
                    <Dropdown
                      items={productsOptions}
                      setSelectedQtyOption={setSelectedQtyOption}
                      setGuideQuantity={setGuideQuantity}
                      selectedQtyOption={selectedQtyOption}
                    />
                  )}
                </div>
              </div>
              <div className="mt-7 mb-7 flex w-full">
                <div className="justify-start w-1/2">
                  <span className="text-sm text-neutral-400 font-medium">Shipping</span>
                </div>

                <div className="flex flex-end ml-auto justify-end">
                  <span className="text-sm text-neutral-400 font-medium">free</span>
                </div>
              </div>

              {!hasCheckoutStatusFinalized && (
                <hr className="w-full h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-5 dark:bg-gray-700" />
              )}

              {!hasCheckoutStatusFinalized && (
                <div className="mt-7 mb-7 flex w-full">
                  <div className="justify-start w-1/2">
                    <span className="text-base text-neutral-800 font-semibold">
                      Total due today
                    </span>
                  </div>

                  <div className="flex flex-end ml-auto justify-end">
                    <span className="text-base text-neutral-800 font-semibold">
                      {selectedQtyOption?.price}
                    </span>
                  </div>
                </div>
              )}

              <div className="mt-7 flex w-full">
                <Button
                  onClick={handleCheckoutSubmit}
                  disabled={isRefetchingUserAddress || mustDisableButton}
                  full
                  wrapperClassName="w-full"
                >
                  <span className="font-bold text-base">
                    {hasCheckoutStatusFinalized
                      ? GuideCheckoutPlaceOrderButton.SHIPNOW
                      : GuideCheckoutPlaceOrderButton.PLACEORDER}
                  </span>
                </Button>
              </div>

              {mustDisplayError() && <div className="mt-4 flex w-full">
                <div className='flex items-center gap-3 py-2.5 px-3 bg-danger-100 rounded-lg w-full'>
                  <DangerTriangleIcon fill="#E4483D" />
                  <div className='text-base text-danger-500 font-semibold w-full'>{alertText()}</div>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </Box>

      <ShippingNewAddress
        openAddressForm={showShippingModal}
        setOpenAddressForm={setShowShippingModal}
        fetchUserAddressesRefetch={fetchUserAddressesRefetch}
      />

      {showAddCardModal && (
        <AddCardModal showModal={showAddCardModal} setShowModal={setShowAddCardModal} />
      )}

      <ProcessingModal
        title="Processing..."
        description="Please don’t close this window"
        showModal={showLoadingModal}
        setShowModal={setShowLoadingModal}
        theme={customLoadingModalTheme}
      />

      <ConfirmationModal
        title={
          isDigitalOnly() ? 'Share Your Digital Guide!' : 'Thank you. Your order is confirmed!'
        }
        description={
          isDigitalOnly()
            ? 'Thank you for your order. Share your guide digitally to showcase expertise and build trust with remote clients.'
            : 'Please allow 7-10 days for us to print and ship your order.'
        }
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        onConfirmation={handleConfirmation}
        confirmationText={isDigitalOnly() ? 'Okay' : 'View order'}
        children={ConfirmationModalContent()}
        filledIcon={isDigitalOnly()}
      />
    </>
  );
};

export default compose(
  withAddress,
  withPublish,
  withGuideProfile,
  withUserPreferences,
  withProfile,
)(GuideCheckout);
