import env from 'environments';
import { BoltIcon, TickMark } from 'modules/v2/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { cardMagazineImageUrl } from 'modules/v2/utils/hasOnlyRMAccess';

function ModalSubscribeMagazine({ setOpenModal }) {

  const handleClick = () => {
    setOpenModal(false);
  };

  return (
    <div className='flex items-start self-stretch bg-white rounded-lg gap-1 w-full'>
      <div className='p-5 w-[50%]'>
        <div className='flex text-sm font-semibold py-2'>
          <span className='pr-2'><BoltIcon fill='#FFC229' /></span>
          Upgrade now to use this feature
        </div>
        <div className='text-2xl font-bold py-2'>
          Generate More Referrals With Your Own Personalized Magazine
        </div>
        <div className='py-2 text-base font-medium'>
          You can sell 9x more homes when you focus on repeat & referral business, instead of cold calling or open houses. Your Referral Marketing Membership includes: 
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-base'>
            <span className='font-bold'>Customize your Magazine.</span> We'll customize the magazine with your name, picture, and contact information.
          </div>
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-base'>
            <span className='font-bold'>Lead Capture Site.</span> Generate new subscribers. Send your magazine to them, and generate more referrals
          </div>
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-base'>
            <span className='font-bold'>Digital Magazine.</span> Easily share your digital magazine’s latest issue with your leads via email or social media.
          </div>
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-base'>
            <span className='font-bold'>Weekly Email Campaigns.</span> These emails will promote your digital magazine and generate more referrals
          </div>
        </div>
        <div className='gap-2 mt-4 mb-2 w-full'>
          <Button type='primary' full className="py-2" onClick={handleClick}>Need Magazines? <br /> Please call us at 904-695-993</Button>
        </div>
        <div className='gap-2 mt-4 mb-2 w-full'>
          <Button type='outlined' full wrapperClassName="w-full" onClick={handleClick} >Maybe Later</Button>
        </div>
      </div>
      <div className='justify-center items-center gap-2.5 self-stretch w-[50%]'>
        <img className='object-cover rounded-r-lg h-full w-full' src={cardMagazineImageUrl} alt='magazine-asset'/>
      </div>
    </div>
  );
}

export default ModalSubscribeMagazine;