import { useEffect, useState } from 'react';
import { compose } from 'redux';

import { isReady } from 'store/status';
import { Loader, UserAvatar } from 'modules/v2/common/components';

import { ProfileAvatar } from 'modules/v2/common/components/SvgIcon';
import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import { EditProfileForm, FileUploadModal } from 'modules/v2/components';
import { ProfileTemplate } from 'modules/v2/templates';

import { withUserPreferences, } from 'modules/dashboard/containers';
import { withProfile } from 'modules/v2/containers';
import { withAuth } from 'modules/auth/containers';

const Profile = ({
  avatar,
  uploadAvatar,
  profile,
  updateProfile,
  getUserPreferences,
  userPreferences,
  updateUserPreferences,
  signOut
}) => {
  const [loadingText, setLoadingText] = useState('Loading Profile');
  const [userPreference, setUserPreference] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    headshotImage: '',

  });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getUserPreferences();
  }, []);

  const {
    data: { email, firstName, lastName, phone },
  } = profile;

  useEffect(() => {
    if (userPreferences?.status === 'STATUS_SUCCESS' && userPreferences?.data?.data) {
      const { data } = userPreferences.data.data;
      setUserPreference(data);
    }
  }, [userPreferences, profile]);

  const fields = {
    email,
    firstName,
    lastName,
    phone: phone ? phone : userPreference?.phone,
  };

  const hasAvatar = !!avatar?.data?.url || !!userPreference?.headshotImage;

  const avatarUrl = avatar?.data?.url || userPreference?.headshotImage;

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleConfirm = (newFile) => {
    setIsOpen(false);

    uploadAvatar(newFile);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleProfileUpdate = (formFields) => {
    setLoadingText('Saving profile');

    updateProfile(formFields);
    const updatedPreferences = { ...userPreferences };
      let allFieldsExist = true;
  
      Object.entries(formFields).forEach(([key, value]) => {
        if (value && userPreferences[key] === undefined) {
          updatedPreferences[key] = value;
          allFieldsExist = false;
        }
      });

      if (!allFieldsExist) {
        updateUserPreferences(updatedPreferences);
      }
  };

  let content = <Loader title={loadingText} height="100%" />;

  if (isReady(profile.status) && isReady(userPreferences?.status)) {
    content = (
      <div>
        {hasAvatar ? (
          <div className="h-[120px] max-w-[492px] mt-3.5 mb-6 flex items-center gap-6">
            <div className="w-[120px]">
              <UserAvatar avatar={avatarUrl} bordered defaultAvatar={<ProfileAvatar />} size="120px" />
            </div>
            <Button type="outlined" onClick={handleOpen}>
              Change
            </Button>
          </div>
        ) : (
          <Button type="outlined" onClick={handleOpen} className="max-w-[492px] mt-3.5 mb-6" full>
            Upload photo
          </Button>
        )}
        <EditProfileForm fields={fields} onSave={handleProfileUpdate} />
        {isOpen && (
          <FileUploadModal
            isOpen={isOpen}
            title="Upload Avatar"
            type="profile"
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        )}
      </div>
    );
  }

  return (
    <ProfileTemplate
      avatar={avatar}
      email={fields.email}
      title="Account Settings"
      username={fields.firstName}
      onLogout={signOut}
      sidebarWidth={0}
      noPaddingLeft
      noPaddingRight
    >
      <Box>
        <div className="text-base font-semibold px-6 py-[22px] text-boxTitle border-b border-neutral-200">
          Personal information
        </div>

        <div className="p-6">
          <div>
            <div>
              <div className="flex flex-col items-start">
                <div className="text-neutral-800 text-sm font-semibold">Your photo</div>
                <div className="text-neutral-500 text-sm font-medium">
                  Recommended size: 520 x 520
                </div>
              </div>
            </div>
          </div>
          {content}
        </div>
      </Box>
    </ProfileTemplate>
  );
};

export default compose(
  withProfile,
  withAuth,
  withUserPreferences,
)(Profile);
