import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { LightningBoltIcon } from 'modules/v2/common/components/SvgIcon';
import { neutral300 } from 'modules/v2/common/theme/color';
import { isGuideOnlyCustomer } from 'modules/v2/utils';

type MenuItemProps = {
  href: string;
  current: boolean;
  name: string;
  Icon: React.FC<{ active: boolean }>;
  isChild?: boolean;
  welcomeJourneyEligible?: boolean;
  ActionIcon?: any;
};

const MenuItem = ({
  href,
  current,
  name,
  Icon,
  isChild = false,
  welcomeJourneyEligible = false,
  ActionIcon,
}: MenuItemProps) => {
  const isGuideOnly = isGuideOnlyCustomer();
  const [isFirstAccessGuideOnly, setIsFirstAccessGuideOnly] = useState(
    localStorage.getItem('isWJGuidesCompleted') === 'false'
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setIsFirstAccessGuideOnly(localStorage.getItem('isWJGuidesCompleted') === 'false');
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const hasHttps = href.includes('http' || 'https');

  const enabledName = isFirstAccessGuideOnly ? 'Dashboard' : 'Welcome journey';

  const isDisabled = welcomeJourneyEligible || (isGuideOnly && isFirstAccessGuideOnly)
    ? name !== enabledName
    : false;

  if (hasHttps) {
    return (
      <a
        href={href}
        target="blank"
        className={classNames(
          'm-0 w-full h-[39px] flex relative items-center',
          isChild ? 'pr-1' : 'pr-3',
          current
            ? `
            after:w-2 w-2
            after:content-[' ']
            after:absolute
            after:right-[-4px]
            after:top-1
            after:h-8
            after:bg-blueBorder
            after:transition-all
            after:duration-200
            after:rounded-full
            h-[39px]
            hover:after:w-2
          `
            : '',
        )}
      >
        <div
          className={classNames(
            current
              ? 'bg-gray-100 text-neutral-700 after:w-2'
              : 'hover:bg-gray-100 text-neutral-600 after:w-0',
            'w-full flex gap-[10px] items-center px-2 py-[9.5px] rounded-lg',
            isChild ? 'mr-2' : '',
            !Icon ? 'pl-10' : '',
          )}
        >
          {Icon && <Icon active={current} />}
          <span>{name}</span>
        </div>
      </a>
    );
  }

  if (isDisabled) {
    return (
      <span
        className={classNames(
          `
          m-0
          w-full
          h-[39px]
          flex
          relative
          items-center
          cursor-not-allowed
          opacity-50
          pr-3
        `,
        )}
      >
        <div className="w-full mr-2 flex gap-[10px] items-center px-2 py-[9.5px] rounded-lg">
          {Icon && <Icon active={current} />}
          <span>{name}</span>
        </div>
      </span>
    );
  }

  return (
    <Link
      to={href}
      className={classNames(
        'm-0 w-full h-[39px] flex relative items-center',
        isChild ? 'pr-1' : 'pr-3',
        current
          ? `
          after:w-2 w-2
          after:content-[' ']
          after:absolute
          after:right-[-4px]
          after:top-1
          after:h-8
          after:bg-blueBorder
          after:transition-all
          after:duration-200
          after:rounded-full
          hover:after:w-2
        `
          : '',
      )}
    >
      <div
        className={classNames(
          current
            ? 'bg-neutral-50 text-neutral-700 after:w-2'
            : 'hover:bg-neutral-50 text-neutral-600 after:w-0',
          'w-full flex items-center p-2 rounded-lg justify-between',
          isChild ? 'mr-2' : '',
          !Icon ? 'pl-10' : '',
        )}
      >
        <div className={classNames(current ? 'mr-[-8px]' : '', 'flex justify-between w-full')}>
          <div className="flex gap-[10px]">
            {Icon && <Icon active={current} />}
            <span>{name}</span>
          </div>
          {ActionIcon}
        </div>
      </div>
    </Link>
  );
};

export default MenuItem;
