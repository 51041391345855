import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import isEmpty from 'lodash-es/isEmpty';
import { compose } from 'redux';
import { useParams } from 'react-router-dom';

import { ArrowLeft } from 'modules/v2/common/components/SvgIcon/ArrowLeft/index';
import { CheckIconGuides, PaintPaletteIcon, CloseCircleIcon, InfographicIconFilled, GuideIconFilled, PiIcon } from 'modules/v2/common/components/SvgIcon';
import { InfoIcon } from 'modules/v2/common/components/SvgIcons';
import { getMyCheckout, getRouteGuideCatalog, getRouteHomePage } from 'modules/v2/routes/navigation';
import { cn } from 'modules/v2/common/utils';
import { isGuideOnlyCustomer } from 'modules/v2/utils';
import { publishGuide, fetchAllOrders, fetchGuidesListByEmail } from 'modules/api/authorify/guides';
import { PersonalInformationForm, ProcessingModal } from 'modules/v2/common/AtomicDesign/organisms';
import { getGuideContent, getGuideInfographics } from 'modules/api/strapi';
import { guideColor, colorPick as colors, getEditButtonLabel, GuideCheckoutStatus, checkOrderedGuideName, showColorInGuides } from 'modules/v2/utils/guideUtils';
import { strapiHtmlCreation, payloadCreation, strapiHtmlInfographicCreation, strapiPreloadHtmlImages } from 'modules/v2/pages/GuideCatalog/utils/htmlCreation';
import NavigationArrow from 'modules/v2/common/AtomicDesign/atoms/NavigationArrow';
import { withAuth } from 'modules/auth/containers';
import { withAddress, withGuideProfile, withUserPreferences } from 'modules/dashboard/containers';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { withProfile } from 'modules/v2/containers';
import { profilePlaceholder, formatPhoneNumber, getName, getFinalizedCheck } from 'modules/v2/utils/userPreferencesUtils';
import { formatProductCatalog, formatInfographicsName, formatGuideName } from 'modules/v2/pages/GuideCatalog/utils';
import { Tooltip } from 'modules/v2/common/AtomicDesign/molecules';

const EditGuide = ({
  addresses,
  avatar,
  getUserAddresses,
  getUserPreferences,
  guideProfile,
  history,
  isCanadianAddress,
  userPreferences,
  profile,
  updateGuideProfile,
}) => {
  const isOnlyGuideCustomer = isGuideOnlyCustomer();
  const queryClient = useQueryClient();
  const { guideName : guideId } = useParams();
  const guideName = Number(guideId);

  const { data: profileData } = profile;
  const { email, firstName, lastName, phone } = profileData;
  const profileImageUrl = avatar?.data || '';

  const { guidePreferences } = guideProfile;

  const [isInfographicView, setIsInfographicView] = useState<boolean>(false);
  const [guideInfogra, setGuideInfogra] = useState({});
  const [selectedColor, setSelectedColor] = useState<string>('blue');
  const [hideOptions, setHideOptions] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isColorShow, setIsColorShow] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('personal');
  const [selectedPreference, setSelectedPreference] = useState(null);
  const [productCatalog, setProductCatalog] = useState([]);
  const [previewGuide, setPreviewGuide] = useState({});
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [userPreference, setUserPreference] = useState({
    companyLogo: '',
    companyName: '',
    email: '',
    name: '',
    profileImage: '',
    phoneNumber: '',
    isFinalized: Boolean,
  });
  const [productCatalogData, setProductCatalogData] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState(false);
  const [disablePrintOption, setDisablePrintOption] = useState(false);

  const { data: guideResponse, isLoading: isGuideLoading } = useQuery('getGuideContent', getGuideContent);

  const mutation = useMutation({
    mutationFn: publishGuide,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['posts'] });
      setIsOpen(false);
      const path = getRouteGuideCatalog();
      history.push(`${path}?link=${response?.payload?.output?.pdf?.fullContent}`);
    },
    onError: (error) => {
      console.error('Error:', error);
    }
  });

  const { data: orderList, isLoading: isOrderLoading } = useQuery('getOrders', () => fetchAllOrders({ customerEmail: email }));

  const {
    data: guideList,
    isLoading: isGuideListLoading,
  } = useQuery(
    ['getGuidesList', email],
    () => fetchGuidesListByEmail(email),
  );

  useEffect(() => {
    setIsColorShow(showColorInGuides.includes(guideName));
  },[guideName]);

  useEffect(() => {
    if (!isGuideLoading) {
      const payloadData = guideResponse.data;
      const newPayloadData = payloadData.map(item => {
        const showColor = showColorInGuides.includes(item?.id);
        const showInfographics = !isEmpty(item?.attributes?.infographics);
        return {
          id: item?.id,
          name: item?.attributes?.Name,
          title: item?.attributes?.frontCover?.title,
          showColor,
          showInfographics,
        };
      });
      setProductCatalog(newPayloadData);
      const guideSelectedData = newPayloadData.find(item => item.id === guideName);
      setPreviewGuide(guideSelectedData);
      const guideInfoGraphicData = guideResponse.data.find(item => item.id === guideName);
      setGuideInfogra(guideInfoGraphicData?.attributes?.infographics);
    }
  }, [guideName, guideResponse, isGuideLoading]);

  useEffect(() => {
    if (!isOrderLoading && orderList?.data?.payload.length) {
      const payloadData = orderList.data.payload;
      const getIpOrders = payloadData.filter(item => item.status === GuideCheckoutStatus.IN_PROGRESS && checkOrderedGuideName(item.name, guideName));
      if(getIpOrders.length){
        setInProgressOrders(true);
      }
    }
  }, [guideName, isOrderLoading, orderList]);

  useEffect(() => {
    if (!isGuideListLoading && guideList?.data) {
      const payloadData = guideList?.data?.filter(Boolean)[0];
      const formattedCatalog = formatProductCatalog(payloadData, productCatalog);
      setProductCatalogData(formattedCatalog);
    }
  }, [guideList, isGuideListLoading, productCatalog]);

  useEffect(() => {
    if (guidePreferences.length === 0) {
      const newGuidePreferences = productCatalogData.map(item => ({ ...item, selectedColor: 'blue', selectedStatus: item.id === guideName }));
      updateGuideProfile({ guidePreferences: newGuidePreferences });
    }
  }, [guideName, guidePreferences, productCatalogData, updateGuideProfile]);

  useEffect(() => {
    getUserPreferences();
  }, [getUserPreferences]);

  useEffect(() => {
    if (userPreferences?.status === 'STATUS_SUCCESS' && userPreferences?.data?.data) {
      const { data: dataDefault } = userPreferences?.data?.data;
      const data = dataDefault?.guides || dataDefault;
      
      const phoneDetails = data?.phone ? formatPhoneNumber(data.phone) : '';
      const updatePreferences = {
        companyLogo: data?.brokerLogo?.value || '',
        companyName: data?.companyName || '',
        email: data?.email || email,
        name: getName(data, firstName, lastName),
        profileImage: data?.headshotImage || profileImageUrl.url || profilePlaceholder,
        phoneNumber: phoneDetails || (phone && formatPhoneNumber(phone)) || '',
        isFinalized: getFinalizedCheck(data, email, phone, profileImageUrl),
        showBrokerInfo: data?.brokerLogo?.status || false,
      };
      setUserPreference(updatePreferences);
    }
  }, [email, firstName, lastName, phone, userPreferences, profileImageUrl]);

  useEffect(() => {
    if (guidePreferences.length === 0) {
      return;
    }

    const selected = guidePreferences.find((item) => item.id === guideName);
    setSelectedPreference(selected);
    setSelectedColor(selected.selectedColor);
  }, [guideName, guidePreferences]);

  let guideDetails;
  let selectedGuideData;
  if (!isEmpty(guideResponse)) {
    const guideResponseData = guideResponse.data.find(item => item.id === guideName);
    selectedGuideData = guideResponseData;
    guideDetails = guideResponseData?.attributes;
  }


  const handleViewSwitch = (view: 'guide' | 'infographic') => {
    setIsInfographicView(view === 'infographic');
  };

  const defaultAddress = addresses.data.find((address) => address.isDefault === true);

  const handlePublish = () => {
    if (inProgressOrders) {
      history.push(getMyCheckout('finalized'));
    } else if(isOnlyGuideCustomer) {
      history.push(getMyCheckout('new'));
    } else if(selectedPreference?.guideStatus !== 'published'){
      setIsOpen(true);
      const payload = payloadCreation(selectedGuideData, userPreference, selectedColor, email, defaultAddress);
      mutation.mutate(payload);
    } else {
      history.push(getMyCheckout('new'));
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const setSideOptions = (value: string) => {
    setHideOptions(false);
    setActiveTab(value);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleSetSelectedColor = (colorItem: string) => {
    setSelectedColor(colorItem);
    if (selectedPreference) {
      setSelectedPreference({ ...selectedPreference, selectedColor: colorItem });
      const newGuidePreferences = guidePreferences.map((item) => {
        if (item.id === guideName) {
          return { ...item, selectedColor: colorItem };
        }
        return item;
      } );
      updateGuideProfile({ guidePreferences: newGuidePreferences });
    }
  };

  let finalHTML;

  if (!isGuideLoading) {
    finalHTML = strapiHtmlCreation(guideDetails, userPreference, selectedColor, guideName);
  }

  let finalHTMLInfographic;

  if (!isGuideLoading && !isEmpty(guideInfogra)) {
    finalHTMLInfographic = strapiHtmlInfographicCreation(guideInfogra, userPreference, selectedColor);
  }

  if (!isGuideLoading) {
    strapiPreloadHtmlImages(guideDetails, guideInfogra);
  }


  const cards = () => {
    return (
      <>
        {guideColor?.map((color: string) => {
          return (
            <button
              type="button"
              onClick={() => handleSetSelectedColor(color)}
              key={color}
              className="cursor-pointer"
            >
              <div className='w-7 h-7 rounded-full'>
                <div
                  className="w-[26px] h-[26px] border border-neutral-200 rounded-full flex items-center justify-center hover:cursor-pointer hover:outline-primary-400 hover:outline hover:outline-offset-1 hover:outline-2"
                  style={{ backgroundColor: colors[color] }}
                >
                  {color === selectedColor && <CheckIconGuides />}
                </div>
              </div>
            </button>
          );
        })}
      </>
    );
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (finalHTML && currentPage < finalHTML.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPageContent = () => {
    if (!finalHTML || !finalHTML[currentPage]) return '';
    return finalHTML[currentPage];
  };

  const getPageContentInfographic = () => {
    if (!finalHTMLInfographic) return '';
    return finalHTMLInfographic;
  };

  let setupGuides = <></>;

  const activeTabIsPersonal = () => activeTab === 'personal';
  const activeTabIsColors = () => activeTab === 'colors';

  if (activeTabIsPersonal()) {
    setupGuides = <PersonalInformationForm updateGuide />;
  }

  if (activeTabIsColors()) {
    setupGuides = (
      <>
        <div className="flex gap-2 mb-6 py-2.5 px-3 bg-neutral-200 rounded-md">
          <div className="w-5 mt-0.5">
            <InfoIcon fill="#000000" className="w-5" />
          </div>
          <p>The color will be applied to both your guides and the infographic.</p>
        </div>
        <div className="flex flex-wrap items-center justify-start gap-1">
          {cards()}
        </div>
      </>
    );
  }

  const SideBarActiveTabIndicator = (
    <div className={`
      h-full
      flex
      relative
      items-center
      ${!hideOptions ? 'max-sm:after:w-1.5' : 'max-sm:after:w-1'}
      after:w-1
      after:h-[54px]
      after:content-[' ']
      after:absolute
      after:right-[-4px]
      after:top-1
      after:h-8
      after:bg-blueBorder
      after:transition-all
      after:rounded-l-md
      sm:after:duration-200
    `}/>
  );

  const buttonLabel = getEditButtonLabel(inProgressOrders, userPreference?.isFinalized, selectedPreference?.guideStatus);

  const mustDisablePublish = () => {
    const shouldValidatePublish =
      selectedPreference?.guideStatus === 'published' || buttonLabel.toLowerCase().includes('print');

    if (shouldValidatePublish) return disablePrintOption;

    return false;
  }

  useEffect(() => {
    setDisablePrintOption(isCanadianAddress);
  }, [addresses]);

  useEffect(() => {
    getUserAddresses();
  }, []);

  return (
    <div className="fixed inset-0 bg-neutral-50">
      <div className="w-full self-stretch h-16 flex-col justify-start items-center inline-flex">
        <div className="self-stretch h-16 px-6 pt-2 pb-[9px] bg-neutral-800 justify-between items-center inline-flex z-10 max-sm:px-3">
          <button
            type="button"
            className="justify-start items-center gap-2 flex cursor-pointer max-sm:gap-0.5 hover:bg-neutral-600 pr-[18px] rounded-md"
            onClick={handleGoBack}
            aria-label="Back"
          >
            <ArrowLeft />
            <div className="text-neutral-50 text-sm font-bold leading-tight">
              Back
            </div>
          </button>
          <div className="justify-center items-center gap-1 flex">
            <button
              type="button"
              className={`h-[46px] pl-4 pr-[18px] py-[13px] rounded-md justify-center items-center gap-2 flex cursor-pointer hover:bg-neutral-600 ${!isInfographicView ? 'bg-neutral-700' : ''}`}
              onClick={() => handleViewSwitch('guide')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleViewSwitch('guide');
              }}
              aria-label="Guide View"
            >
              <GuideIconFilled className="w-5 h-5" />
              <div className="font-figtree text-neutral-50 text-sm font-bold leading-tight max-sm:hidden">
                Guide
              </div>
            </button>
            { previewGuide?.showInfographics && <button
              type="button"
              className={`h-[46px] pl-4 pr-[18px] py-[13px] rounded-md justify-center items-center gap-2 flex cursor-pointer hover:bg-neutral-600 ${isInfographicView ? 'bg-neutral-700' : ''}`}
              onClick={() => handleViewSwitch('infographic')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleViewSwitch('infographic');
              }}
              aria-label="Infographic View"
            >
              <InfographicIconFilled className="w-5 h-5" />
              <div className="font-figtree text-neutral-50 text-sm font-bold leading-tight max-sm:hidden">
                Infographic
              </div>
            </button> }
          </div>
          <div className="justify-start items-center gap-2 flex">
            <div className="justify-start items-center gap-2 flex">
              <Tooltip
                trigger="hover"
                content={mustDisablePublish() ? 'Sorry, at this point we do not ship printed guides to Canada' : ''}
                style="dark"
                arrow
                placement="top"
                theme={{
                  base:
                    `text-neutral-50 text-sm p-2 px-3 rounded-lg font-normal font-figtree ${mustDisablePublish() ? '' : 'invisible'}`,
                }}
              >
                <Button
                  type='primary'
                  disabled={!userPreference?.isFinalized || mustDisablePublish()}
                  onClick={handlePublish}
                >
                  {buttonLabel}
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <div className="flex" style={{ height: 'calc(100vh - 64px)' }}>
        <div className="flex bg-white z-20">
          <div className="w-[84px] border-r border-neutral-200 pl-2 pt-3.5">
            <div className="flex-row">
              <div className="flex gap-1">
                <button
                  type="button"
                  onClick={() => setSideOptions('personal')}
                  className={cn('group rounded-md w-[68px] h-[68px] flex flex-col justify-center items-center gap-1', {
                    'bg-neutral-100': activeTabIsPersonal(),
                  })}
                >
                  <div className="group-hover:translate-y-[-4px] duration-200">
                    <PiIcon />
                  </div>
                  <p className="text-xs font-bold">Personal</p>
                </button>
                {activeTabIsPersonal() && SideBarActiveTabIndicator}
              </div>
              { isColorShow && <div className="flex gap-1">
                <button
                  type="button"
                  onClick={() => setSideOptions('colors')}
                  className={cn('group rounded-md w-[68px] h-[68px] flex flex-col justify-center items-center gap-1', {
                    'bg-neutral-100': activeTabIsColors(),
                  })}
                >
                  <div className="group-hover:translate-y-[-4px] duration-200">
                    <PaintPaletteIcon />
                  </div>
                  <p className="text-xs font-bold">Colors</p>
                </button>
                {activeTabIsColors() && SideBarActiveTabIndicator}
              </div> }
            </div>
          </div>
          {!hideOptions && (
            <div className="w-11/12 sm:w-[312px] bg-white z-10">
              <div className="flex justify-between items-center border-b border-neutral-200 px-4 py-3.5">
                <p className="font-semibold">{activeTabIsPersonal() ? 'Personal Information' : 'Available Colors'}</p>
                <button
                  type="button"
                  className="p-2.5 bg-neutral-100 rounded-md cursor-pointer hover:bg-neutral-200"
                  onClick={() => setHideOptions(true)}
                  aria-label="Close color options"
                >
                  <CloseCircleIcon />
                </button>
              </div>
              <div
                className="py-6 px-3.5 overflow-y-auto min-w-[235px]"
                style={{ height: 'calc(100vh - 100px)' }}
              >
                {setupGuides}
              </div>
            </div>
          )}
        </div>

        {
          !isInfographicView ? (
            <div className="
              bg-neutral-700
              flex
              gap-4
              items-center
              justify-start
              max-h-[100vh]
              max-md:flex-wrap
              md:justify-between
              min-[350px]:justify-center
              w-full
            ">
              <div className="mx-6 max-md:mt-6">
                <NavigationArrow
                  direction="left"
                  onClick={handlePreviousPage}
                  disabled={currentPage === 0}
                />
              </div>

              <div
                className="
                  w-full
                  h-full
                  scale-30
                  min-[1400px]:scale-50
                  min-[1500px]:scale-50
                  min-[1700px]:scale-60
                  min-[2000px]:scale-80
                  min-[2200px]:scale-90
                  min-[2400px]:scale-[0.95]
                  min-[2600px]:scale-110
                  translate-x-[-10%]
                  -translate-y-[15%]
                  min-[350px]:translate-x-[0]
                  min-[1300px]-translate-y-[10%]
                  min-[1500px]:translate-y-[-12%]
                  min-[1700px]:translate-y-[-10%]
                  min-[2000px]:translate-y-[-5%]
                  min-[2200px]:translate-y-[0]
                  min-[2400px]:translate-y-[2%]
                  min-[2600px]:translate-y-[9.5%]
                  max-md:order-last
                "
              >
                <iframe
                  id="guidesPreview"
                  title="Guide preview"
                  srcDoc={getPageContent()}
                  src="demo_iframe_srcdoc.htm"
                  className="
                    overflow-y-scroll
                    min-[300px]:min-w-[200%]
                    min-[500px]:min-w-[180%]
                    min-[1000px]:min-w-[115%]
                    h-[170%]
                    justify-self-center
                  "
                />
              </div>

              <div className="mx-6 max-md:mt-6">
                <NavigationArrow
                  direction="right"
                  onClick={handleNextPage}
                  disabled={!finalHTML || currentPage === finalHTML.length - 1}
                />
              </div>
            </div>
          ) : (
            <div
              className="relative w-full flex items-center justify-center bg-neutral-700 p-4 overflow-y-auto"
              style={{
                maxHeight: window.innerHeight > 825 ? 'calc(100vh - 64px)' : '100vh',
              }}
            >
              <iframe
                title="Infographic preview"
                width="1000px"
                height="2170px"
                srcDoc={getPageContentInfographic()}
                src="demo_iframe_srcdoc.htm"
                className="
                  absolute
                  left-1/2
                  scale-20
                  min-[1200px]:scale-[0.25]
                  min-[1300px]:scale-[0.30]
                  min-[1500px]:scale-[0.325]
                  min-[2000px]:scale-[0.40]
                  min-[2200px]:scale-[0.45]
                  min-[2400px]:scale-[0.5]
                  min-[2600px]:scale-[0.55]
                  origin-center
                  top-1/2
                  -translate-x-1/2
                  -translate-y-1/2
                "
              >
                <p>Your browser does not support iframes.</p>
              </iframe>
            </div>
          )
        }
      </div>
      <ProcessingModal
        title="Publishing your guide..."
        description="Please wait"
        showModal={isOpen}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default compose(
  withProfile,
  withAuth,
  withGuideProfile,
  withUserPreferences,
  withAddress,
)(EditGuide);
