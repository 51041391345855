import { call, put, takeEvery } from 'redux-saga/effects';

import { fetchUserPreferences, resource } from 'modules/api';
import { GET_USER_PREFERENCES } from 'modules/dashboard/store/constants';

export function* onGetUserPreferences() {
  try {
    const response = yield call(fetchUserPreferences);
    yield put({
      type: `${GET_USER_PREFERENCES}_SUCCEEDED`,
      payload: { preferences: response },
    });
  } catch (error) {
    yield put({
      type: `${GET_USER_PREFERENCES}_FAILED`,
      error,
    });
  }
}

export function* watchGetUserPreferences() {
  yield takeEvery(GET_USER_PREFERENCES, onGetUserPreferences);
}
