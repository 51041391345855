export const DangerTriangleIcon = ({ width = 20, height = 20, fill = '#D9630B' }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none">
    <path
      d="M4.42675 8.96791C6.85872 4.65597 8.0747 2.5 10.0003 2.5C11.9259 2.5 13.1419 4.65597 15.5739 8.96791L15.8769 9.50522C17.8979 13.0884 18.9084 14.88 17.9951 16.19C17.0818 17.5 14.8224 17.5 10.3034 17.5H9.69727C5.17829 17.5 2.9188 17.5 2.00554 16.19C1.09228 14.88 2.10275 13.0884 4.1237 9.50523L4.42675 8.96791Z"
      stroke={fill}
      strokeWidth="2"
    />
    <path d="M10 6.6665V10.8332" stroke={fill} strokeWidth="2" strokeLinecap="round" />
    <circle cx="10.0003" cy="13.3333" r="0.833333" fill={fill} />
  </svg>
);
