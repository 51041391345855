import { getInstance } from './instance';

const instance = getInstance();

const getOneTimePaymentOnly = async (strapiContentId = '') => {
  const url = `product-catalog/products?oneTimePaymentOnly=true&q=${strapiContentId}`;

  try {
    const response = await instance
      .get(url);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export { getOneTimePaymentOnly };