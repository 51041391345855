export const removeEmptyValues = <T extends Record<string, any>>(obj: T): Partial<T> => {
  return Object.entries(obj).reduce<Partial<T>>((acc, [key, value]) => {
    if (
      value !== undefined &&
      value !== null &&
      value !== "" &&
      value !== false &&
      (!Array.isArray(value) || value.length > 0) &&
      (typeof value !== "object" || Object.keys(value).length > 0)
    ) {
      acc[key as keyof T] = typeof value === "object" && !Array.isArray(value)
        ? removeEmptyValues(value)
        : value;
    }
    return acc;
  }, {});
};
