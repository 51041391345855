import { call, takeLatest } from 'redux-saga/effects';
import { notification } from 'modules/common/utils';

import { uploadAvatar, resource, getError } from 'modules/api';
import { UPLOAD_AVATAR } from 'modules/dashboard/store/constants';
import { formatImageUrl } from 'modules/dashboard/utils';

function* onUploadAvatar({ payload }) {
  try {
    const request = resource(UPLOAD_AVATAR, uploadAvatar, formatImageUrl);
    const response = yield call(request, payload);

    notification.success({ description: 'Avatar was uploaded successfully' });

    return response;
  } catch (error) {
    const response = getError(error);
    console.error(response);

    notification.error({ description: 'Avatar could not be uploaded' });

    return response;
  }
}

export function* watchUploadAvatar() {
  yield takeLatest(UPLOAD_AVATAR, onUploadAvatar);
}
