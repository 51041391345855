import { getInstance } from '../instance';

const instance = getInstance();

export const fetchAllOrders = (queryParams) => {
  const url = 'api/order/list-orders';
  return instance.post(url, queryParams);
};

export const createGuide = async (queryParams) => {
  const url = 'api/guides';
  const { data: response } = await instance.post(url, queryParams);
  return response;
};

export const publishGuide = async (queryParams) => {
  const url = 'api/guides/publish';
  const { data: response } = await instance.post(url, queryParams);
  return response;
};

export const fetchGuidesListByEmail = (email) => {
  const encodedEmail = encodeURIComponent(email);
  const url =  `api/guides/list?userEmail=${encodedEmail}`;
  return instance.get(url);
};