import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { DashboardTemplate } from 'modules/v2/templates';
import GuideCheckout from './GuideCheckout';

const Checkout = () => {
  const { status } = useParams<{ status: string }>();
  const location = useLocation();
  const [orderId, setOrderId] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderData = queryParams.get('orderid');
    if(orderData){
      setOrderId(orderData);
    }
  }, [location.search]);

  return (
    <DashboardTemplate hasSidebar >
      <GuideCheckout checkoutStatus={status} orderId={orderId} />
    </DashboardTemplate>
  );
};

export default Checkout;