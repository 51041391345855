import { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { compose } from 'redux';
import { Form, Formik } from 'formik';
import { APIProvider, Map, Pin, AdvancedMarker } from '@vis.gl/react-google-maps';
import { get, isEmpty, map } from 'lodash-es';

import env from 'environments';

import { updateDefaultAddress } from 'modules/api/user';
import { withAddress, withUserPreferences } from 'modules/dashboard/containers';
import { withCountries } from 'modules/v2/containers';
import { cn } from 'modules/v2/common/utils/cn';

import { getUser } from 'modules/api/afyLogger/afyLogger';
import { SelectField } from 'modules/v2/common/components';
import TextInput from 'modules/v2/common/AtomicDesign/atoms/TextInput';
import { LabelInput, Button } from 'modules/v2/common/AtomicDesign/atoms';
import AddressGoogleLookup from 'modules/v2/common/components/AddressGoogleLookup';
import { getItem } from 'modules/dashboard/utils/legacy';

import validationSchema from 'modules/editor/components/AddressForm/validationSchema';
import MapHandler from './MapHandler';
import S from './styles';

interface AddressValuesProps {
  _id?: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  pincode: string;
  state: string;
  isDefault?: boolean;
}

const API_KEY = env.GOOGLE_MAP_API_KEY;

const AddressForm = ({
  showButton = false,
  hideCancelButton = false,
  confirmButtonTitle = 'Save changes',
  cancelButtonTitle = 'Cancel',
  usePublicAddress = true,
  initialValues,
  // DATA
  addresses,
  countries,
  states,
  setAddressValues,
  userPreferences,

  // Callbacks
  addUserAddresses,
  editUserAddresses,
  getUserAddresses,
}) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [geocodeLocation, setGeocoderLocation] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isSavingAddress, setIsSavingAddress] = useState(false);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [addressesQuantity, setAddressesQuantity] = useState(0);
  const [publicAddressValue, setPublicAddressValue] = useState<AddressValuesProps>({
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: '',
    pincode: '',
    state: '',
  });
  const [addressValue, setAddressValue] = useState<AddressValuesProps>({
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: '',
    pincode: '',
    state: '',
  });

  useEffect(() => {
    getUserAddresses();
  }, []);

  useEffect(() => {
    if (addresses?.status === 'STATUS_SUCCESS') {
      const defaultAddress = addresses.data.find((address) => address.isDefault === true);
      const billingAddress = addresses.data.find((address) => address._id === 'billing');
      setAddressValue(defaultAddress || billingAddress);
      setAddressesQuantity(addresses.data.length);

      if (!defaultAddress && addressesQuantity > 0) {
        const lastId = addresses?.data[addresses?.data?.length - 1]?._id;
        mutateDefaultAddress(lastId);
      }
    }
  }, [addresses]);

  useEffect(() => {
    if (addressesQuantity > 0 && isSavingAddress) {
      const lastId = addresses?.data[addresses?.data?.length - 1]?._id;
      mutateDefaultAddress(lastId);
    }
  }, [addressesQuantity]);


  useEffect(() => {
    if (usePublicAddress && userPreferences?.status === 'STATUS_SUCCESS') {
      const publicAddress = userPreferences.data.data.data.publicAddress;
      setPublicAddressValue(publicAddress);
    }
  }, [usePublicAddress, userPreferences]);

  const { mutate: mutateDefaultAddress } = useMutation(updateDefaultAddress, {
    onSuccess: () => {
      getUserAddresses();
      setIsSavingAddress(false);
    },
  });

  const fields = {
    firstName: userData?.firstname,
    lastName: userData?.lastname,
    phoneNumber: '',
    addressLine1: usePublicAddress ? publicAddressValue?.addressLine1 : addressValue?.addressLine1 || '',
    addressLine2: usePublicAddress ? publicAddressValue?.addressLine2 : addressValue?.addressLine2 || '',
    city: usePublicAddress ? publicAddressValue?.city : addressValue?.city || '',
    country: usePublicAddress ? publicAddressValue?.country : addressValue?.country || '',
    pincode: usePublicAddress ? publicAddressValue?.pincode : addressValue?.pincode || '',
    state: usePublicAddress ? publicAddressValue?.state : addressValue?.state || '',
    isDefault: true,
  };

  const handleSaveAddress = useCallback(
    (field) => {
      setIsSavingAddress(true);
      field.firstName = userData?.firstname;
      field.lastName = userData?.lastname;
      const callback = field._id ? editUserAddresses : addUserAddresses;
      callback(field);
    },
    [addUserAddresses, userData],
  );

  useEffect(() => {
    const cacheData = JSON.parse(getItem('userData'));
    const user = getUser();
    if (cacheData) {
      cacheData.id = user?.id;
      setUserData(cacheData);
    }
  }, []);

  console.log('initialValues', initialValues);

  return (
    <Formik
      initialValues={initialValues || fields}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur
      onSubmit={handleSaveAddress}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        setFieldValue
      }) => {
        const optionBuilder = (item) => ({
          title: item,
          value: item,
        });

        useEffect(() => {
          if (setAddressValues) {
            setAddressValues((prevFields) => ({
              ...prevFields,
              country: values.country,
              state: values.state,
              addressLine1: values.addressLine1,
              addressLine2: values.addressLine2,
              city: values.city,
              pincode: values.pincode,
            }));
          }
        }, [values]);

        const selectedCountry = values.country;
        const selectedStateList = get(states.data, selectedCountry);

        const countryList = map(countries.data, optionBuilder);
        const stateList = map(selectedStateList, optionBuilder);

        const handleCountryChange = () => setFieldValue('state', undefined);

        const onChangeField = (e) => {
          const { name, value } = e.target;
          setFieldValue(name, value);

          if (setAddressValues) {
            setAddressValues((prevFields) => ({
              ...prevFields,
              [name]: value,
            }));
          }
        };

        const hideForm = isEmpty(geocodeLocation?.results) && !isManualEntry && !usePublicAddress;

        return (
          <Form onSubmit={handleSubmit} className="address-form p-0 w-full">
            <APIProvider apiKey={API_KEY} libraries={['marker']}>
              <div className="flex flex-col items-center justify-center gap-8 focus:border-primary-500">
                <div className="w-full h-auto">
                  <div>
                    <div
                      className={cn('scale-0 opacity-0 transition-all ease-in-out duration-300', {
                        'scale-100 opacity-100 p-0 border-2 border-neutral-200 rounded-lg overflow-hidden mb-[20px]': selectedPlace,
                      })}
                    >
                      <Map
                        mapId="bf51a910020fa25a"
                        style={{
                          width: '100%',
                          height: selectedPlace && '250px',
                          borderRadius: '8px',
                        }}
                        defaultZoom={15}
                        defaultCenter={{
                          lat: selectedPlace?.geometry?.location.lat(),
                          lng: selectedPlace?.geometry?.location.lat(),
                        }}
                        gestureHandling="greedy"
                        disableDefaultUI
                      />
                    </div>
                    {selectedPlace && (
                      <AdvancedMarker
                        position={{
                          lat: selectedPlace?.geometry?.location.lat() || 0,
                          lng: selectedPlace?.geometry?.location.lng() || 0,
                        }}
                        title={selectedPlace?.formatted_address}
                      >
                        <Pin background="#4CC9E1" borderColor="#22ccff" glyphColor="#fff" />
                      </AdvancedMarker>
                    )}

                    <MapHandler place={selectedPlace} />

                    <div>
                      <AddressGoogleLookup
                        setSelectedPlace={setSelectedPlace}
                        setGeocoderLocation={setGeocoderLocation}
                        setFieldValue={setFieldValue}
                        setIsManualEntry={setIsManualEntry}
                        initialInputValue={values?.addressLine1}
                      />
                      {touched.addressLine1 && errors.addressLine1 ? (
                        <span className="text-error-500 mt-2">{errors.city}</span>
                      ) : null}
                    </div>
                    <div
                      className={cn('mt-4 gap-4 flex flex-col', {
                        'hidden p-0': hideForm,
                      })}
                    >
                      <div>
                        <LabelInput>Apartment, suite, unit, etc. (if applicable)</LabelInput>
                        <TextInput
                          type="text"
                          sizing="sm"
                          name="addressLine2"
                          placeholder="Optional"
                          value={values.addressLine2}
                          onChange={(element) => {
                            onChangeField(element)
                            handleChange(element)
                          }}
                        />
                      </div>
                      <div className="w-full flex gap-3 max-[650px]:flex-col">
                        <div className="w-full">
                          <LabelInput required>City</LabelInput>
                          <TextInput
                            type="text"
                            name="city"
                            sizing="sm"
                            value={values.city}
                            onChange={(element) => {
                              onChangeField(element)
                              handleChange(element)
                            }}
                            errorMessage="City is required"
                          />
                          {touched.city && errors.city ? (
                            <span className="text-error-500 mt-2">{errors.city}</span>
                          ) : null}
                        </div>

                        <div className="w-full flex flex-col">
                          <LabelInput required className="mb-[0px]">
                            Country
                          </LabelInput>
                          <S.FormikField
                            component={SelectField}
                            name="country"
                            placeholder="Country"
                            hasError
                            type="text"
                            value={values.country}
                            options={countryList}
                            onChange={handleCountryChange}
                          />

                          {errors.country && (
                            <span className="text-error-500 text-sm">{errors.country}</span>
                          )}
                        </div>
                      </div>
                      <div className="w-full flex gap-3 max-[650px]:flex-col">
                        <div className="w-full">
                          <LabelInput required>Zip/Postal code</LabelInput>
                          <TextInput
                            type="text"
                            name="pincode"
                            sizing="sm"
                            value={values.pincode}
                            onChange={(element) => {
                              onChangeField(element)
                              handleChange(element)
                            }}
                            errorMessage="Zip code is required"
                            isValid={!errors.pincode && touched.pincode}
                          />
                          {errors.pincode && (
                            <span className="text-error-500 text-sm">{errors?.pincode}</span>
                          )}
                        </div>

                        <div className="w-full flex flex-col">
                          <LabelInput required className="mb-[0px]">
                            State/Province
                          </LabelInput>
                          <S.FormikField
                            component={SelectField}
                            name="state"
                            placeholder="State"
                            hasError
                            type="text"
                            value={values.state}
                            options={stateList}
                          />
                          {errors.state && (
                            <span className="text-error-500 text-sm">{errors.state}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    {showButton && (
                      <div className="w-full flex gap-1 mt-6">
                        {!hideCancelButton && (
                          <Button color="light" onClick={() => handleCancelClick()}>
                            {cancelButtonTitle}
                          </Button>
                        )}
                        <Button color='primary' buttonType="submit" disabled={isSavingAddress}>
                          {confirmButtonTitle}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </APIProvider>
          </Form>
        );
      }}
    </Formik>
  );
};

const Composed = compose(withAddress, withCountries, withUserPreferences)(AddressForm);

export default Composed;
