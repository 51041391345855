import { useEffect, useState } from 'react';
import { BrokerLogoModalPreferences } from 'modules/v2/common/components';
import { Button, LabelInput, TextInput, SwitchButton } from 'modules/v2/common/AtomicDesign/atoms';

interface LogoUploaderProps {
  isActive: boolean | null;
  setIsActive: (value: boolean) => void;
  imageUrl?: string | null;
  setImageUrl?: (value: string) => void;
  companyName?: string;
  setCompanyName?: (value: string) => void;
  dimensions?: { width: number, height: number };
  modalTitle?: string;
  switchButtonText?: string;
}

const LogoUploader = ({
  isActive,
  setIsActive,
  imageUrl,
  setImageUrl,
  companyName,
  setCompanyName,
  dimensions = { width: 274, height: 154 },
  modalTitle = 'Upload Broker Logo',
}: LogoUploaderProps) => {
  const [toggleBrokerModal, setToggleBrokerModal] = useState(false);
  const type = 'brokerageLogoPreferences';

  const onSuccess = (value) => {
    setImageUrl(value);
    setToggleBrokerModal(false);
  };
  const onCancel = () => setToggleBrokerModal(false);
  const handleOpenModal = () => setToggleBrokerModal(true);
  const onRemoveImage = () => setImageUrl(null);

  return (
    <div>
      <div className='flex items-center gap-3.5'>
        <SwitchButton
          checked={isActive}
          setChecked={setIsActive}
          color='#4CC9E1'
        />

        <label className="text-sm font-semibold" onClick={() => setIsActive(!isActive)}>
          Add Your Brokerage
        </label>
      </div>
      {isActive && <div className='w-full  mt-3.5'>
        <BrokerLogoModalPreferences
          title={modalTitle}
          type={type}
          isOpen={toggleBrokerModal}
          onCancel={onCancel}
          onSuccess={onSuccess}
          imageUrlForEdit={imageUrl}
          toggleCancelEdit={() => setToggleBrokerModal(false)}
        />
        <div
          className="w-full pr-2 flex justify-start mb-3.5 max-[600px]:overflow-x-auto"
          onClick={handleOpenModal}
        >
          <div>
            <p className='text-sm font-semibold'>Broker Company Logo<span className='text-error-400'>*</span></p>
            <p className='text-sm font-medium text-neutral-500'>Recommended size: {dimensions.width} x {dimensions.height}</p>
          </div>
        </div>

        {imageUrl ? (
          <div className="flex flex-wrap gap-3 items-center">
            <div className={`w-[${dimensions.width}px] h-[${dimensions.height}px] min-w-[255px] border border-neutral-200 rounded-md bg-white flex items-center justify-center`}>
              <img src={imageUrl} alt={type} className='object-container rounded-md' />
            </div>
            <div className='flex gap-3'>
              <Button
                color="light"
                onClick={handleOpenModal}
                className="border-neutral-200 hover:border-neutral-500 hover:bg-neutral-50 h-11"
              >
                Change
              </Button>
              <Button
                color="light"
                onClick={onRemoveImage}
                className="border-neutral-200 hover:border-neutral-500 hover:bg-neutral-50 h-11"
              >
                Remove
              </Button>
            </div>
          </div>
        ) : (
          <div className='w-full'>
            <Button
              type="outlined"
              full
              onClick={handleOpenModal}
            >
              Upload Logo
            </Button>
          </div>
        )}
        <div>
          <div className="flex-1 flex flex-col mt-6">
            <div className="w-full max">
              <LabelInput required>Company Name</LabelInput>
              <TextInput
                type="text"
                name="company"
                sizing='sm'
                className="w-full"
                errorMessage="Please enter the company name"
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
                value={companyName}
              />
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default LogoUploader;
