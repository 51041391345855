import { STATUS_LOADING, STATUS_SUCCESS, STATUS_ERROR } from 'store/status';
import { 
  GET_USER_PREFERENCES,
  UPDATE_PASSWORD,
  UPDATE_PREFERENCES,
  UPLOAD_USER_HEADSHOT,
  UPLOAD_USER_BROKERLOGO,
 } from 'modules/dashboard/store/constants';
import { user as initialState } from 'modules/dashboard/store/reducers/initialState';

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case `${UPDATE_PREFERENCES}_REQUESTED`: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          status: STATUS_LOADING,
        },
      };
    }

    case `${UPDATE_PREFERENCES}_SUCCEEDED`: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          status: STATUS_SUCCESS,
        },
      };
    }

    case `${UPDATE_PREFERENCES}_FAILED`: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          status: STATUS_ERROR,
        },
      };
    }

    case `${UPDATE_PASSWORD}_REQUESTED`: {
      return {
        ...state,
        password: {
          ...state.password,
          status: STATUS_LOADING,
        },
      };
    }

    case `${UPDATE_PASSWORD}_SUCCEEDED`: {
      return {
        ...state,
        password: {
          ...state.password,
          status: STATUS_SUCCESS,
        },
      };
    }

    case `${UPDATE_PASSWORD}_FAILED`: {
      return {
        ...state,
        password: {
          ...state.password,
          status: STATUS_ERROR,
        },
      };
    }

    case `${GET_USER_PREFERENCES}_SUCCEEDED`: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          status: STATUS_SUCCESS,
          data: action.payload.preferences,
        },
      };
    }

    case `${GET_USER_PREFERENCES}_FAILED`: {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          status: STATUS_ERROR,
        },
      };
    }

    case `${UPLOAD_USER_HEADSHOT}_REQUESTED`: {
      return {
        ...state,
        headshot: {
          ...state.headshot,
          status: STATUS_LOADING,
        },
      };
    }

    case `${UPLOAD_USER_HEADSHOT}_FAILED`: {
      return {
        ...state,
        headshot: {
          ...state.headshot,
          status: STATUS_ERROR,
        },
      };
    }

    case `${UPLOAD_USER_HEADSHOT}_SUCCEEDED`: {
      return {
        ...state,
        headshot: {
          ...state.headshot,
          data:  action.payload.headshot,
          status: STATUS_SUCCESS,
        },
      };
    }

    case `${UPLOAD_USER_BROKERLOGO}_REQUESTED`: {
      return {
        ...state,
        brokerLogo: {
          ...state.brokerLogo,
          status: STATUS_LOADING,
        },
      };
    }

    case `${UPLOAD_USER_BROKERLOGO}_FAILED`: {
      return {
        ...state,
        brokerLogo: {
          ...state.brokerLogo,
          status: STATUS_ERROR,
        },
      };
    }

    case `${UPLOAD_USER_BROKERLOGO}_SUCCEEDED`: {
      return {
        ...state,
        userBrokerLogo: {
          ...state.brokerLogo,
          data: action.payload.userBrokerLogo,
          status: STATUS_SUCCESS,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
