function BuyAgainIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Iconer">
        <path id="Vector" d="M3.06683 9.44444H2.31683H3.06683ZM3.06684 10.8333L2.53859 11.3657C2.831 11.6559 3.30267 11.6559 3.59508 11.3657L3.06684 10.8333ZM4.99492 9.97685C5.28896 9.68511 5.29083 9.21024 4.99909 8.9162C4.70735 8.62216 4.23248 8.6203 3.93843 8.91204L4.99492 9.97685ZM2.19524 8.91204C1.90119 8.6203 1.42632 8.62216 1.13458 8.9162C0.842843 9.21024 0.844708 9.68511 1.13875 9.97685L2.19524 8.91204ZM15.4068 6.22612C15.6237 6.57899 16.0856 6.68918 16.4385 6.47225C16.7914 6.25532 16.9016 5.79341 16.6846 5.44054L15.4068 6.22612ZM10.066 1.75C5.79184 1.75 2.31683 5.18938 2.31683 9.44444H3.81683C3.81683 6.02889 6.60914 3.25 10.066 3.25V1.75ZM2.31683 9.44444L2.31684 10.8333H3.81684L3.81683 9.44444L2.31683 9.44444ZM3.59508 11.3657L4.99492 9.97685L3.93843 8.91204L2.53859 10.3009L3.59508 11.3657ZM3.59508 10.3009L2.19524 8.91204L1.13875 9.97685L2.53859 11.3657L3.59508 10.3009ZM16.6846 5.44054C15.324 3.22738 12.868 1.75 10.066 1.75V3.25C12.3291 3.25 14.3097 4.44155 15.4068 6.22612L16.6846 5.44054Z" fill="#252525"/>
        <path id="Union" fillRule="evenodd" clipRule="evenodd" d="M16.4008 8.63309C16.693 8.34431 17.1631 8.34431 17.4553 8.63309L18.8604 10.022C19.155 10.3132 19.1578 10.788 18.8666 11.0826C18.5755 11.3772 18.1006 11.38 17.806 11.0888L17.6679 10.9523C17.459 15.0247 14.0543 18.2498 9.90205 18.2498C7.09179 18.2498 4.62717 16.7737 3.26127 14.5604C3.04374 14.2079 3.15315 13.7458 3.50564 13.5283C3.85813 13.3107 4.32023 13.4201 4.53777 13.7726C5.63896 15.557 7.62799 16.7498 9.90205 16.7498C13.2324 16.7498 15.9446 14.1941 16.1637 10.9765L16.0501 11.0888C15.7555 11.38 15.2806 11.3772 14.9894 11.0826C14.6982 10.788 14.701 10.3132 14.9956 10.022L16.4008 8.63309Z" fill="#252525"/>
      </g>
    </svg>

  );
}

export default BuyAgainIcon;
