import { createSelector } from 'reselect';

import { dashboardSelector } from '../dashboard';

export const userSelector = createSelector(dashboardSelector, (dashboard) => dashboard.user);
export const passwordSelector = createSelector(userSelector, (user) => user.password);
export const preferencesSelector = createSelector(userSelector, (user) => user.preferences);

export const headshotStateSelector = createSelector(
  userSelector,
  (user) => user.headshot
);

export const brokerLogoStateSelector = createSelector(
  userSelector,
  (user) => user.userBrokerLogo
);