import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { compose } from 'redux';
import { isEmpty } from 'lodash';

import { withProfile } from 'modules/v2/containers';
import { fetchOrderList } from 'modules/api';
import { fetchAllOrders } from 'modules/api/authorify/guides';
import { DashboardTemplate } from 'modules/v2/templates';
import { OrderHistorySubMenu } from 'modules/v2/common/AtomicDesign/molecules';
import OrderedBookPage from 'modules/v2/pages/OrderedBooksListingPage/OrderedBookPage';
import { hasGuideAccess } from 'modules/v2/utils';

import { GuidesProvider } from './context';
import OrderedGuides from './OrderedGuides/index';


const MyOrder = ({ profile }) => {
  const { data: profileData } = profile;
  const { email } = profileData;

  const showGuides = hasGuideAccess();

  const [subMenuItems, setSubMenuItems] = useState([
    { title: 'Guides', id: 'guides', count: 0, active: showGuides },
    { title: 'Books', id: 'books', count: 0, active: !showGuides },
  ]);

  const { data: guideOrderList, isLoading: isGuideOrderLoading } = useQuery(['getOrders', email], () =>
    fetchAllOrders({ customerEmail: email }),
  );

  const { data: bookOrderList, isLoading: isBookOrderLoading } = useQuery('fetchOrderList', fetchOrderList);

  useEffect(() => {
    if (!isBookOrderLoading && !isGuideOrderLoading && guideOrderList && bookOrderList) {
      const updateSubMenu = (id: string, title: string, orderList: any[], isLoading: boolean) => {
        if (!isLoading) {
          const count = orderList?.length || 0;
  
          setSubMenuItems((prev) =>
            prev.map((item) =>
              item.id === id
                ? { ...item, title, count, active: item.active }
                : item
            )
          );
        }
      };
  
      if (showGuides) {
        updateSubMenu('guides', 'Guides', guideOrderList?.data?.payload, isGuideOrderLoading);
      } else {
        setSubMenuItems((prev) =>
          prev
            .filter((item) => item.id !== 'guides')
            .map((item) => ({ ...item, active: item.id === 'books' }))
        );
      }
  
      updateSubMenu('books', 'Books', bookOrderList?.data, isBookOrderLoading);
    }
  }, [isGuideOrderLoading, guideOrderList, isBookOrderLoading, bookOrderList, showGuides]);
  

  const changeActiveItem = (id) => {
    const newItems = subMenuItems.map((item) => {
      if (item.id === id) {
        return { ...item, active: true };
      }
      return { ...item, active: false };
    });
    setSubMenuItems(newItems);
  };

  const subHeader = <OrderHistorySubMenu changeActiveItem={changeActiveItem} items={subMenuItems} />;

  const showCurrentComponent = (id) => {
    switch (id) {
      case 'guides':
        return <OrderedGuides changeActiveItem={changeActiveItem} items={subMenuItems} />;
      case 'books':
        return <OrderedBookPage changeActiveItem={changeActiveItem} items={subMenuItems} />;
      default:
        return <OrderedGuides changeActiveItem={changeActiveItem} items={subMenuItems} />;
    }
  };

  return (
    <DashboardTemplate hasSidebar title="Order History" subHeader={subHeader}>
      <GuidesProvider>
        {showCurrentComponent(subMenuItems.find((item) => item.active).id)}
      </GuidesProvider>
    </DashboardTemplate>
  );
};

export default compose(withProfile)(MyOrder);