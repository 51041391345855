import React from 'react';
import { CustomFlowbiteTheme, Tooltip as TooltipBase } from 'flowbite-react';

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  arrow?: boolean;
  trigger?: 'click' | 'hover';
  style?: 'light' | 'dark';
  placement?: 'left' | 'right' | 'top' | 'bottom';
  theme?: CustomFlowbiteTheme['tooltip'];
}

const customTheme: CustomFlowbiteTheme['tooltip'] = {
  arrow: {
    style: {
      dark: "bg-neutral-800 dark:bg-neutral-800",
      light: "bg-white",
      auto: "bg-white dark:bg-neutral-800",
    },
    placement: "-4px",
  },
  base:
    "absolute z-10 inline-block rounded-lg p-3 text-sm shadow-sm font-normal",
  style: {
    dark: "bg-neutral-800 text-white",
    light: "bg-white text-neutral-800",
    auto: "bg-white text-neutral-800",
  },
};


const defaultProps: Partial<TooltipProps> = {
  arrow: false,
  trigger: 'click',
  style: 'light',
  placement: 'left',
  theme: customTheme,
};

const Tooltip = ({ content, children, arrow, trigger, style, placement, theme }: TooltipProps) => {
  return (
    <TooltipBase
      arrow={arrow}
      trigger={trigger}
      style={style}
      placement={placement}
      theme={theme || customTheme}
      content={content}
    >
      {children}
    </TooltipBase>
  );
};

Tooltip.defaultProps = defaultProps;

export default Tooltip;
