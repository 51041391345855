import { useEffect, useState } from 'react';
import { Tooltip } from 'flowbite-react';
import { useSelector, useDispatch } from 'react-redux';
import { cn } from 'modules/v2/common/utils';
import { useCustomerPlanInfo, hasGuideAccess, isGuideOnlyCustomer } from 'modules/v2/utils';
import { hasOnlyRMAccess, checkRMSubscriptionAccess } from 'modules/v2/utils/hasOnlyRMAccess';
import { useBookAccess } from 'modules/v2/utils/useBookAccess';
import { hideBookAccess } from 'modules/v2/utils/hideBookAccess';
import { isPilotCustomer } from 'modules/v2/utils/isPilotCustomer';
import { getData } from 'modules/auth';
import { updateIsBooks, updateIsRm, updateIsGuides } from 'shared/store/actions/bundleAccess';
import DropdownMenu from './DropdownMenu';
import navigationRoutes from './navigationRoutes';
import MenuItem from './MenuItem';
import {
  CAMPAIGNS,
  LEADS,
  MY_BOOKS,
  REFERRAL_MARKETING,
  TRAINING,
  WELCOME_JOURNEY,
  GUIDES_AUTOMATION,
  CAMPAIGNS_KEY,
  LEADS_KEY,
  MY_BOOKS_GUIDES,
  REFERRAL_MARKETING_GUIDES,
} from './constants';

const SidebarMenu = ({ disableMenu = false, hasTrial = false }) => {
  const dispatch = useDispatch();
  const { pathname } = window.location;
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [welcomeJourneyEligible, setWelcomeJourneyEligible] = useState(true);

  const { hasBookAccess } = useBookAccess();
  const { isRm, isBooks } = useSelector((data: any) => data.bundleAccess);

  const showGuidesTab = hasGuideAccess();

  const isGuideOnly = isGuideOnlyCustomer();

  useEffect(() => {
    const handleResize = () => setWindowSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch(updateIsGuides(showGuidesTab));
    const { userData } = getData();
    setWelcomeJourneyEligible(!!userData?.welcomeJourneyEligible);
  }, []);

  useEffect(() => {
    dispatch(updateIsBooks(hasBookAccess && !hideBookAccess()));
    dispatch(updateIsRm(isPilotCustomer() || hasOnlyRMAccess() || checkRMSubscriptionAccess()));
  }, [hasBookAccess]);

  const shouldDisplayMenuItem = (item: { key: string }) => {
    if (item?.key === GUIDES_AUTOMATION) {
      return showGuidesTab;
    }

    return (
      (welcomeJourneyEligible && item?.key === WELCOME_JOURNEY) ||
      (item.key === CAMPAIGNS_KEY && (isRm || isBooks)) ||
      (item.key === LEADS_KEY && (isRm || isBooks)) ||
      (item.key === MY_BOOKS_GUIDES && !isBooks) ||
      (item.key === REFERRAL_MARKETING_GUIDES && !isRm) ||
      (isRm && item?.key === REFERRAL_MARKETING) ||
      (isBooks && item?.key === MY_BOOKS) ||
      ![
        MY_BOOKS,
        REFERRAL_MARKETING,
        WELCOME_JOURNEY,
        CAMPAIGNS_KEY,
        LEADS_KEY,
        MY_BOOKS_GUIDES,
        REFERRAL_MARKETING_GUIDES,
      ].includes(item?.key)
    );
  };

  const isMobile = windowSize < 1024;
  const isDigitalCustomer = useCustomerPlanInfo();
  const indexMyBooks = navigationRoutes.findIndex((item) => item.key === 'my-books');

  const booksNavigationUpdated = navigationRoutes[indexMyBooks]?.children.filter((child) => {
    return isDigitalCustomer.isDigital || child.key !== 'Digital-books';
  });

  const navigationRoutesUpdated = navigationRoutes.map((item) => {
    if (item.key === 'my-books') {
      return { ...item, children: booksNavigationUpdated };
    }
    return item;
  });

  const isFirstAccessGuideOnly: boolean = localStorage.getItem('isWJGuidesCompleted') === 'false';

  return (
    <div
      className={cn('pl-3 py-4 bg-white border-divider text-sm', {
        'fixed top-0 left-1 z-40 h-fit w-[calc(100%-8px)] border-[1px] shadow-box rounded-lg': isMobile,
        'h-full pb-20 overflow-y-auto border-r-[1px]': !isMobile,
      })}
    >
      <ul
        className={cn('font-medium space-y-2', {
          'mt-[96px]': (hasTrial || welcomeJourneyEligible) && isMobile,
          'mt-[44px]': !hasTrial && !welcomeJourneyEligible && isMobile,
        })}
      >
        {navigationRoutesUpdated.map((item) => {
          if (!shouldDisplayMenuItem(item)) return null;
          const isDisabled =
            (item.name === 'Training' && isFirstAccessGuideOnly && isGuideOnly) ||
            (welcomeJourneyEligible && item.name !== 'Welcome journey');

          return (
            <li key={item.key}>
              {isDisabled ? (
                <Tooltip
                  className={cn(
                    'ml-[100px] w-[200px]',
                    { 'ml-[70px]': item?.name === CAMPAIGNS },
                    { 'ml-[140px]': item?.name === LEADS },
                    { 'ml-[90px]': item?.name === TRAINING },
                  )}
                  content="Complete the action steps below to unlock this"
                  placement="right"
                >
                  <li
                    className="relative"
                  >
                    <MenuItem
                      name={item?.name}
                      Icon={item?.icon}
                      href="#"
                      ActionIcon={item?.actionIcon}
                      current={pathname === item?.href}
                      welcomeJourneyEligible={welcomeJourneyEligible}
                    />
                  </li>
                </Tooltip>
              ) : !item.children ? (
                <MenuItem
                  name={item?.name}
                  Icon={item?.icon}
                  href={item?.href}
                  ActionIcon={item?.actionIcon}
                  current={pathname === item?.href}
                  welcomeJourneyEligible={welcomeJourneyEligible}
                />
              ) : (
                <DropdownMenu
                  item={item}
                  open={pathname.includes(item.key)}
                  options={undefined}
                  current={pathname === item?.href}
                  welcomeJourneyEligible={welcomeJourneyEligible}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SidebarMenu;
