import { getInstance } from './instance';
import { upgrade as upgradePlan } from '../afyPlans/plans';

const instance = getInstance();

export const readSubscription = (id) => {
  return instance
    .get(`/payment-chargify/subscriptions/${id}.json`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const createSubscription = (payload) => {
  return instance
    .post('/payment-chargify/subscription', payload)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const upgradePlanSubscription = (payload) => {
  return instance
    .post('/upgrade-plan/plan-upgrade', payload)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const upgradePlanSubscriptionNewUpgrade = (payload) => {
  return upgradePlan(payload)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const migratePlanToChargify = (planComponentHandle) => {
  return instance
    .post(`/upgrade-plan/migrate-subscription/${planComponentHandle}`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getSubscriptionService = () => {
  return instance
    .get('/upgrade-plan/identify-account')
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getPaymentProfiles = () => {
  return instance
    .get('/payment-chargify/payment-profiles')
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getPaymentProfilesList = () => {
  return instance
    .get('/payment-chargify/payment-profiles-list')
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getDefaultPaymentProfile = () => {
  return instance
    .get('/payment-chargify/default-payment-profiles')
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const setDefaultPaymentProfile = (id) => {
  return instance
    .post(`/payment-chargify/payment-profiles/${id}`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const deletePaymentProfile = (id) => {
  return instance
    .delete(`/payment-chargify/payment-profile/${id}`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getShowCreditsButton = () => {
  return instance
    .get('/payment-chargify/get-show-credits-button')
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const createPaymentProfile = (payload) => {
  return instance
    .post('/payment-chargify/payment-profile', payload)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getAllSubscriptions = () => {
  const params = { active: 'ALL' };
  return instance
    .get('/payment-chargify/subscriptions', { params })
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getBillingHistory = ({ queryKey }) => {
  const [, page, perPage, startDate, endDate] = queryKey;
  const params = {
    page,
    perPage,
    startDate,
    endDate,
  };
  return instance
    .get('/payment-chargify/get-billing-history', { params })
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getInactiveSubscriptions = () => {
  const params = { active: false };
  return instance
    .get('/payment-chargify/subscriptions', { params })
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const getActiveSubscriptions = () => {
  const params = { active: true };
  return instance
    .get('/payment-chargify/subscriptions', { params })
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const createPreviewAllocation = ({ subscriptionId, payload }) => {
  return instance
    .post(`/payment-chargify/preview-allocation/${subscriptionId}`, payload)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const createPaymentCompletion = ({ productId, payload }) => {
  return instance
    .post(`/payment-chargify/create-payment/${productId}`, payload)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const allocateComponent = (payload) => {
  return instance
    .post('/payment-chargify/allocate-component', payload)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};

export const checkCustomerPaymentProfile = (payload) => {
  return instance
    .post('/payment-chargify/customer/create-profile', payload)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
};
