import { useQuery } from 'react-query';
import { getActiveSubscriptions } from 'modules/api';
import { log } from 'modules/api/afyLogger/afyLogger';

export const isDigitalPlan = () => {
  const { data: subscription = {} } = useQuery(['getSubscription'], getActiveSubscriptions, {
    retry: 0,
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess: () => {
      log('Subscription fetched successfully', 'useDigitalCustomer', ['digital-customer']);
    },
    onError: () => {
      log('Error fetching subscription', 'useDigitalCustomer', ['digital-customer']);
    },
  });

  const isDigitalPlan = subscription?.component?.component_handle.match(/digital/);

  return  !!isDigitalPlan;
};
