import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { getGuideContent } from 'modules/api/strapi';
import { Modal } from 'flowbite-react';
import { fetchAllOrders, fetchGuidesListByEmail } from 'modules/api/authorify/guides';
import { withAuth } from 'modules/auth/containers';
import { withGuideProfile, withUserPreferences } from 'modules/dashboard/containers';
import { fetchJourneyTodos, postJourneyTodos } from 'modules/api/common';
import { withProfile } from 'modules/v2/containers';
import {
  profilePlaceholder,
  formatPhoneNumber,
  getName,
  getFinalizedCheck,
} from 'modules/v2/utils/userPreferencesUtils';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import {
  formatOrderedData,
  formatProductCatalog,
} from 'modules/v2/pages/GuideCatalog/utils';
import { GuideCheckoutStatus, showColorInGuides } from 'modules/v2/utils/guideUtils';
import { DashboardTemplate } from 'modules/v2/templates';
import { isGuideOnlyCustomer } from 'modules/v2/utils';
import { Link } from 'modules/v2/draft/components';
import StepCheckIcon from 'modules/v2/common/components/SvgIcon/StepCheckIcon';
import { normalizeUrl } from 'modules/v2/utils/stringManipulation';

import SubscribeGuide from './components/SubscribeGuide';
import GuideTour from '../HomePage/components/GuideTour';
import GuidePreview from './components/GuidePreview';
import { GUIDE_JOURNEY, GUIDE_CATALOG_STEP } from '../constants';

const GuideCatalog = ({
  getUserPreferences,
  userPreferences,
  profile,
  avatar,
  updateGuideProfile,
  guideProfile,
}) => {
  const { data: profileData } = profile;
  const { guidePreferences } = guideProfile;

  const { email, firstName, lastName, phone } = profileData;
  const profileImageUrl = avatar?.data || '';

  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [firstTimeCatalog, setFirstTimeCatalog] = useState(false);
  const [productCatalogData, setProductCatalogData] = useState([]);
  const [productCatalog, setProductCatalog] = useState([]);
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [digitalLink, setDigitalLink] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [showOrderNotification, setShowOrderNotification] = useState(false);
  const [guideTask, setGuideTask] = useState({
    guideTaskId: '',
    guideCatalogStep: null,
  });
  const [userPreference, setUserPreference] = useState({
    companyLogo: '',
    companyName: '',
    email: '',
    name: '',
    profileImage: '',
    phoneNumber: '',
    isFinalized: false,
  });

  const isOnlyGuideCustomer = isGuideOnlyCustomer();

  const location = useLocation();

  const [loadingMessage, setLoadingMessage] = useState('Loading Guide Catalog...');
  const { data: guideResponse, isLoading: isGuideLoading } = useQuery(
    'getGuideContent',
    getGuideContent,
  );
  let frontCoverResponse;

  if (!isEmpty(guideResponse)) {
    frontCoverResponse = guideResponse.data;
  }

  const { data: orderList, isLoading: isOrderLoading } = useQuery(['getOrders', email], () =>
    fetchAllOrders({ customerEmail: email }),
  );

  const { data: guideList, isLoading: isGuideListLoading } = useQuery(
    ['getGuidesList', email],
    () => fetchGuidesListByEmail(email),
  );

  const processJourneyTodos = (data) => {
    const guideJourneyTask = data?.find(
      (task) => task.name === GUIDE_JOURNEY && task.status === 'todo',
    );
    if (!guideJourneyTask) {
      return;
    }
    const guideCatalogStep = guideJourneyTask.steps?.find(
      (step) => step.name === GUIDE_CATALOG_STEP,
    );
    if (guideCatalogStep?.isCompleted) {
      setFirstTimeCatalog(false);
    } else {
      setGuideTask({
        guideTaskId: guideJourneyTask?.id,
        guideCatalogStep,
      });
      setFirstTimeCatalog(true);
    }
  };

  useQuery('fetchJourneyTodos', () => fetchJourneyTodos(), {
    onSuccess: ({ data }) => processJourneyTodos(data),
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    const lcsLink = urlParams.get('link');
    const orderPlaced = urlParams.has('orderPlaced');

    if (lcsLink) {
      setDigitalLink(lcsLink);
      setShowNotification(true);
    }

    if (orderPlaced){
      urlParams.delete('orderPlaced');

      setShowOrderNotification(true);

      const existingParams = urlParams.toString() ? `?${urlParams.toString()}` : '';

      window.history.pushState({}, document.title, `${window.location.pathname}${existingParams}`);
    }
  }, [location]);

  useEffect(() => {
    if (!isGuideLoading) {
      const payloadData = guideResponse.data;
      const newPayloadData = payloadData.map(item => {
        const showInfographics = !isEmpty(item?.attributes?.infographics);
        const showColor = showColorInGuides.includes(item?.id);
        return {
          id: item?.id,
          name: item?.attributes?.Name,
          title: item?.attributes?.frontCover?.title,
          showColor,
          showInfographics,
        };
      });
      setProductCatalog(newPayloadData);
    }
  }, [guideResponse, isGuideLoading]);

  useEffect(() => {
    if (!isOrderLoading && !isGuideListLoading) {
      const payloadData = orderList.data.payload;
      const orderedHistory = productCatalog.map((item) => {
        const dataLoad = formatOrderedData(item, payloadData);
        if (dataLoad) {
          return {
            ...item,
            ...dataLoad,
            name: item.name,
            orderedName: dataLoad.name,
            orderId: dataLoad?._id,
          };
        }
        return item;
      });

      setIsOrderPlaced(payloadData.length);
      const getIpOrders = payloadData.filter(
        (item) => item.status === GuideCheckoutStatus.IN_PROGRESS,
      );
      if (getIpOrders.length) {
        setInProgressOrders(getIpOrders);
      }

      const payloadGuideData = guideList?.data?.filter(Boolean)[0];
      const formattedCatalog = formatProductCatalog(payloadGuideData, orderedHistory);
      formattedCatalog.sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        }
        if (!a.createdAt && b.createdAt) {
          return 1;
        }
        if (a.createdAt && !b.createdAt) {
          return -1;
        }
        return 0;
      });
      setProductCatalogData(formattedCatalog);
    }
  }, [guideList, isGuideListLoading, isOrderLoading, orderList, productCatalog]);

  useEffect(() => {
    if (guidePreferences.length === 0) {
      let productCatalogDataResponse;
      if (!isGuideListLoading && !isEmpty(guideList?.data)) {
        const payloadData = guideList?.data?.filter(Boolean)[0];
        const result = formatProductCatalog(payloadData, productCatalog);
        productCatalogDataResponse = [...result];
        const newGuidePreferences = productCatalogDataResponse.map((item) => {
          const selectedColor = item.showColor ? 'blue' : 'white';
          return {
            ...item,
            selectedColor,
            selectedStatus: item.name === 'Seller',
          };
        });
        updateGuideProfile({ guidePreferences: newGuidePreferences });
      }
    }
  }, [guideList, guidePreferences, isGuideListLoading, productCatalogData, updateGuideProfile, productCatalog]);

  useEffect(() => {
    getUserPreferences();
  }, [getUserPreferences]);


  useEffect(() => {
    if (userPreferences?.status === 'STATUS_SUCCESS' && userPreferences?.data?.data) {
      const { data: dataDefault } = userPreferences?.data?.data;
      const data = dataDefault?.data?.guides || dataDefault;

      const phoneDetails = data?.phone ? formatPhoneNumber(data.phone) : '';
      const updatePreferences = {
        companyLogo: data?.brokerLogo?.value || '',
        companyName: data?.companyName || '',
        email: data?.email || email,
        name: getName(data, firstName, lastName),
        profileImage: data?.headshotImage || profileImageUrl.url || profilePlaceholder,
        phoneNumber: phoneDetails || (phone && formatPhoneNumber(phone)) || '',
        isFinalized: getFinalizedCheck(data, email, phone, profileImageUrl),
        showBrokerInfo: data?.brokerLogo?.status || false,
      };

      setUserPreference(updatePreferences);
    }
  }, [email, firstName, lastName, phone, userPreferences, profileImageUrl]);

  const handleClickFirstTime = async () => {
    setFirstTimeCatalog(false);
    await postJourneyTodos({
      taskId: guideTask.guideTaskId,
      stepId: guideTask.guideCatalogStep.id,
    });
  };

  return (
    <DashboardTemplate
      title="Guides"
      isLoading={isGuideLoading || isOrderLoading}
      loadingTitle={loadingMessage}
      contentTitle="Guide Catalog"
    >
      {!isOrderPlaced && !isOnlyGuideCustomer && <SubscribeGuide />}

      <div className="w-full bg-neutral-0 rounded-b-lg shadow-box">
        <div>
          {frontCoverResponse &&
            !isEmpty(productCatalogData) &&
            productCatalogData.map((guide) => (
              <GuidePreview
                key={guide.id}
                guideDetails={guide}
                frontCoverResponse={frontCoverResponse}
                personalInformation={userPreference}
                inProgressOrders={inProgressOrders}
                isOnlyGuideCustomer={isOnlyGuideCustomer}
              />
            ))}
        </div>
      </div>
      <Modal
        show={showNotification}
        onClose={() => setShowNotification(false)}
        size="xl"
        dismissible
        position="center"
      >
        <Modal.Body className="flex flex-col justify-center items-center gap-4 p-8">
          <div className="w-[50px] h-[50px] bg-success-500 rounded-full p-1.5 flex items-center justify-center">
            <StepCheckIcon stroke="#FFF" width={30} height={30} strokeWidth={3} />
          </div>
          <h3 className="text-base font-bold">Share Your Digital Guide!</h3>
          <p className="text-sm text-neutral-500 font-normal text-center">
            Thank you for your order. Share your guide digitally to showcase expertise and build
            trust with remote clients.
          </p>
          <div className="w-2/3 h-full">
            <Link
              key="digitalBook"
              id="digitalBook"
              showPreview={false}
              href={normalizeUrl(digitalLink)}
              title={null}
              showText
            />
          </div>
          <Button
            color="light"
            onClick={() => {
              setShowNotification(false);
            }}
          >
            Okay
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showOrderNotification}
        onClose={() => setShowOrderNotification(false)}
        size="xl"
        dismissible
        position="center"
      >
        <Modal.Body className="flex flex-col justify-center items-center gap-4 p-8">
          <div className="w-[50px] h-[50px] bg-success-500 rounded-full p-1.5 flex items-center justify-center">
            <StepCheckIcon stroke="#FFF" width={30} height={30} strokeWidth={3} />
          </div>
          <h3 className="text-base font-bold">Thank you. Your order is confirmed!</h3>
          <p className="text-sm text-neutral-500 font-normal">
            Please allow 7-10 days for us to print and ship your order.
          </p>
          <Button
            color="light"
            onClick={() => {
              setShowOrderNotification(false);
            }}
          >
            Okay
          </Button>
        </Modal.Body>
      </Modal>
      {isOnlyGuideCustomer && (
        <GuideTour
          data-guide-tour="true"
          showModal={firstTimeCatalog}
          setShowModal={handleClickFirstTime}
          guideDetails={productCatalogData[0]}
          personalInformation={userPreference}
          frontCoverResponse={frontCoverResponse}
          inProgressOrders={inProgressOrders}
          isOnlyGuideCustomer={isOnlyGuideCustomer}
        />
      )}
    </DashboardTemplate>
  );
};

export default compose(withProfile, withAuth, withUserPreferences, withGuideProfile)(GuideCatalog);
