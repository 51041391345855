import withStore from 'store/StoreContainer';
import {
  getUserPreferences,
  preferencesSelector,
  updatePreferences,
  headshotStateSelector,
  uploadUserHeadshot,
  brokerLogoStateSelector,
  uploadUserBrokerLogo,
  updateGuidePreferences
} from 'modules/dashboard/store';

const mapStateToProps = (state) => ({
  userPreferences: preferencesSelector(state),
  headshot: headshotStateSelector(state),
  userBrokerLogo: brokerLogoStateSelector(state),
});

const mapDispatchToProps = {
  updateUserPreferences: updatePreferences,
  updateGuidePreferences,
  getUserPreferences,
  uploadUserHeadshot,
  uploadUserBrokerLogo,
};

export default withStore(mapStateToProps, mapDispatchToProps);