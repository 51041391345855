import { isEmpty } from 'lodash-es';
import { getFooterTOC, pageOneHandleBar, colorPick } from 'modules/v2/utils/guideUtils';
import { BgImageResponse } from './htmlCreation.types';

const getImageUrlFromAttributes = (image: BgImageResponse, optmized = true) => {
  if (optmized) return image?.attributes?.formats?.small?.url || '';

  return image?.attributes?.url || '';
};

const getBgImageFromResponse = (bgImageResponse: BgImageResponse[], targetName: string, optmized = true) => {
  const targetImageFallback = bgImageResponse?.[0];
  const targetImage = bgImageResponse?.filter(item => item?.attributes.name === targetName);

  return getImageUrlFromAttributes(targetImage?.[0] ?? targetImageFallback, optmized);
};

const getBgImage = (content, targetName: string, orderNumber) => {
  const data = content[orderNumber-1];
  const { innerFooter } = data;
  const backgroundImage = getBgImageFromResponse(data?.displayBgImage?.data, targetName, false);

  return {
    backgroundImage,
    innerFooter
  };
};

export function updateBrokerInfo({
  showBrokerInfo = false,
  companyName = '',
  companyLogo = '',
  finalHTML = ''
}) {
  if (!showBrokerInfo) {
    const replacements = {
      '{{companyName}}': '',
      '{{companyLogo}}': ''
    };
    return finalHTML.replace(
      /{{company(?:Name|Logo)}}/g,
      match => replacements[match] || ''
    );
  }

  const companyNamePattern = '{{companyName}}';
  const companyNameReplacement = companyName ? `${companyName}` : companyName;

  return finalHTML
    .replace(companyNamePattern, companyNameReplacement)
    .replace('{{companyLogo}}', companyLogo);
}

export const htmlCreation = (guideFrontCoverResponse, personalInformation, selectedColor) => {
  const { email, name, companyName, phoneNumber, profileImage, companyLogo, showBrokerInfo, webAddress = '' } = personalInformation;

  const frontCoverResponse = guideFrontCoverResponse?.attributes?.frontCover;

  let phoneIcon = getBgImageFromResponse(frontCoverResponse?.phoneIcon?.data, selectedColor, false);
  const emailIcon = getBgImageFromResponse(frontCoverResponse?.emailIcon?.data, selectedColor, false);
  const webIcon = getBgImageFromResponse(frontCoverResponse?.webIcon?.data, selectedColor, false);

  if (!phoneNumber) {
    phoneIcon = '';
  }

  const backgroundImage = getBgImageFromResponse(frontCoverResponse?.displayBgImage?.data, selectedColor);
  const colorPicker = colorPick[selectedColor];

  let finalHTML = frontCoverResponse?.htmlContent
    .replace('{{email}}', email)
    .replace('{{email}}', email)
    .replace('{{name}}', name)
    .replace('{{phoneNumber}}', phoneNumber)
    .replace('{{profileImage}}', profileImage)
    .replace('{{emailIcon}}', emailIcon)
    .replace('{{phoneIcon}}', phoneIcon)
    .replace('class="infoWrap1"', 'class="infoWrap1" style="width: 55%; font-size: 12.5px;padding-left: 6px;"')
    .replace('{{Color}}', colorPicker)
    .replace('{{displayBgImage}}', backgroundImage);


  if(webAddress){
    finalHTML = finalHTML?.replace('{{webIcon}}', webIcon)
      .replace('{{webAddress}}', webAddress)
      .replace('{{webAddress}}', webAddress);
  } else{
    finalHTML = finalHTML?.replace('{{webIcon}}', '')
      .replace('{{webAddress}}', '')
      .replace('{{webAddress}}', '');
  }

  const finalHtmlData = updateBrokerInfo({ showBrokerInfo, companyName, companyLogo, finalHTML });

  return finalHtmlData;
};

export const frontCoverHtml = (color, pii, data, guideName) => {
  // Footer change

  const { email, name, companyName, phoneNumber, profileImage, companyLogo, showBrokerInfo, webAddress = '' } = pii;
  let phoneIcon = getBgImageFromResponse(data?.phoneIcon?.data, color, false);
  const emailIcon = getBgImageFromResponse(data?.emailIcon?.data, color, false);
  const webIcon = getBgImageFromResponse(data?.webIcon?.data, color, false);

  if (!phoneNumber) {
    phoneIcon = '';
  }

  // content change
  const backgroundImage = getBgImageFromResponse(data?.displayBgImage?.data, color);
  const colorPicker = colorPick[color];
  let finalHTML = data?.htmlContent
    .replace('{{email}}', email)
    .replace('{{email}}', email)
    .replace('{{name}}', name)
    .replace('{{phoneNumber}}', phoneNumber)
    .replace('{{profileImage}}', profileImage)
    .replace('{{emailIcon}}', emailIcon)
    .replace('{{phoneIcon}}', phoneIcon)
    .replace('{{Color}}', colorPicker)
    .replace('{{displayBgImage}}', backgroundImage);

  if(webAddress){
    finalHTML = finalHTML?.replace('{{webIcon}}', webIcon)
      .replace('{{webAddress}}', webAddress)
      .replace('{{webAddress}}', webAddress);
  } else{
    finalHTML = finalHTML?.replace('{{webIcon}}', '')
      .replace('{{webAddress}}', '')
      .replace('{{webAddress}}', '');
  }

  const finalHtmlData = updateBrokerInfo({ showBrokerInfo, companyName, companyLogo, finalHTML });
  return finalHtmlData;
};

export const insideContent = (color, data, index, pii, guideId, backCover) => {
  const { email, name, companyName, phoneNumber, profileImage, companyLogo, showBrokerInfo, webAddress = '' } = pii;
  const { innerFooter } = data;
  const backgroundImage = getBgImageFromResponse(data?.displayBgImage?.data, color);
  const colorPicker = colorPick[color];
  const contentImageResponse = data?.contentImages?.data;

  let finalHTML = data?.html
    .replace('{{Color}}', colorPicker)
    .replace('{{displayBgImage}}', backgroundImage);

  const getGuideChange = pageOneHandleBar[index];
  if(getGuideChange?.length){
    const needHandlebarChange = getGuideChange.includes(guideId);
    if(needHandlebarChange){
      const phoneIcon = getBgImageFromResponse(backCover?.phoneIcon?.data, color, false);
      const emailIcon = getBgImageFromResponse(backCover?.emailIcon?.data, color, false);
      const webIcon = getBgImageFromResponse(backCover?.webIcon?.data, color, false);
      finalHTML = finalHTML.replace('{{email}}', email)
        .replace('{{email}}', email)
        .replace('{{name}}', name)
        .replace('{{phoneNumber}}', phoneNumber)
        .replace('{{profileImage}}', profileImage)
        .replace('{{emailIcon}}', emailIcon)
        .replace('{{phoneIcon}}', phoneIcon);

      if(webAddress){
        finalHTML = finalHTML?.replace('{{webIcon}}', webIcon)
          .replace('{{webAddress}}', webAddress)
          .replace('{{webAddress}}', webAddress);
      } else{
        finalHTML = finalHTML?.replace('{{webIcon}}', '')
          .replace('{{webAddress}}', '')
          .replace('{{webAddress}}', '');
      }

      finalHTML = updateBrokerInfo({ showBrokerInfo, companyName, companyLogo, finalHTML });
    }
  }

  finalHTML = finalHTML.replace('{{innerFooter}}', innerFooter || getFooterTOC());


  if (contentImageResponse !== null) {
    const contentImage = getBgImageFromResponse(contentImageResponse, color);
    finalHTML = finalHTML.replace('{{contentImages}}', contentImage);
  }

  return finalHTML;
};

export const backCoverHtml = (color, data, pii, guideName) => {
  const { email, name, companyName, phoneNumber, profileImage, companyLogo, showBrokerInfo, webAddress = '' } = pii;

  let phoneIcon = getBgImageFromResponse(data?.phoneIcon?.data, color, false);
  const emailIcon = getBgImageFromResponse(data?.emailIcon?.data, color, false);
  const webIcon = getBgImageFromResponse(data?.webIcon?.data, color, false);

  if (!phoneNumber) {
    phoneIcon = '';
  }

  const backgroundImage = getBgImageFromResponse(data?.displayBgImage?.data, color);
  const colorPicker = colorPick[color];

  let finalHTML = data?.html
    .replace('{{email}}', email)
    .replace('{{email}}', email)
    .replace('{{name}}', name)
    .replace('{{phoneNumber}}', phoneNumber)
    .replace('{{profileImage}}', profileImage)
    .replace('{{phoneIcon}}', phoneIcon)
    .replace('{{emailIcon}}', emailIcon)
    .replace('{{Color}}', colorPicker)
    .replace('{{displayBgImage}}', backgroundImage);

  if(webAddress){
    finalHTML = finalHTML?.replace('{{webIcon}}', webIcon)
      .replace('{{webAddress}}', webAddress)
      .replace('{{webAddress}}', webAddress);
  } else{
    finalHTML = finalHTML?.replace('{{webIcon}}', '')
      .replace('{{webAddress}}', '')
      .replace('{{webAddress}}', '');
  }

  const finalHtmlData = updateBrokerInfo({ showBrokerInfo, companyName, companyLogo, finalHTML });

  return finalHtmlData;
};

export const htmlCreationInfographics = (data, pii, color) => {
  const { email, name, companyName, phoneNumber, profileImage, showBrokerInfo } = pii;
  let phoneIcon = data?.phoneIcon?.data[0]?.attributes?.url;
  const emailIcon = data?.emailIcon?.data[0]?.attributes?.url;
  const backgroundImage = getBgImageFromResponse(data?.displayBgImage?.data, color);

  const colorPicker = colorPick[color];

  if (!phoneNumber){
    phoneIcon = '';
  }

  const finalHTML = data?.html
    .replace('{{email}}', email)
    .replace('{{email}}', email)
    .replace('{{name}}', name)
    .replace('{{phoneNumber}}', phoneNumber)
    .replace('{{color}}', colorPicker)
    .replace('{{displayBgImage}}', backgroundImage)
    .replace('{{profileImage}}', profileImage)
    .replace('{{phoneIcon}}', phoneIcon || '')
    .replace('{{emailIcon}}', emailIcon || '');

  if (!showBrokerInfo || !companyName) {
    return finalHTML
      .replace('<span class="CompnName">{{companyName}}</span>', '')
      .replace('<span.agentName {font-weight: 500;color: var(--text-color-white);font-size: 48px;margin-bottom: 3px;}', '');
  }
  return finalHTML.replace('{{companyName}}', companyName);
};

export const strapiHtmlCreation = (data, personalInformation, selectedColor, guideId) => {
  const htmlPages = [];
  // Add front cover
  const frontCoverDesign = frontCoverHtml(selectedColor, personalInformation, data?.frontCover, data?.Name);
  htmlPages.push(frontCoverDesign);

  // Add content pages
  const frontInsideCover = data?.content;
  frontInsideCover.forEach((item, index) => {
    const frontCoverInside = insideContent(selectedColor, item, index, personalInformation, guideId, data?.backCover);
    htmlPages.push(frontCoverInside);
  });

  // Add back cover
  const backCoverDesign = backCoverHtml(selectedColor, data?.backCover, personalInformation, data?.Name);
  htmlPages.push(backCoverDesign);

  return htmlPages;
};

export const strapiHtmlInfographicCreation = (data, personalInformation, selectedColor) => {

  const frontCoverDesign = htmlCreationInfographics(data, personalInformation, selectedColor);

  return frontCoverDesign;
};

export const strapiPreloadHtmlImages = (guideResponse, guideInfographicsResponse) => {
  const imagesToPreload = [];

  const skipPreload = Boolean(!guideResponse || !(guideInfographicsResponse && Object.values(guideInfographicsResponse).length > 0));

  if (skipPreload) return [];

  const { backCover, content, frontCover } = guideResponse;
  // const { cover: [infographicsCover] } = guideInfographicsResponse;

  for (const backCoverData of backCover?.displayBgImage?.data) {
    imagesToPreload.push(
      getImageUrlFromAttributes(backCoverData)
    );
  }

  for (const contentAttribute of content) {
    for (const contentData of contentAttribute?.displayBgImage?.data) {
      imagesToPreload.push(
        getImageUrlFromAttributes(contentData)
      );
    }
  }

  for (const frontCoverData of frontCover?.displayBgImage?.data) {
    imagesToPreload.push(
      getImageUrlFromAttributes(frontCoverData)
    );
  }

  for (const infographicCover of guideInfographicsResponse?.displayBgImage?.data) {
    imagesToPreload.push(
      getImageUrlFromAttributes(infographicCover)
    );
  }

  const preloadedImages = [];

  for (const imageToPreload of imagesToPreload) {
    const tmpImage = new Image();
    tmpImage.src = imageToPreload;

    preloadedImages.push(tmpImage);
  }

  return preloadedImages;
};

export const payloadCreation = (selectedGuideData, personalInformation, selectedColor, userEmail, shippingAddress, productInfo) => {
  const {
    email,
    name,
    companyName,
    phoneNumber,
    profileImage,
    companyLogo,
    showBrokerInfo,
    webAddress = ''
  } = personalInformation;

  const {
    attributes: {
      Name: guideName,
      frontCover: frontCoverResponse,
      backCover: backCoverResponse,
      content: backContentResponse,
      infographics: infographicsResponse
    },
    id
  } = selectedGuideData;

  const createInputs = (inputs) =>
    Object.entries(inputs).map(([key, value]) => ({ key, value }));

  const createContentPage = (order, backgroundImage, htmlFooter = guideName, guideId) => {
    const index = order - 1;
    const response = {
      order,
      input: [
        { key: 'displayBgImage', value: backgroundImage.backgroundImage || backgroundImage },
        { key: 'Color', value: colorPick[selectedColor] },
        { key: 'innerFooter', value: backgroundImage.innerFooter || htmlFooter }
      ]
    };

    const getGuideChange = pageOneHandleBar[index];

    if(getGuideChange?.length){
      const needHandlebarChange = getGuideChange.includes(guideId);
      if(needHandlebarChange){
        const phoneIcon = getBgImageFromResponse(backCoverResponse?.phoneIcon?.data, selectedColor, false);
        const emailIcon = getBgImageFromResponse(backCoverResponse?.emailIcon?.data, selectedColor, false);
        const webIcon = getBgImageFromResponse(backCoverResponse?.webIcon?.data, selectedColor, false);
        response.input.push({ key: 'email', value: email });
        response.input.push({ key: 'email', value: email });
        response.input.push({ key: 'name', value: name });
        response.input.push({ key: 'phoneNumber', value: phoneNumber });
        response.input.push({ key: 'profileImage', value: profileImage });
        response.input.push({ key: 'emailIcon', value: emailIcon });
        response.input.push({ key: 'phoneIcon', value: phoneIcon });
        if(webAddress){
          response.input.push({ key: 'webIcon', value: webIcon });
          response.input.push({ key: 'webAddress', value: webAddress });
          response.input.push({ key: 'webAddress', value: webAddress });
        } else {
          response.input.push({ key: 'webIcon', value: '' });
          response.input.push({ key: 'webAddress', value: '' });
          response.input.push({ key: 'webAddress', value: '' });
        }

        const companyInfo = showBrokerInfo && companyName && companyLogo ? {
          companyName,
          companyLogo
        } : {
          companyName: '',
          companyLogo: ''
        };
        response.input.push(...createInputs(companyInfo));
      }
    }
    return response;
  };

  const icons = {
    phoneIconFrontCover: phoneNumber ? getBgImageFromResponse(frontCoverResponse?.phoneIcon?.data, selectedColor, false) : '',
    emailIconFrontCover: getBgImageFromResponse(frontCoverResponse?.emailIcon?.data, selectedColor, false),
    webIconFrontcover: webAddress ? getBgImageFromResponse(frontCoverResponse?.webIcon?.data, selectedColor, false): '',
    locationIconFrontCover:  getBgImageFromResponse(backCoverResponse?.locationIcon?.data, selectedColor, false),

    phoneIconBackCover: phoneNumber ? getBgImageFromResponse(backCoverResponse?.phoneIcon?.data, selectedColor, false) : '',
    emailIconBackCover: getBgImageFromResponse(backCoverResponse?.emailIcon?.data, selectedColor, false),
    locationIconBackCover: getBgImageFromResponse(backCoverResponse?.locationIcon?.data, selectedColor, false),
    webIconBackcover: webAddress ? getBgImageFromResponse(backCoverResponse?.webIcon?.data, selectedColor, false): '',
  };

  if(!isEmpty(infographicsResponse)){
    icons.phoneInfographics = phoneNumber ? infographicsResponse?.phoneIcon?.data[0]?.attributes?.url : '';
  }

  const backgrounds = {
    front: getBgImageFromResponse(frontCoverResponse?.displayBgImage?.data, selectedColor, false),
    back: getBgImageFromResponse(backCoverResponse?.displayBgImage?.data, selectedColor, false),
    // infographics: getBgImageFromResponse(infographicsResponse?.displayBgImage?.data, selectedColor, false),
    content: Array.from({ length: backContentResponse.length }, (_, i) =>
      getBgImage(backContentResponse, selectedColor, i + 1)
    )
  };

  if(!isEmpty(infographicsResponse)){
    backgrounds.infographics = getBgImageFromResponse(infographicsResponse?.displayBgImage?.data, selectedColor, false);
  }

  const basePayload = {
    profileImage,
    name,
    phoneNumber,
    email,
    Color: colorPick[selectedColor]
  };

  const payload = {
    userEmail,
    contentId: id.toString(),
    guideColor: selectedColor,
    guideAddress: shippingAddress,
    title: guideName,
    userName: name,
    brokerName: (showBrokerInfo && companyName) ? companyName : '',
    productInfo,
    inputs: {
      frontCover: createInputs({
        ...basePayload,
        phoneIcon: icons.phoneIconFrontCover,
        emailIcon: icons.emailIconFrontCover,
        locationIcon: icons.locationIconFrontCover,
        displayBgImage: backgrounds.front
      }),
      backCover: createInputs({
        ...basePayload,
        phoneIcon: icons.phoneIconBackCover,
        emailIcon: icons.emailIconBackCover,
        locationIcon: icons.locationIconBackCover,
        displayBgImage: backgrounds.back
      }),
      contentPages: backgrounds.content.map((bg, index) => 
        createContentPage(index + 1, bg, index === 0 ? getFooterTOC() : guideName, id)
      ),
    }
  };

  if(!isEmpty(infographicsResponse)){
    payload.inputs.infographic = createInputs({
      profileImage,
      name,
      phoneNumber,
      email,
      phoneIcon: icons?.phoneInfographics,
      emailIcon: infographicsResponse?.emailIcon?.data[0]?.attributes?.url,
      Color: colorPick[selectedColor],
      displayBgImage: backgrounds?.infographics
    });
  }

  const companyInfo = showBrokerInfo && companyName && companyLogo ? {
    companyName,
    companyLogo
  } : {
    companyName: '',
    companyLogo: ''
  };

  payload.inputs.frontCover.push(...createInputs(companyInfo));
  payload.inputs.backCover.push(...createInputs(companyInfo));
  if(!isEmpty(infographicsResponse)){
    payload.inputs.infographic.push({ key: 'companyName', value: companyInfo.companyName });
  }

  if(webAddress){
    payload.inputs.frontCover.push({ key: 'webIcon', value: icons.webIconFrontcover });
    payload.inputs.frontCover.push({ key: 'webAddress', value: webAddress });
    payload.inputs.frontCover.push({ key: 'webAddress', value: webAddress });
    payload.inputs.backCover.push({ key: 'webIcon', value: icons.webIconBackcover });
    payload.inputs.backCover.push({ key: 'webAddress', value: webAddress });
    payload.inputs.backCover.push({ key: 'webAddress', value: webAddress });
  } else {
    payload.inputs.frontCover.push({ key: 'webIcon', value: '' });
    payload.inputs.frontCover.push({ key: 'webAddress', value: '' });
    payload.inputs.frontCover.push({ key: 'webAddress', value: '' });
    payload.inputs.backCover.push({ key: 'webIcon', value: '' });
    payload.inputs.backCover.push({ key: 'webAddress', value: '' });
    payload.inputs.backCover.push({ key: 'webAddress', value: '' });
  }

  return payload;
};
