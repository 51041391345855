interface IThumbnailPreview {
  imageAlt: string;
  imageSrc: string;
  linkToRedirect?: string;
}

const ThumbnailPreview = ({ size = "67px", imageAlt, imageSrc, linkToRedirect = null }: IThumbnailPreview) => {
  const handleClick = () => {
    if (linkToRedirect)
      window.open(linkToRedirect, '_blank');
  }

  return (
    <div
      className={`flex justify-center bg-neutral-100 rounded-lg border border-neutral-300 w-[75px] h-[75px] overflow-hidden ${linkToRedirect ? 'hover:cursor-pointer' : ''}`}
      onClick={handleClick}
    >
      <img alt={imageAlt} src={imageSrc} />
    </div>
  );
};

export default ThumbnailPreview;
