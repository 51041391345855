import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { compose } from 'redux';
import { useQuery } from 'react-query';
import { get } from 'lodash-es';
import { Modal } from 'flowbite-react';
import GuidesOrder from 'modules/v2/pages/HomePage/sections/Guides/GuidesOrder';

import { getData } from 'modules/auth';
import { getGuideContent } from 'modules/api/strapi';
import { DashboardTemplate } from 'modules/v2/templates';
import { withProfile } from 'modules/v2/containers';
import { isDentistCustomer, isGuideOnlyCustomer } from 'modules/v2/utils';
import OrderTag from 'modules/v2/common/AtomicDesign/atoms/OrderTag';
import StepCheckIcon from 'modules/v2/common/components/SvgIcon/StepCheckIcon';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';
import { getRouteDentistGuidesCatalog } from 'modules/v2/routes/dentist/navigation';

import { AnalyticsContainer, BooksContainer, MagazineContainer, GuidesContainer } from './sections';
import { INTERCOM_CONTROL } from './constants';

export const HomePage = ({ history, profile }) => {
  const isDentist = isDentistCustomer();
  const isOnlyGuideCustomer = isGuideOnlyCustomer();
  const { isRm, isBooks } = useSelector((data) => data.bundleAccess);
  const [firstNameUser, setFirstNameUser] = useState('');
  const [welcomeJourneyCompletion, setWelcomeJourneyCompletion] = useState(false);
  const [showOrderId, setShowOrderId] = useState('');
  const location = useLocation();
  const [globalProfileData, setGlobalProfileData] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const { data: guideResponse, isLoading: isGuideLoading } = useQuery('getGuideContent', getGuideContent);

  const subHeader = () => {
    if (welcomeJourneyCompletion) return null;

    return 'Finish this setup checklist to get your resources ready to go.';
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderData = queryParams.has('orderPlaced');
    if (orderData) {
      setShowNotification(true);
    }
  }, [location.search]);

  useEffect(() => {
    const { userData } = getData();
    if (userData?.welcomeJourneyEligible) {
      const url = '/welcome-journey/';
      history.push(url);
    }
  }, [history, location.search]);

  useEffect(() => {
    if (profile) {
      const { firstName } = get(profile, 'data', '');
      setFirstNameUser(firstName);
    }
  }, [profile]);

  useEffect(() => {
    // TODO: We need to improve that
    if (isDentist) {
      history.push(getRouteDentistGuidesCatalog());
    }

    const intercom = window?.Intercom;
    const intercomShow = localStorage.getItem(INTERCOM_CONTROL);
    if (intercom && !intercomShow) intercom('show');
    localStorage.setItem(INTERCOM_CONTROL, '1');
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const welcomeOrderId = queryParams.get('welcomeOrderId');
    const hasWelcomeJourneyCompleted = queryParams.has('welcomeJourneyCompleted');
    setShowOrderId(welcomeOrderId);
    setWelcomeJourneyCompletion(hasWelcomeJourneyCompleted);
  }, [location.search]);

  return (
    <DashboardTemplate title={`Welcome back ${firstNameUser}`} isLoading={isGuideLoading} subTitle={subHeader()}>
      <div className="rounded-md gap-2">
        {(!isOnlyGuideCustomer && (isBooks || isRm)) &&
          <div className="flex gap-x-6 flex-wrap max-xxl:gap-6 justify-between flex-col lg:flex-row">
            {(!isOnlyGuideCustomer && isBooks) ? (
              <div className="bg-white p-6 rounded-lg shadow-md flex-1 h-[420px] max-lg:h-full">
                <BooksContainer />
              </div>
            ) : null}
            {(!isOnlyGuideCustomer && isRm) ? (
              <div className="bg-white p-6 rounded-lg shadow-md flex-1 h-[420px] max-lg:h-full">
                <MagazineContainer />
              </div>
            ) : null}
          </div>
        }
        {!isOnlyGuideCustomer && (isBooks || isRm) ? (
          <AnalyticsContainer />
        ) : null}
        {isOnlyGuideCustomer && (
          <GuidesContainer globalProfileData={globalProfileData} guideResponse={guideResponse} isGuideLoading={isGuideLoading} hasBookAccess={isBooks} />
        )}
        {!isOnlyGuideCustomer && <GuidesOrder />}
      </div>
      <Modal
        show={welcomeJourneyCompletion}
        onClose={() => setWelcomeJourneyCompletion(false)}
        size="xl"
        dismissible
        position="center"
      >
        <Modal.Body className="flex flex-col justify-center items-center gap-4 p-8">
          <div className="w-[50px] h-[50px] bg-success-500 rounded-full p-1.5 flex items-center justify-center">
            <StepCheckIcon stroke="#FFF" width={30} height={30} strokeWidth={3} />
          </div>
          {showOrderId && <OrderTag className="mb-2">Order# {showOrderId}</OrderTag>}
          <h3 className="text-base font-bold">Great Job! 🥳 You have completed all your tasks!</h3>
          <p className="text-sm text-neutral-500 font-normal">
            {showOrderId
              ? 'Your order is confirmed!. Please allow 7-10 days for us to print and ship your order.'
              : 'Fantastic job on taking care of your action steps! '}
          </p>
          <Button
            color="light"
            onClick={() => {
              setWelcomeJourneyCompletion(false);
            }}
          >
            Continue
          </Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={showNotification}
        onClose={() => setShowNotification(false)}
        size="xl"
        dismissible
        position="center"
      >
        <Modal.Body className="flex flex-col justify-center items-center gap-4 p-8">
          <div className="w-[50px] h-[50px] bg-success-500 rounded-full p-1.5 flex items-center justify-center">
            <StepCheckIcon stroke="#FFF" width={30} height={30} strokeWidth={3} />
          </div>
          <h3 className="text-base font-bold">Thank you. Your order is confirmed!</h3>
          <p className="text-sm text-neutral-500 font-normal">
            Please allow 7-10 days for us to print and ship your order.
          </p>
          <Button
            color="light"
            onClick={() => {
              setShowNotification(false);
            }}
          >
            Okay
          </Button>
        </Modal.Body>
      </Modal>
    </DashboardTemplate>
  );
};

export default compose(withProfile)(HomePage);