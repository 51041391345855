import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import env from 'environments';

import { hasGuideAccess } from 'modules/v2/utils';
import { ROUTE_HOMEPAGE } from './constants';

export function GuideGuard<T extends RouteComponentProps = RouteComponentProps<{}>>(
  Component: React.ComponentType<T>,
) {
  return (props: RouteComponentProps<any>) => {
    const isGuidesAutomationEnabled = hasGuideAccess();

    if (!isGuidesAutomationEnabled) {
      return <Redirect to={ROUTE_HOMEPAGE} />;
    }

    return <Component {...(props as T)} />;
  };
}